import React, { Fragment, HTMLAttributes, useContext } from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";

import { 
    GetLocationByIP, 
    Industries, 
    Strings, 
    RequestExamples, 
    Sticky, 
    DescriptionLevel, 
    RequestsLevel, 
    DataLayerPush, 
    CheckLatin, 
    RequestColors2, 
    MergeArrays,
    CommunityLogo
} from "./elements/Data";
import { 
    EmailAuth, 
    EmailAuthConfirm, 
    GoogleAuth, LinkedInAuth, 
    NewOffer, 
    ProfileUpdate, 
    RefreshId 
} from "./Requests";
import {  
    FooterDesktop,
    Loading, 
    TopBarDesktop,
    NewAvatar,
    Edit,
    ProductHuntLabel,
} from "./elements/Elements.jsx";
import {  
    InfoErrorPopup, 
    InfoPopup,  
    PopupCenter,
    PopupCenterBecomeAMember, 
} from "./elements/Popups";

import { 
    AngleRightBlack,
    AngleRightGray,  
    GoogleLogo,     
    LinkedInLogo,  
    OkWhite,
    PlusBlack,
    PlusGreen,
    PlusWhite,
    TrashBlack,  
} from "./elements/Imgs";

import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { FeedRequestCardDesktop } from "./Profile/RequestCards";
import { GlobalContext } from "./elements/Contexts";
import { FormattedMessage } from "react-intl";
import { messages } from "../i18n/messages";

const RegistrationDesktop = (props: any) => { 
    const navigate = useNavigate();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const globalContext = useContext(GlobalContext);
   
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ userId, setUserId ] = useState<string>(cookie['user_id_cookie'] ?? searchParams.get("user_id")); 
    const [ regScreenColor, setScreenColor ] = useState<number | null>(null);  
    const [ invalidForm, setInvalidForm ] = useState<boolean>(false); 
    const [ sampleRequestNumber, setSampleRequestNumber ] = useState<number>(2);
    
    const [ step, setStep ] = useState<number>(Number(
        searchParams.get("reg_step") ?? (searchParams.get("new_user") === 'true' ? (searchParams.get("new_request") === 'true' ? 2 : 1) : 0)
    ));
    const [ onboardingStep, setOnboardingStep ] = useState<number>(0);
    
    const languages: string[] = [`English`]  
    const [ ipCity, setIPCity ] = useState<{city: string} | null>(null);
    const [ city, setCity ] = useState<string>(searchParams.get('city') ?? "");
    const [ email, setEmail ] = useState<string>(searchParams.get('email') ?? "");  
    const [ fname, setFName ] = useState<string>(((searchParams.get('name') ?? "") + ' ').split(' ')[0] ?? "");
    const [ lname, setLName ] = useState<string>(((searchParams.get('name') ?? "") + ' ').split(' ')[1] ?? "");
    const [ avatar_, setAvatar_ ] = useState<File | null>(null);
    const [ avatarUrl, setAvatarUrl ] = useState<string>(searchParams.get('avatar') ?? "");    
    // const [ linkedIn, setLinkedIn ] = useState<string>(searchParams.get('linkedin') ?? "");
    const [ occupation, setOccupation ] = useState<string>(searchParams.get('job') ?? "");
    const [ company, setCompany ] = useState<string>(searchParams.get('company') ?? "");
    const [ industry, setIndustry ] = useState<string[]>((searchParams.get('industry') ?? "").split(/\s{0,},\s{0,}/).filter(val => !!val));
    const [ addIndustry, setAddIndustry ] = useState<boolean>(false);
    const [ newIndustry, setNewIndustry] = useState<string>('');
    const [ description, setDescription ] = useState<string>(searchParams.get('description') ?? "");

    const [ remindCalendar, setRemindCalendar ] = useState<boolean>(true);
    const [ agreeProcessData, setAgreeProcessData ] = useState<boolean>(false);
    const [ needAgree, setNeedAgree ] = useState<boolean>(false);
    const redirect: string = (searchParams.get('redirect') ?? "");

    const [ request, setRequest ] = useState<number | null>(null);
    const [ requestDescription, setRequestDescription ] = useState<string>(``);
    // const [ requestHint, setRequestHint ] = useState<boolean>(false);
    const [ showScrollButtons, setShowScrollButtons ] = useState<boolean>(false);

    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false);
    const [ mailResendPopup, setMailResendPopup ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);
    const [ becomeAMemberPopup, setBecomeAMemberPopup ] = useState<boolean>(false);

    const [ emailExist, setEmailExist ] = useState<boolean>(false);
    const [ mailbox, setMailbox ] = useState<string | null>(null);

    const [ waitRegistration, setWaitRegistration ] = useState<boolean>(false);
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ requestCreated, setRequestCreated ] = useState<boolean>(false);

    const [ addRequestBlockHeight, setAddRequestBlockHeight ] = useState<number>();
    const [ selectBackground, setSelectBackground ] = useState<boolean>(false);
    const [ backgroundType, setBackgroundType ] = useState<number>(0);

    // const [ progress, setProgress ] = useState<number>(0);

    // const shifts: string[] = [`28%`, `52%`, `0`, `-52%`, `-28%`, `0`, `28%`, `52%`, `0`, `-52%`, `-28%`];
    // const scales: number[] = [0.9, 0.81, 0.729, 0.81, 0.9, 1, 0.9, 0.81, 0.729, 0.81, 0.9];
    // const zIdxs: number[] = [2, 1, 0, 1, 2, 3, 2, 1, 0, 1, 2];

    const popularIndustries = [
        "Impact", 
        "Tech", 
        "AI", 
        "Media/Content", 
        "Future of Work", 
        "Education", 
        "FinTech", 
        "E-commerce", 
        "MarketingTech", 
        "Web3", 
        "Social Networks", 
        "Consulting", 
    ]

    const [ isFilled, setIsFilled ] = useState<boolean>(
        lname.length > 1 && !CheckLatin(fname) &&
        fname.length > 1 && !CheckLatin(lname) && 
        !!description && !CheckLatin(description) && 
        !!city && !CheckLatin(city) && 
        !!occupation && !CheckLatin(occupation) && 
        // !!linkedIn && !CheckLatin(linkedIn) && 
        !!company && !CheckLatin(company) &&
        !!industry?.length
    )

    const hideIndustryList = () => {
        setNewIndustry(''); 
        setAddIndustry(false);
    }
  
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 18;
        } 
        if (text?.length > 75) {
            return 20;
        } 
        if (text?.length > 55) {
            return 22;
        } 
        return 24;
    }

    useEffect(() => {
        const input = document.querySelector(`#input-industry`) as HTMLInputElement;
        if (input && addIndustry) {
            input.focus();
        }
    }, [addIndustry])

    useEffect(() => {
        setIsFilled(
            lname.length > 1 && !CheckLatin(fname) &&
            fname.length > 1 && !CheckLatin(lname) && 
            !!description && !CheckLatin(description) && 
            !!city && !CheckLatin(city) && 
            !!occupation && !CheckLatin(occupation) && 
            // !!linkedIn && !CheckLatin(linkedIn) && 
            !!company && !CheckLatin(company) &&
            !!industry?.length
        );
        // setProgress(
        //     ((fname?.length > 1 && lname?.length > 1) ? 2 : 0) + 
        //     ((company && occupation && industry) ? 4 : 0) + 
        //     ((city) ? 5 : 0) + 
        //     ((avatarUrl) ? 1 : 0)
        // );
    }, [
        fname, 
        lname, 
        description, 
        city, 
        occupation, 
        // linkedIn, 
        industry,
        avatarUrl,
        company,
    ])

    useEffect(() => {
        if (avatar_) {
            setAvatarUrl(URL.createObjectURL(avatar_));
        }
    }, [avatar_])

    useEffect(() => {
        console.warn("ON REG", cookie['user_id_cookie'])
        if (cookie['user_id_cookie'] && 
            searchParams.get("force_logout") !== 'true' && 
            searchParams.get("new_user") !== 'true') {
            navigate('/profile');
            setUserId(cookie[`user_id_cookie`]);
        }
    }, [])

    useEffect(() => {
        if (becomeAMemberPopup) {
            setSearchParams({});
        }
    }, [becomeAMemberPopup])
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant` as ScrollBehavior,
        });  
        
        if (step !== 0) {
            RefreshId(() => {}, () => {
                setStep(0);
            })
            document.body.style.backgroundColor = "#FFFFFF";
            document.body.style.backgroundImage = "";
        } else {
            document.body.style.backgroundColor =globalContext?.community === undefined ? "#FFFFFF" : "#0BC582";
            if (CommunityLogo[globalContext?.community]?.bg) {
                document.body.style.backgroundSize = 'cover';
                document.body.style.backgroundPosition = 'center';
                document.body.style.backgroundImage = `url("${CommunityLogo[globalContext?.community]?.bg}")`;
            } else {
                document.body.style.backgroundImage = "";
            }
        }

        if (step === 1) {
            DataLayerPush({'event': 'profile_fillup'});
        }

        if (step === 2) {
            // window.onkeydown = e => {
            //     if (e.key === 'ArrowRight') {
            //         setSampleRequestNumber(val => (val + 1) % 6);
            //     } else if (e.key === 'ArrowLeft') {
            //         setSampleRequestNumber(val => (val + 5) % 6);
            //     }
            // }
            DataLayerPush({'event': 'request_fillup'});
        } else {
            window.onkeydown = null;
        }
    }, [step, globalContext?.community])

    async function finishRegistration() {
        setWaitRegistration(true);

        const data = {
            name: `${fname.trim()} ${lname.trim()}`,
            fname: fname.trim(),
            lname: lname.trim(),
            links: {
                email: email,
                // linkedin: linkedIn ? linkedIn : null,
            },
            location: {
                city: city,
            },
            city: city,
            industry: industry,
            occupation: occupation,
            company: company,
            description: description,
            about: description,
            languages: languages,
            emails_matches_calendar_reminders: remindCalendar,
            emails_matches_calendar_reminders_on_registration: remindCalendar,
        }

        ProfileUpdate(userId ?? cookie[`user_id_cookie`], data, avatar_, () => {
            if (searchParams.get('can_help_text') && searchParams.get('can_help_request_id')) {
                NewOffer({
                    user_id: cookie[`user_id_cookie`],
                    pair_id: searchParams.get('can_help_pair_id') ?? '',
                    card_id: searchParams.get('can_help_request_id') ?? '',
                    text: searchParams.get('can_help_text') ?? "",
                })
            }
            setWaitRegistration(false);            
            if (remindCalendar) {                
                // setConfirmEmailPopup(true);
                // setStep(2);
                if (globalContext?.community) {
                    navigate("/profile/match");
                } else {
                    setBecomeAMemberPopup(true);
                }
            } else {
                // setStep(2);
                if (globalContext?.community) {
                    navigate("/profile/match");
                } else {
                    setBecomeAMemberPopup(true);
                }
            }
        }, () => {
            setWaitRegistration(false);
            setMailErrorPopup(true);
        }, () => {}, () => {}, () => {
            setWaitRegistration(false);
            RefreshId(() => {
                setMailErrorPopup(true);
            }, () => {
                setStep(0);
            })
        })
    }

    useEffect(() => {
        if (ipCity?.city) {
            setCity(val => !val ? ipCity.city : val)
        }
    }, [ipCity])
    
    useEffect(() => {
        setScreenColor(Math.floor(Math.random() * 6));

        Smartlook.init('9e37423949b71c5128d4aa917a1d241c9bba305b');

        GetLocationByIP(setIPCity);

        window.onresize = () => {
            const requestBlock = document.querySelector(`#add-request-block-onboarding`) as HTMLDivElement;
            if (requestBlock) {
                setAddRequestBlockHeight(requestBlock.offsetHeight);
            }
        }

        // window.addEventListener("click", hideIndustryList);

        return () => {
            window.onkeydown = null;
            window.onresize = null;
            // window.removeEventListener("click", hideIndustryList);
            document.body.style.backgroundColor = "";
        }
    }, []);

    useEffect(() => {
        const requestBlock = document.querySelector(`#add-request-block-onboarding`) as HTMLDivElement;
        if (requestBlock) {
            setAddRequestBlockHeight(requestBlock.offsetHeight);
        }
    }, [requestDescription, selectBackground])

    useEffect(() => {
        if (userId && (searchParams.get('email') || searchParams.get('linkedin'))) {
            Smartlook.identify(userId, {
                email: searchParams.get('email'),
                uuid: userId,
                linkedin: searchParams.get('linkedin'),
            })
            posthog.identify(userId, { 
                email: searchParams.get('email'), 
                uuid: userId,
                linkedin: searchParams.get('linkedin'),
            })
        }
    }, [userId])

    useEffect(() => {
        setInvalidForm(false);
    }, [onboardingStep])

    return ((regScreenColor !== undefined && globalContext?.community !== undefined) ? <>
        <div className={`body top`} style={{maxWidth: 1920}}>
            <TopBarDesktop func={() => {
                const newWindow: WindowProxy | null = window.open(
                    CommunityLogo[globalContext?.community]?.url ?? `https://osmos.social?nologin=true`, 
                    '_blank'
                );
                if (newWindow) {
                    newWindow.focus();
                }
            }} toTop={false} color={!!searchParams.get('code') || !step ? 'transparent' : 'white'}
            className={`w-flex`} hideNotifications={true} community={globalContext?.community}/>
            
            {!step && <>
                <div className={`w-flex p-5 md-px-3 ${globalContext?.community ? "row nowrap" : "neg-mt-6 column"}`} style={{
                    minHeight: `calc(100vh - 144px)`, 
                    justifyContent: `center`,
                    transition: 'opacity 0.3s ease-in-out',
                    opacity: globalContext?.community === undefined ? 0 : 1,
                    gap: 38,
                }}>
                    {globalContext?.community ?
                    <div className="column top" style={{width: 480}}>
                        <img src={CommunityLogo[globalContext?.community]?.white} 
                            alt="" style={{height: 58}} className="mb-3"></img>
                        <p data-size={4} data-color={`white`} className={`center`}>
                            {(CommunityLogo[globalContext?.community]?.text ?? "").split("\n")
                            .map((str: string, index: number) => <Fragment key={index}>
                                {str}<br/>
                            </Fragment>)}
                        </p>
                    </div> :
                    <div>
                        <p data-size={9} data-color={`white`} className={`center semibold seminarrow`}>
                            {`Create your Osmos account`}
                        </p>
                        <p data-size={5} data-color={`white`} className={`center`}>
                            {`There's a lot of new friendships ahead! Try to fill out your`}<br/> 
                            {`profile carefully so that interesting people can find you`}
                        </p>
                    </div>}

                    {!!globalContext?.community &&
                    <div className="block h-9 mx-2" data-color={"white-40-opacity"} style={{width: 2}}></div>}
                    
                    <div className={`block b-3 p-3`} style={{width: 520}}>
                        {globalContext?.community ? 
                        <p data-size={4} className={`center mb-4 semibold seminarrow mb-1`}>
                            <FormattedMessage id="reg_create_account"/>
                            {/* <FormattedMessage id="reg_create_account"/> */}
                        </p> :
                        <p data-size={8} className={`semibold seminarrow mb-1`}>
                            <FormattedMessage id="reg_continue"/>
                        </p>}
                        {[
                            {title: `Google`, logo: GoogleLogo, onClick: () => {
                                GoogleAuth(
                                    searchParams.get('code'), 
                                    searchParams.get('community'), 
                                    searchParams.get('redirect'), 
                                    (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                        text: searchParams.get('can_help'),
                                        requestId: searchParams.get('request_id'),
                                    } : null,
                                    {
                                        200: () => {                                     
                                            DataLayerPush({'event': 'registration_start'});
                                        }, 
                                        0: () => {
                                            setMailErrorPopup(true)
                                        }, 
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, text: '', shown: true},
                            {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                LinkedInAuth(
                                    searchParams.get('code'), 
                                    searchParams.get('community'), 
                                    searchParams.get('redirect'), 
                                    (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                        text: searchParams.get('can_help'),
                                        requestId: searchParams.get('request_id'),
                                    } : null,
                                    {
                                        200: () => {                                     
                                            DataLayerPush({'event': 'registration_start'});
                                        }, 
                                        0: () => {
                                            setMailErrorPopup(true)
                                        }, 
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, text: '', shown: false},
                        ].map((elem, index) => {return (
                            <button className={`btn-google desktop row center mt-1 w-flex`} key={elem.title} 
                                    data-color={`tp-black`} onClick={() => {elem.onClick()}}
                                    hidden={!elem.shown} id={`continue-with-${elem.title.toLowerCase()}-button`}>
                                <img className={``} src={elem.logo} alt={`li`}></img>
                                <p><FormattedMessage id="reg_continue_with"/>&nbsp;{elem.title}</p>
                            </button>
                        )})}

                        <form className={`block w-flex mt-3`} onSubmit={e => {
                            e.preventDefault();
                            if (email) {
                                setWaitEmail(true); 
                                setTimeout(() => { setWaitEmail(false) }, 5000);
                                EmailAuth(
                                    email, setEmailExist, setMailbox, 
                                    searchParams.get('code'), 
                                    searchParams.get('community'), 
                                    searchParams.get('redirect'), 
                                    (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                        text: searchParams.get('can_help'),
                                        requestId: searchParams.get('request_id'),
                                    } : null,
                                    {
                                        200: () => {                                     
                                            setWaitEmail(false);
                                            setMailOkPopup(true);
                                            DataLayerPush({'event': 'registration_start'});
                                        }, 
                                        0: () => {
                                            setMailErrorPopup(true);
                                        }, 
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    }
                                );
                            } 
                        }} id={`continue-with-email-form`}>
                            <p data-size={8} className={`semibold seminarrow mb-1`}>
                                <FormattedMessage id="reg_continue_with_email"/>
                                {/* <FormattedMessage id="reg_continue_with_email"/> */}
                            </p>

                            <div className={`row input-block desktop b-1 mb-1`}>
                                <input autoComplete={`off`} className={`desktop`} 
                                    placeholder={messages[globalContext.locale].reg_your_email} type={`email`}
                                    value={email} onChange={e => {
                                        setEmail(e.target.value);
                                    }} required={true}></input> 
                                <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                            </div>
                            
                            <button className={`block btn-flex desktop high w-flex row center`}
                                    data-color={`black`} type={`submit`}>
                                <p><FormattedMessage id="reg_send_link"/></p>
                                {waitEmail &&
                                <Loading color={`white`} width={24} height={24}/>}
                            </button>
                        </form>
                    </div>
                </div>              

                <FooterDesktop links={true} color={`black-5-opacity`} innerColor={`white`} agreement={true}/>
                
                {!globalContext?.community &&
                <ProductHuntLabel/>}
            </>}

            {step === 1 && <div className={`column top`}>
                <div className={`w-9 pb-4`} style={{minHeight: `calc(100vh - 86px)`}}>
                    {onboardingStep === 0 && 
                    <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                        onSubmit={async e => {
                        e.preventDefault();
                        if (agreeProcessData) {
                            if (
                                lname.length > 1 && !CheckLatin(fname) &&
                                fname.length > 1 && !CheckLatin(lname)
                            ) {
                                setOnboardingStep(val => val + 1);
                            } else {
                                const invalidInput = document.querySelector(`.invalid, .only-latin`);
                                if (invalidInput) {
                                    window.scrollTo({
                                        top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                        behavior: 'smooth',
                                    });
                                }
                            }
                        } else {
                            setNeedAgree(true);
                            const agreeProcessBtn = document.querySelector(`#agree-process-data-btn`);
                            if (agreeProcessBtn) {
                                window.scrollTo({
                                    top: agreeProcessBtn.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                        }} id={`registration-form`} style={{minHeight: 552}}>
                        <div className={`w-flex`}>
                            <div className={`row top w-flex nowrap mb-5`}>
                                <button className={`btn desktop row left min-w-5`} onClick={() => { 
                                    setStep(0);
                                }}>
                                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                </button>
                                <p data-size={4} className={`center semibold seminarrow`}>
                                    <FormattedMessage id="ob_header_common"/>
                                </p>
                                <p data-size={5} data-color={`black-40-opacity`} className={`min-w-5 pt right semibold seminarrow noshrink`}>
                                    {`${onboardingStep + 1}/5`}
                                </p>
                            </div>

                            <p data-size={8} className={`px semibold semibold mb-1`}>
                                <FormattedMessage id="fname"/>
                            </p>
                            <input autoComplete={`off`} className={`desktop mb-3 ${fname.length < 2 ? 'in' : ''}valid ${CheckLatin(fname) ? 'only-latin' : ''}`}
                                placeholder={messages[globalContext.locale].fname_ph} type={`text`} required={true}
                                value={fname} id={`fname-input`} onChange={e => {setFName(e.target.value)}} minLength={2} autoFocus={true}></input> 
                            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                            <p data-size={8} className={`px semibold semibold mb-1`}>
                                <FormattedMessage id="lname"/>
                            </p>
                            <input autoComplete={`off`} className={`desktop mb-2 ${lname.length < 2 ? 'in' : ''}valid ${CheckLatin(lname) ? 'only-latin' : ''}`} 
                                placeholder={messages[globalContext.locale].lname_ph} type={`text`} required={true}
                                value={lname} id={`lname-input`} onChange={e => {setLName(e.target.value)}} minLength={2}></input> 
                            <p className={`after neg-mt-2 mb-2 pt-1`} data-color={`coral`}></p>

                            <button className={`btn-checkbox desktop row left top nowrap custom-style px-1 pt-2 pb-2`} type={`button`}
                                    onClick={() => {setRemindCalendar(val => !val)}}>
                                <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={remindCalendar}>
                                    <img src={OkWhite} alt={`v`}></img>
                                </div>
                                <p data-size={5} data-color={`black`}><FormattedMessage id="ob_notify_matches"/></p>
                            </button>
                            
                            <button className={`btn-checkbox desktop row left top nowrap custom-style px-1 pt-2 pb-2`} type={`button`}
                                    onClick={() => {setAgreeProcessData(val => !val); setNeedAgree(false);}} id={`agree-process-data-btn`}>
                                <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={agreeProcessData} data-error={needAgree}>
                                    <img src={OkWhite} alt={`v`}></img>
                                </div>
                                <p data-size={5} data-color={needAgree && !agreeProcessData ? `coral` :`black`} className={``} 
                                    style={{whiteSpace: `normal`}} onClick={e => { e.stopPropagation() }}>
                                    <FormattedMessage id="ob_concent"/>&nbsp;	
                                    <a href={`${window.location.origin}/privacy`}>
                                        <span data-color={`green`}><FormattedMessage id="ob_processing_data"/></span>
                                    </a>
                                </p>
                            </button>
                        </div>

                        <button className={`block btn-flex high desktop w-flex row center mt-3`} type={`submit`} 
                                data-color={(
                                    lname.length > 1 && !CheckLatin(fname) &&
                                    fname.length > 1 && !CheckLatin(lname)
                                ) ? `black` : `light-gray`} onClick={() => { setInvalidForm(true) }}>
                            <p data-color={(
                                    lname.length > 1 && !CheckLatin(fname) &&
                                    fname.length > 1 && !CheckLatin(lname)
                                ) ?null : `white`}><FormattedMessage id="next"/></p>
                        </button>
                    </form>}
                    
                    {onboardingStep === 1 && 
                    <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                        onSubmit={async e => {
                            e.preventDefault();
                            if (avatarUrl) {
                                setOnboardingStep(val => val + 1);
                            } else {
                                const invalidInput = document.querySelector(`.invalid, .only-latin`);
                                if (invalidInput) {
                                    window.scrollTo({
                                        top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                        behavior: 'smooth',
                                    });
                                }
                            }
                        }} id={`registration-form`} style={{minHeight: 552}}>
                        <div className={`w-flex column top`}>
                            <div className={`row top w-flex nowrap mb-5`}>
                                <button className={`btn desktop row left min-w-5`} onClick={() => { 
                                    setOnboardingStep(val => val - 1);
                                }}>
                                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                </button>
                                <p data-size={4} className={`center semibold seminarrow`}>
                                    <FormattedMessage id="ob_photo_header"/>
                                </p>
                                <p data-size={5} data-color={`black-40-opacity`} className={`min-w-5 pt right semibold seminarrow noshrink`}>
                                    {`${onboardingStep + 1}/5`}
                                </p>
                            </div>
                            
                            <div className={`row top center mb-3`} data-color={`light-gray`}
                                    id={`registration-image-field`} style={{position: `relative`}}>
                                <input autoComplete={`off`} id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                                    onChange={e => {
                                        if (e.target.files?.length) {
                                            setAvatar_(e.target.files[0]);
                                        }
                                    }} name={`preview`}></input>
                                {!!avatarUrl ? <>
                                    <NewAvatar size={9} avatar={avatarUrl} type={0} className={`circled`}/>
                                    <div className={`block b-2 p-2 row right top appear-on-hover`} data-color={`black-10-opacity`}
                                        style={{position: `absolute`, top: 0, bottom: 0, left: 0, right: 0}}>
                                        <button className={`block btn w-4 h-4 b-1 centered`}
                                                onClick={() => { 
                                                    // setAvatar_(null); 
                                                    // setAvatarUrl(``);
                                                    // (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                                    (document.querySelector(`#input-img`) as HTMLInputElement).click();
                                                }} type={`button`}>
                                            <Edit className={'h-3 w-3'} color={`#000000`}/>
                                        </button>  
                                    </div>                                                             
                                </> : <NewAvatar size={9} avatar={avatarUrl} type={['gray', 'light-gray', 'light-gray', 'gray']} 
                                            className={`circled`} name={`${fname.trim()} ${lname.trim()}`}/>}
                            </div>
                            <p data-size={5} className={`center`} style={{maxWidth: 350}}>
                                <FormattedMessage id="ob_photo_hint"/>
                            </p>
                        </div>

                        <button className={`block btn-flex high desktop w-flex row center`} type={`submit`} 
                                data-color={`black`} onClick={() => { 
                                    if (avatarUrl) {
                                        setInvalidForm(true) 
                                    } else {
                                        (document.querySelector(`#input-img`) as HTMLInputElement).click();
                                    }
                                }}>
                            <p>{avatarUrl ? <FormattedMessage id="next"/> : <FormattedMessage id="ob_add_from_lib"/>}</p>
                        </button>
                    </form>}
                    
                    {onboardingStep === 2 && 
                    <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                        onSubmit={async e => {
                            e.preventDefault();
                            if (DescriptionLevel(description).isValid) {
                                setOnboardingStep(val => val + 1);
                            } else {
                                const invalidInput = document.querySelector(`.invalid, .only-latin`);
                                if (invalidInput) {
                                    window.scrollTo({
                                        top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                        behavior: 'smooth',
                                    });
                                }
                            }
                        }} id={`registration-form`} style={{minHeight: 552}}>
                        <div className={`w-flex`}>
                            <div className={`row top w-flex nowrap mb-5`}>
                                <button className={`btn desktop row left min-w-5`} onClick={() => { 
                                    setOnboardingStep(val => val - 1);
                                }}>
                                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                </button>
                                <p data-size={4} className={`center semibold seminarrow`}>
                                    <FormattedMessage id="ob_intro_header"/>
                                </p>
                                <p data-size={5} data-color={`black-40-opacity`} className={`min-w-5 pt right semibold seminarrow noshrink`}>
                                    {`${onboardingStep + 1}/5`}
                                </p>
                            </div>
                            
                            <textarea className={`p-2 b-1 ${!DescriptionLevel(description)?.isValid ? 'in' : ''}valid`} 
                                            style={{height: 200}}
                                            placeholder={messages[globalContext.locale].ob_intro_hint} 
                                            required={false} id={`description-input`}
                                            value={description} onChange={e => {setDescription(e.target.value)}}></textarea> 
                            <div className={`block h w-flex mt-1 b row left`} data-color={`light-gray`}>
                                <div className={`h-flex b`} style={DescriptionLevel(description)?.style}></div>
                            </div>
                            {DescriptionLevel(description)?.isText &&
                            <p data-size={6} className={`mt`}
                                data-color={!DescriptionLevel(description)?.isValid ? `coral` : `black-40-opacity`}>
                                <FormattedMessage id={DescriptionLevel(description)?.locale_text}/>
                                {/* {DescriptionLevel(description)?.text} */}
                            </p>}
                        </div>

                        <button className={`block btn-flex high desktop w-flex row center`} type={`submit`} 
                                data-color={`black`} onClick={() => { setInvalidForm(true) }}>
                            <p><FormattedMessage id="next"/></p>
                        </button>
                    </form>}
                    
                    {onboardingStep === 3 && 
                    <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                        onSubmit={async e => {
                            e.preventDefault();
                            if (industry?.length) {
                                setOnboardingStep(val => val + 1);
                            } else {
                                const invalidInput = document.querySelector(`.invalid, .only-latin`);
                                if (invalidInput) {
                                    window.scrollTo({
                                        top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                        behavior: 'smooth',
                                    });
                                }
                            }
                        }} id={`registration-form`} style={{minHeight: 552}}>
                        <div className={`w-flex`}>
                            <div className={`row top w-flex nowrap mb-5`}>
                                <button className={`btn desktop row left min-w-5`} onClick={() => { 
                                    setOnboardingStep(val => val - 1);
                                }}>
                                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                </button>
                                <p data-size={4} className={`center semibold seminarrow`}>
                                    <FormattedMessage id="ob_industry_header"/>
                                </p>
                                <p data-size={5} data-color={`black-40-opacity`} className={`min-w-5 pt right semibold seminarrow noshrink`}>
                                    {`${onboardingStep + 1}/5`}
                                </p>
                            </div>

                            <input  value={newIndustry} onChange={e => {
                                    setNewIndustry(e.target.value);
                                }} onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (newIndustry) {
                                            setIndustry((val: any) => val.includes(newIndustry.trim()) ? val : val.concat([newIndustry.trim()]));
                                            hideIndustryList();
                                        }
                                    }
                                }} className={`desktop mb-3`} placeholder={messages[globalContext.locale].industry_ph}
                                id={`input-industry`}></input>
                            <div className={`row left mb-3`}>
                                {((
                                    Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))?.length &&
                                    newIndustry.trim()?.length 
                                ) ?
                                MergeArrays(industry, Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))) : (industry?.length ? industry : popularIndustries))
                                .map((elem: string, index: number) => 
                                    <button className={`block btn-circled-32 desktop row`} 
                                        data-color={industry.includes(elem) ? 'transparent' : `light-gray`}
                                        data-border={industry.includes(elem) ? `green-1` : null}
                                        onClick={() => {
                                            setIndustry((val: any) => 
                                                val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                            )
                                            const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                            if (input) {
                                                input.focus();
                                            }
                                        }} type={`button`} id={`select-btn`} key={index}>
                                    <p data-color={industry.includes(elem) ? 'green' : `black-40-opacity`}>
                                        {elem}
                                    </p>
                                    {industry.includes(elem) &&
                                    <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                                </button>)}
                            </div>
                        </div>

                        <button className={`block btn-flex high desktop w-flex row center`} type={`submit`} 
                                data-color={`black`} onClick={() => { setInvalidForm(true) }} disabled={!industry?.length}>
                            <p><FormattedMessage id="next"/></p>
                        </button>
                    </form>}

                    {onboardingStep === 4 && 
                    <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                        onSubmit={async e => {
                            e.preventDefault();
                            if (
                                !!city && !CheckLatin(city) && 
                                !!occupation && !CheckLatin(occupation) &&  
                                !!company && !CheckLatin(company)
                            ) {
                                finishRegistration();
                            } else {
                                const invalidInput = document.querySelector(`.invalid, .only-latin`);
                                if (invalidInput) {
                                    window.scrollTo({
                                        top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                        behavior: 'smooth',
                                    });
                                }
                            }
                        }} id={`registration-form`} style={{minHeight: 552}}>
                        <div className={`w-flex`}>
                            <div className={`row top w-flex nowrap mb-5`}>
                                <button className={`btn desktop row left min-w-5`} onClick={() => { 
                                    setOnboardingStep(val => val - 1);
                                }}>
                                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                </button>
                                <p data-size={4} className={`center semibold seminarrow`}>
                                    <FormattedMessage id="ob_occupation_header"/>
                                </p>
                                <p data-size={5} data-color={`black-40-opacity`} className={`min-w-5 pt right semibold seminarrow noshrink`}>
                                    {`${onboardingStep + 1}/5`}
                                </p>
                            </div>

                            <p data-size={8} className={`px semibold semibold mb-1`}>
                                <FormattedMessage id="ob_occupation_company"/>
                            </p>
                            <input autoComplete={`off`} className={`mb-3 ${!company ? 'in' : ''}valid ${CheckLatin(company) ? 'only-latin' : ''} desktop`} 
                                placeholder={messages[globalContext.locale].occupation_company_ph} type={`text`} required={true}
                                value={company} id={`job-input`} onChange={e => {setCompany(e.target.value)}}></input> 
                            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                            <p data-size={8} className={`px semibold semibold mb-1`}>
                                <FormattedMessage id="ob_occupation_role"/>
                            </p>
                            <input autoComplete={`off`} className={`mb-3 ${!occupation ? 'in' : ''}valid ${CheckLatin(occupation) ? 'only-latin' : ''} desktop`} 
                                placeholder={messages[globalContext.locale].occupation_role_ph} type={`text`} required={true}
                                value={occupation} id={`role-input`} onChange={e => {setOccupation(e.target.value)}}></input> 
                            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                            <p data-size={8} className={`px semibold semibold mb-1`}>
                                <FormattedMessage id="ob_occupation_city"/>
                            </p>
                            <input autoComplete={`off`} className={`desktop mb-3 ${!city ? 'in' : ''}valid ${CheckLatin(city) ? 'only-latin' : ''}`} 
                                placeholder={messages[globalContext.locale].city_ph} type={`text`} required={true}
                                value={city} id={`city-input`} onChange={e => {setCity(e.target.value)}}></input> 
                            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>
                        </div>

                        <button className={`block btn-flex high desktop w-flex row center`} type={`submit`} 
                                data-color={(
                                    !!city && !CheckLatin(city) && 
                                    !!occupation && !CheckLatin(occupation) &&  
                                    !!company && !CheckLatin(company)
                                ) ? `black` : `light-gray`} onClick={() => { setInvalidForm(true) }}>
                            {waitRegistration ? 
                            <Loading color={`white`} width={24} height={24}/> :
                            <p data-color={(
                                    !!city && !CheckLatin(city) && 
                                    !!occupation && !CheckLatin(occupation) &&  
                                    !!company && !CheckLatin(company)
                                ) ? null : `white`}><FormattedMessage id="next"/></p>}
                        </button>
                    </form>}
                </div>

                <FooterDesktop links={true} agreement={true} hideCommunity={true}/>
            </div>}

            {!(step - 100) && 
            <form className={`${invalidForm && 'invalid-form'}`}
                onSubmit={async e => {
                e.preventDefault();
                if (agreeProcessData) {
                    if (isFilled) {
                        finishRegistration();
                    } else {
                        const invalidInput = document.querySelector(`.invalid, .only-latin`);
                        if (invalidInput) {
                            window.scrollTo({
                                top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                behavior: 'smooth',
                            });
                        }
                    }
                } else {
                    setNeedAgree(true);
                    const agreeProcessBtn = document.querySelector(`#agree-process-data-btn`);
                    if (agreeProcessBtn) {
                        window.scrollTo({
                            top: agreeProcessBtn.getBoundingClientRect().top + window.scrollY - 300,
                            behavior: 'smooth',
                        });
                    }
                }
                }} id={`registration-form`}>
                <div className={`w-flex mb-5`}>  
                    <div className={`w-flex pl-5 md-pl-3 row nogap top noscroll`}>
                        <div className={`pr-4 md-pr-3 md-col-12 col-7`}>
                            <p data-hidden={true} data-md-shown={true} data-size={4} className={`semibold seminarrow center mb-4`}>
                                {globalContext.community ? <FormattedMessage id="ob_header_common"/> : <>
                                <FormattedMessage id="ob_header_common"/><br/>{`to Osmos people`}</>}
                            </p>

                            <div className={`block mb-4 b-3 p-3 w-flex`}>
                                <p className={`text-4 px semibold semibold mb-2`}>
                                    {`First name*`}
                                </p>
                                <input autoComplete={`off`} className={`desktop mb-3 ${fname.length < 2 ? 'in' : ''}valid ${CheckLatin(fname) ? 'only-latin' : ''}`} placeholder={`Enter your first name`} type={`text`} required={true}
                                    value={fname} id={`fname-input`} onChange={e => {setFName(e.target.value)}} minLength={2} autoFocus={true}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p className={`text-4 px semibold semibold mb-2`}>
                                    {`Last name*`}
                                </p>
                                <input autoComplete={`off`} className={`desktop mb-3 ${lname.length < 2 ? 'in' : ''}valid ${CheckLatin(lname) ? 'only-latin' : ''}`} placeholder={`Enter your last name`} type={`text`} required={true}
                                    value={lname} id={`lname-input`} onChange={e => {setLName(e.target.value)}} minLength={2}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p data-size={4} className={`px semibold mb-2 px`}>
                                    {`Industry*`}
                                </p>
                                <div className={`block row left bottom ${!industry?.length ? 'in' : ''}valid p-2 b-${(addIndustry || industry?.length === 0) ? 1 : 4}`} data-color={`light-gray`}
                                    onClick={e => { e.stopPropagation() }} style={{overflow: `hidden`}}>
                                    {(addIndustry || industry?.length === 0) &&                    
                                    <input  value={newIndustry} onChange={e => {
                                                setNewIndustry(e.target.value);
                                            }} onKeyDown={e => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (newIndustry) {
                                                        setIndustry((val: any) => val.includes(newIndustry.trim()) ? val : val.concat([newIndustry.trim()]));
                                                        hideIndustryList();
                                                    }
                                                }
                                            }} onFocus={e => {
                                                setAddIndustry(true);
                                            }} className={`block w-flex`} placeholder={`Enter an industry you work in and press Enter`}
                                            style={{
                                                lineHeight: `30px`,
                                            }} data-color={`transparent`} id={`input-industry`}></input>}
                                    {(industry ?? []).map((elem: string, index: number) => {return (
                                        <button className={`block btn-circled-40 desktop p-left row noshrink ${!index && addIndustry ? `mt-2` : ``}`} onClick={() => {
                                            setIndustry((val: any) => val.filter((val_: string) => val_ !== elem));
                                        }} data-color={`black`} id={`select-btn`} type={`button`}>
                                            <div className={`btn-symbol close w-3 h-3 centered`} data-color={`white`}>
                                                <img src={PlusWhite} alt={`+`}></img>
                                            </div>
                                            <p>{elem}</p>
                                        </button>
                                    )})}
                                    {!addIndustry ?
                                    <button className={`btn-circled-40 desktop p-left row left noscroll nowrap`} onClick={() => {
                                        setAddIndustry(true);
                                    }} hidden={industry?.length === 0}>
                                        <div className={`btn-symbol w-3 h-3 centered`} data-color={'black'}>
                                            <img src={PlusBlack} alt={`+`}></img>
                                        </div>
                                        <p data-color={'dark-gray'}><FormattedMessage id="add"/></p>
                                    </button> :                    
                                    <div className={`block w-flex max-h-7 neg-mb-2 y-auto`} data-color={`light-gray`}>
                                        {Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))
                                        .map((elem: string, index: number) => {return (
                                            <button className={`btn desktop row mb-2 w-flex`} style={{marginTop: !index ? 6 : undefined}}
                                                    onClick={() => {
                                                        // setNewIndustry("");
                                                        setIndustry((val: any) => 
                                                            val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                                        )
                                                        // setIndustryAutoFill(false);
                                                        // setAddIndustry(false);
                                                        const input = document.querySelector(`#input-industry`) as HTMLInputElement;
                                                        if (input) {
                                                            input.focus();
                                                        }
                                                    }} type={`button`} id={`select-btn`} key={index}>
                                                <p data-size={8} className={industry.includes(elem) ? `semibold` : ``}>
                                                    {elem}
                                                </p>
                                            </button>
                                        )})}
                                    </div>}
                                </div>
                                <p className={`after pt px`} data-color={`coral`}></p>

                                <div className={`row w-flex left mt-2`}>
                                    {[
                                        `AI`, `Vc`, `DeepTech`, `E-commerce`, `Education`, `EnergyTech`, `FinTech`,  
                                        `Future of Work`, `Games`, `MarketingTech`, `Media/Content`, 
                                        `Real Estate/PropTech`, `Sales/CRM`, `Social Networks`, `Web3`,
                                    ].map((elem: string, index: number) => {return (
                                        <button className={`block btn-circled-32 desktop`} key={index}
                                                data-color={industry.includes(elem) ? `green` : `light-gray`} 
                                                onClick={() => {
                                                    setIndustry(val => val.includes(elem) ? val.filter(ind => ind !== elem) : val.concat([elem]))
                                                }} type={`button`}>
                                            <p data-color={industry.includes(elem) ? null : `black-40-opacity`}>{elem}</p>
                                        </button>
                                    )})}
                                </div>

                                {/* <p className={`text-4 px semibold semibold mb-2`}>
                                    {`LinkedIn`}
                                </p>
                                <div className={`row input-block desktop ${!linkedIn ? 'in' : ''}valid`}>
                                    <input autoComplete={`off`} className={`desktop`} placeholder={`Enter your profile link`} type={`text`}
                                        value={linkedIn} id={`linkedin-input`} onChange={e => {setLinkedIn(e.target.value)}}></input> 
                                    <img className={`w-3`} src={LinkedInLogo} alt={`li`}></img>
                                </div>
                                <p className={`after pt-1`} data-color={`coral`}>{!email ? <FormattedMessage id="edit_required_filed"/> : `Check your link. This Profile doesn't exist`}</p> */}
                                
                                <button className={`btn-checkbox desktop row left top nowrap custom-style mt-3 px-1 pt-2 pb-2`} type={`button`}
                                     onClick={() => {setRemindCalendar(val => !val)}}>
                                    <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={remindCalendar}>
                                        <img src={OkWhite} alt={`v`}></img>
                                    </div>
                                    <p data-size={5} data-color={`black`}><FormattedMessage id="ob_notify_matches"/></p>
                                </button>
                                
                                <button className={`btn-checkbox desktop row left top nowrap custom-style px-1 pt-2 pb-2`} type={`button`}
                                     onClick={() => {setAgreeProcessData(val => !val); setNeedAgree(false);}} id={`agree-process-data-btn`}>
                                    <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={agreeProcessData} data-error={needAgree}>
                                        <img src={OkWhite} alt={`v`}></img>
                                    </div>
                                    <p data-size={5} data-color={needAgree && !agreeProcessData ? `coral` :`black`} className={``} 
                                       style={{marginTop: 2, whiteSpace: `normal`}} onClick={e => { e.stopPropagation() }}>
                                        <FormattedMessage id="ob_concent"/>&nbsp;	
                                        <a href={`${window.location.origin}/privacy`}>
                                            <span data-color={`green`}><FormattedMessage id="ob_processing_data"/></span>
                                        </a>
                                    </p>
                                </button>
                            </div>                        
                        </div>  

                        <div className={`pr-5 md-pr-3 md-col-12 col-5 mb-4`} data-md-hidden={true} 
                             style={Sticky(38) as HTMLAttributes<HTMLDivElement>}>
                            <div className={`block b-3 p-3 w-flex`}>
                                <p data-size={4} className={`semibold seminarrow mb-2`}>
                                    {`Why your profile's depth matters`}
                                </p>
                                <p data-size={5}>
                                    {`Our recommendation system relies heavily on the information you provide. The more details in your profile, the higher the quality of our recommendations.`}
                                </p>
                            </div> 
                        </div>                 
                    </div>
                    <div className={`w-flex pl-5 md-pl-3 row nogap top noscroll`}>
                        <div className={`pr-4 md-pr-3 md-col-12 col-7`}>
                            <div className={`block mb-4 b-3 p-3 w-flex`}>
                                <p className={`px text-4 semibold mb-2`}>
                                    {`Company*`}
                                </p>
                                <input autoComplete={`off`} className={`mb-3 ${!company ? 'in' : ''}valid ${CheckLatin(company) ? 'only-latin' : ''} desktop`} 
                                    placeholder={messages[globalContext.locale].occupation_company_ph} type={`text`} required={true}
                                    value={company} id={`job-input`} onChange={e => {setCompany(e.target.value)}}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p className={`px text-4 semibold mb-2`}>
                                    {`Role*`}
                                </p>
                                <input autoComplete={`off`} className={`mb-3 ${!occupation ? 'in' : ''}valid ${CheckLatin(occupation) ? 'only-latin' : ''} desktop`} placeholder={`Enter your role in the company`} type={`text`} required={true}
                                    value={occupation} id={`role-input`} onChange={e => {setOccupation(e.target.value)}}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p className={`text-4 semibold mb-2 px`}>
                                    {`Your city*`}
                                </p>
                                <input autoComplete={`off`} className={`desktop mb-3 ${!city ? 'in' : ''}valid ${CheckLatin(city) ? 'only-latin' : ''}`} placeholder={messages[globalContext.locale].city_ph} type={`text`} required={true}
                                    value={city} id={`city-input`} onChange={e => {setCity(e.target.value)}}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p className={`text-4 semibold mb-2 px`}>{`Describe yourself and your work to get the attention of people you're looking for*`}</p>
                                <textarea className={`p-2 b-1 h-8 ${!DescriptionLevel(description)?.isValid ? 'in' : ''}valid`} 
                                        placeholder={messages[globalContext.locale].ob_intro_hint} 
                                        required={false} id={`description-input`}
                                        value={description} onChange={e => {setDescription(e.target.value)}}></textarea> 
                                {/* <div className={`block h w-flex mt-1 b row left`} data-color={`light-gray`}>
                                    <div className={`h-flex b`} style={DescriptionLevel(description)?.style}></div>
                                </div> */}
                                {(DescriptionLevel(description)?.isText && !DescriptionLevel(description)?.isValid) ?
                                <p data-size={6} 
                                   data-color={!DescriptionLevel(description)?.isValid ? `coral` : `black-40-opacity`}>
                                    {DescriptionLevel(description)?.text}
                                </p> :
                                <p className={`after neg-mt-1 pt-1`} data-color={`coral`}></p>}
                            </div>  

                            <div className={`block mb-2 b-3 p-3 w-flex`} hidden={true}>
                                <p className={`px text-4 semibold mb-2 px`}>
                                    {`Your photo`}
                                </p>
                                <div className={`row top nowrap nogap block b-2 w-flex h-8`} data-color={`light-gray`}
                                     id={`registration-image-field`}>
                                    <input autoComplete={`off`} id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                                        onChange={e => {
                                            if (e.target.files?.length) {
                                                setAvatar_(e.target.files[0]);
                                            }
                                        }} name={`preview`}></input>
                                    {!!avatarUrl ? <>
                                        <div></div>
                                        <div className={`w-8 h-flex b-2`} style={{
                                                backgroundImage: `url(${avatarUrl})`,
                                            }}></div> 
                                        <button className={`block btn neg-ml-5 mt-2 mr-2 w-4 h-4 b-1 centered`}
                                                onClick={() => { 
                                                    setAvatar_(null); 
                                                    setAvatarUrl(``);
                                                    (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                                }} type={`button`}>
                                            <img src={TrashBlack} alt={``} className={`w-3 h-3`}></img>
                                        </button>                                                               
                                    </> :
                                    <div className={`w-flex h-flex centered`}>
                                        <button className={`block btn-flex desktop b-1 centered`} data-color={`gray`} type={`button`}
                                                onClick={() => { (document.querySelector(`#input-img`) as HTMLInputElement).click() }}>
                                            <p><FormattedMessage id="edit_upload_photo"/></p>
                                        </button>
                                    </div>}
                                </div> 
                            </div> 

                            <button className={`block btn-flex desktop w-flex row center mb-4`} data-color={isFilled ? `black` : `gray`}
                                    type={`submit`} onClick={() => { setInvalidForm(true) }}>
                                {waitRegistration ? 
                                <Loading color={`white`} width={24} height={24}/> :
                                <p data-color={isFilled ? null : `white`}><FormattedMessage id="create_profile"/></p>} 
                            </button>                         
                        </div>  

                        <div className={`pr-5 md-pr-3 md-col-12 col-5 mb-4`} data-md-hidden={true} 
                             style={Sticky(38) as HTMLAttributes<HTMLDivElement>}>
                            <div className={`block b-3 p-3 w-flex`}>
                                <p data-size={4} className={`semibold seminarrow mb-2`}>
                                    {`Don't forget to add a bio`}
                                </p>
                                <p data-size={5}>
                                    {`It'll make starting up chats with your matches easier.`}
                                </p>
                            </div> 
                        </div>                 
                    </div>
                    {/* <div className={`sticky-bottom w-flex px-5`} style={{marginTop: -98}}>
                        <div className={`row right visible pb-4`}>                            
                            <button className={`block btn-large desktop row box-shadow w-8`} type={`submit`} 
                                    data-color={isFilled ? `blue` : 'gray'} 
                                onClick={() => { 
                                    setInvalidForm(true);
                                }}>
                                {isFilled ?
                                <p className={`semibold`} data-color={`white`}><FormattedMessage id="create_profile"/></p> :
                                <p className={`semibold not-active`} data-color={'black-10-opacity'}><FormattedMessage id="create_profile"/></p>}
                                {waitRegistration ? <Loading color={`white`} width={24} height={24}/> : 
                                <div className={`btn-symbol w-3 h-3 centered custom-hover`} data-color={isFilled ? `white` : 'black'}
                                    style={{opacity: isFilled ? undefined : 0.1}}>
                                    <img src={isFilled ? AngleRightWhite : AngleRightBlack} alt={`>`} className={`not-scale w-2`}></img>
                                </div>}
                            </button>
                        </div>
                    </div> */}
                </div>

                <FooterDesktop links={true} agreement={true} hideCommunity={true}/>
            </form>}

            {!(step - 2) && 
            <div className={`row w-flex center`}> 
                <div className={`w-flex`} data-hidden={true} data-md-shown={true}>
                    <p data-size={4} className={`semibold seminarrow center mb-4`}>
                        {`Check out queries`}<br/>{`from other professionals`}
                    </p>

                    <div className={`w-screen row nowrap nogap mb-5 px-4`} style={{
                            left: `min(-50vw + 50%, 0px)`, overflow: `hidden`,
                        }}onMouseEnter={() => {
                            setShowScrollButtons(true);
                        }} onMouseLeave={() => {
                            setShowScrollButtons(false);
                        }}>
                        <button className={`block btn p-1 b-4 centered box-shadow`} style={{zIndex: 1, marginLeft: -30}}
                                onClick={() => { setSampleRequestNumber(val => (val + 5) % 6) }} hidden={!showScrollButtons}>
                            <img src={AngleRightBlack} alt={`>`} className={`h-2`} style={{transform: `rotate(180deg)`}}></img>
                        </button>
                        <div></div>
                        {/*VAR 1 (row)*/}
                        <div className={`row nogap nowrap noshrink`} style={{
                            overflow: `visible`, width: 630,                            
                        }}>                
                            {RequestExamples.map((elem, index) => {return (
                                <div className={`w-flex`} style={{
                                    transition: 'margin 0.5s ease-in-out',
                                    marginLeft: !index ? `${-sampleRequestNumber * 100}%` : 0,
                                    padding: `0 7px`,
                                }} key={index}>
                                    <FeedRequestCardDesktop request={elem} onboardingV={true}/>
                                    {/* <div className={`block w-flex b-3 p-1`} data-color={elem.color}>
                                        <div className={`block b-2 p-2 h-flex`} style={{minHeight: 240, padding: 14}}>
                                            <div className={`row mb-2 nowrap top`}>
                                                <div>
                                                    <p data-size={8} className={`semibold`} data-color={`black`}>{elem.name}</p>
                                                    <p data-size={8} data-color={`black-40-opacity`} className={`semibold`}>{elem.job}</p>
                                                </div>
            
                                                <NewAvatar size={4} type={elem.style} name={elem.name} avatar={elem.avatar}/>
                                            </div>
                                            <p data-size={6} data-color={`black`}>{elem.description}</p>
                                        </div>
                                    </div> */}
                                </div>
                            )})}
                        </div>
                        {/*VAR 2 (cascade)*/}
                        {/* <div className={`row nogap nowrap noshrink center`} style={{
                            overflow: `visible`, width: 380,                            
                        }}>                
                            {RequestExamples.map((elem, index) => {return (
                                <div className={`w-flex`} style={{
                                    transition: 'margin 0.3s ease-in-out, transform 0.3s ease-in-out, z-index 0.3s ease-in-out',
                                    marginLeft: `-50%`,
                                    marginRight: `-50%`,
                                    height: 314,
                                    zIndex: zIdxs[index + 5 - sampleRequestNumber],
                                    transform: `scale(${scales[index + 5 - sampleRequestNumber]}) translate(${shifts[index + 5 - sampleRequestNumber]})`,
                                }} key={index}>
                                    <div className={`block b-3 w-flex`} style={{
                                        marginBottom: -314,
                                        height: 314,
                                    }}></div>
                                    <div className={`block w-flex b-3 p-1 h-flex`} data-color={elem.color} style={{
                                        opacity: scales[index + 5 - sampleRequestNumber] * 3 - 2,
                                        transition: `opacity 0.3s ease-in-out`,
                                    }}>
                                        <div className={`block b-2 h-flex`} style={{padding: 20}}>
                                            <div className={`row mb-2 top nowrap`}>
                                                <div>
                                                    <p data-size={5} className={`semibold`} data-color={`black`}>{elem.name}</p>
                                                    <p data-size={5} data-color={`black`}>{elem.job}</p>
                                                </div>

                                                <NewAvatar size={4} type={elem.style} name={elem.name} avatar={elem.avatar} className={`circled`}/>
                                            </div>
                                            <p data-size={5} data-color={`black`}>{elem.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </div> */}
                        <div></div>
                        <button className={`block btn p-1 b-4 centered box-shadow`} style={{zIndex: 1, marginRight: -30}}
                                onClick={() => { setSampleRequestNumber(val => (val + 1) % 6) }} hidden={!showScrollButtons}>
                            <img src={AngleRightBlack} alt={`>`} className={`h-2`} style={{transform: `rotate(0deg)`}}></img>
                        </button>
                    </div>
                
                    <div className={`w-flex centered mb-5 py-1`} onClick={e => {
                            window.scroll({
                                top: (e.target as HTMLDivElement).getBoundingClientRect().bottom + window.scrollY,
                                behavior: `smooth`,
                            })
                        }}>
                        <img src={AngleRightBlack} style={{
                            height: 64, width: 64, opacity: 0.15,
                        }} alt={``} className={`angle-moving`}></img>
                    </div>

                    <p data-size={4} className={`semibold seminarrow center mb-4`}>
                        {`Get inspired and create`}<br/>{`your first request`}
                    </p>
                </div>

                <div className={`row w-flex pr-5 nogap top`} style={{flexDirection: `row-reverse`, paddingLeft: 20}}>
                    <div className={`md-col-12 col-5 pl-4 mb-4`} data-md-hidden={true}>
                        <div className={`block w-flex px-4 pt-4 b-3 y-auto`} id={`request-samples`} style={{height: addRequestBlockHeight ?? 537}}>
                            <p data-size={4} className={`px semibold seminarrow`} data-color={`black-40-opacity`}>
                                <FormattedMessage id="onboarding_other_users_requests"/>
                            </p>
                            <div className={`row w-flex gap-2 pb-4 pt-3`}>
                            {RequestExamples.map((elem: any, index: number) => {return (
                                <FeedRequestCardDesktop request={elem} onboardingV={true} key={index}/>
                                // <div className={`block w-flex b-3 p-1`} data-color={elem.color} key={index}>
                                //     <div className={`block b-2 p-2`} style={{padding: 20}}>
                                //         <div className={`row mb-2 nowrap top`}>
                                //             <div>
                                //                 <p data-size={5} className={`semibold`} data-color={`black`}>{elem.name}</p>
                                //                 <p data-size={5} data-color={`black-40-opacity`} className={`semibold`}>{elem.job}</p>
                                //             </div>
        
                                //             <NewAvatar size={4} type={elem.style} name={elem.name} avatar={elem.avatar}/>
                                //         </div>
                                //         <p data-size={5} data-color={`black`}>{elem.description}</p>
                                //     </div>
                                // </div>
                            )})}
                            </div>
                            <div className={`row center w-flex`} style={{
                                position: `sticky`, bottom: 10,
                            }}>
                                <button className={`block btn desktop p-2 b-2 row`} style={{
                                    boxShadow: `0px 1px 3px 0px #00000024`,
                                    marginTop: -62,
                                }} onClick={() => {
                                    const samples = document.querySelector(`#request-samples`);
                                    if (samples) {
                                        samples.scrollTo({
                                            top: samples.scrollTop + 300,
                                            behavior: `smooth`,
                                        })
                                    }
                                }}>
                                    <img src={AngleRightGray} alt={``} className={`w-3 h-3 angle-moving`}></img>
                                </button>
                            </div>
                        </div>
                    </div>

                    <form className={`md-col-12 col-7 pl-4`} onSubmit={e => {
                        e.preventDefault();
                        (e.target as Element).classList.add(`invalid-form`);

                        if (RequestsLevel(((e.target as HTMLFormElement).elements.namedItem(`answer`) as HTMLTextAreaElement)?.value)?.isValid) {
                            const data = {
                                requests: [{
                                    title: Strings().requests[request ?? 0].title,
                                    answers: [
                                        ((e.target as HTMLFormElement).elements.namedItem(`answer`) as HTMLTextAreaElement)?.value,
                                        '',
                                        '',
                                    ],
                                    background_type: selectBackground ? backgroundType : null,
                                }]
                            }

                            if (!requestCreated) {
                                setWaitRegistration(true);
                        
                                ProfileUpdate(userId ?? cookie[`user_id_cookie`], data, null, () => {
                                    setWaitRegistration(false);                                
                                    DataLayerPush({'event': 'complete_registration'});
                                    setRequestCreated(true);
                                    if (globalContext?.community) {
                                        navigate("/profile/match");
                                    } else {
                                        setBecomeAMemberPopup(true);
                                    }
                                }, () => {
                                    setWaitRegistration(false);
                                }, () => {}, () => {}, () => {
                                    setMailErrorPopup(true);
                                    setWaitRegistration(false);
                                })
                            } else {
                                if (globalContext?.community) {
                                    navigate("/profile/match");
                                } else {
                                    setBecomeAMemberPopup(true);
                                }
                            }
                        }
                    }} id={`new-request-form`}>
                        <div className={`block w-flex p-4 b-3 mb-2`} id={`add-request-block-onboarding`}>
                            <p data-size={4} className={`px semibold seminarrow mb-3`}>
                                <FormattedMessage id="describe_expectations"/>&nbsp;
                                {CommunityLogo[globalContext.community]?.title ?? "Osmos"}
                            </p>

                            <div className={`row left mb-4`}>
                                {[
                                    `share experience`,
                                    `find a job`,
                                    `hire`,
                                    `find clients`,
                                    `raise funds`,
                                    `invest`,
                                    `find experts`,
                                    `find partners`,
                                    `network`,
                                ].map((elem, index) => {return (
                                    <button className={`block btn-circled-32 desktop`} key={index} data-color={(index === request) ? `green` : `light-gray`} 
                                            type={`button`} onClick={() => { setRequest(index) }} hidden={(/::hidden/).test(elem)}>
                                        <p data-color={(index === request) ? null : `black-40-opacity`}>{elem}</p>
                                    </button>
                                )})}
                            </div>

                            <p data-size={4} className={`semibold px mb-1`}><FormattedMessage id="edit_describe_request"/></p>
                            <p data-size={5} className={`semibold px mb-3`} data-color={`black-40-opacity`}>
                                <FormattedMessage id="request_describe_request_hint_1"/><br/>
                                <FormattedMessage id="request_describe_request_hint_2"/>
                            </p>     

                            <div className={`block w-flex b-1`} style={{
                                position: `relative`, height: selectBackground ? 203 : 165, flexDirection: `column`,
                            }}>
                                <textarea className={`p-2 desktop h-flex valid ${selectBackground ? "b-3" : "b-1"}`} placeholder={Strings().requests[request ?? 0]?.placeholder}
                                        value={requestDescription} name={`answer`} onChange={e => {
                                            // setRequestHint(e.target.value?.length < 50);
                                            setRequestDescription(e.target.value);
                                            if (!RequestsLevel(e.target.value)?.isValid) {
                                                e.target.classList.replace(`valid`, `invalid`);
                                            } else {
                                                e.target.classList.replace(`invalid`, `valid`);
                                            }
                                        }} onFocus={e => {
                                            // setRequestHint(e.target.value?.length < 50);
                                        }} onBlur={() => { 
                                            // setRequestHint(false) 
                                        }} style={{
                                            wordBreak: `break-word`,
                                            ...(selectBackground ? {
                                                fontSize: fontSize(requestDescription), 
                                                fontWeight: 600, lineHeight: `120%`,
                                                padding: 24,
                                            } : {
                                                paddingTop: 14, marginBottom: -28,
                                                fontSize: 16, lineHeight: `140%`,
                                            }),
                                        }} data-color={selectBackground ? RequestColors2[backgroundType]?.color : `light-gray`}
                                        data-text-color={selectBackground ? RequestColors2[backgroundType]?.textColor : `black`}></textarea>

                                <div className={`block row nowrap noshrink`} style={{
                                    position: `absolute`, right: 14, bottom: 14, 
                                    maxWidth: selectBackground ? 590 : 44,
                                    transition: `max-width 0.2s ease-in-out, padding 0.2s ease-in-out`,
                                    borderRadius: selectBackground ? 11.5 : 5.5, 
                                    border: `solid 2px #FFFFFF`, padding: selectBackground ? 6 : 0,
                                    backgroundColor: `lin`, overflow: `hidden`,
                                    flexDirection: `row-reverse`,
                                    boxShadow: `0px 1px 3px 0px #00000024`,
                                }}>
                                    <button style={{width: 40, height: 40, borderRadius: 3.5}} 
                                    className={`centered block`} onClick={() => { 
                                        setSelectBackground(val => !val);
                                    }} type={`button`} data-color={selectBackground ? `light-gray` : (requestDescription?.length > 220 ? `gray` : `green`)} 
                                    disabled={requestDescription?.length > 220}>
                                        <p data-size={5} data-color={selectBackground ? `black` : `white`} className={`semibold seminarrow`}>{`Aa`}</p>
                                    </button>
                                    <div className={`row left noshrink`} style={{width: 526}}>
                                        {RequestColors2.map((elem: any, index: number) => {return (
                                            <div className={`block`} style={{padding: 2, borderRadius: 7.5, margin: -2}} key={index}
                                                    data-color={index === backgroundType ? 'black-40-opacity' : null}>
                                                <div className={`block`} style={{padding: 2, borderRadius: 5.5}}
                                                        data-color={index === backgroundType ? null : elem?.color}>
                                                    <button className={`row block`} type={`button`} style={{
                                                        // background: `linear-gradient(${elem.gradient.join(", ")})`,
                                                        width: 36, height: 36, borderRadius: 3.5,
                                                    }} onClick={() => { setBackgroundType(index) }} data-color={elem?.color}></button>
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={`block h w-flex mt-1 b row left`} data-color={`light-gray`}>
                                <div className={`h-flex b`} style={{...RequestsLevel(requestDescription)?.style}}></div>
                            </div> */}
                            <div className={`neg-mt`}></div>
                            {RequestsLevel(requestDescription)?.isText &&
                            <p data-size={6} className={`mt`}
                                data-color={!RequestsLevel(requestDescription)?.isValid ? `coral` : `black-40-opacity`}>
                                {RequestsLevel(requestDescription)?.text}
                            </p>}
                        </div>

                        <button className={`block btn-flex desktop w-flex mb-6`} data-color={`black`} 
                                disabled={!RequestsLevel(requestDescription)?.isValid || request === null}
                                type={`submit`}>
                            {waitRegistration ? 
                            <Loading color={`white`} width={24} height={24}/> :
                            <p className={`center`}><FormattedMessage id="onboarding_create_first_request"/></p>}
                        </button>
                        {/* <button className={`block btn-flex desktop w-flex mb-6`} data-color={`transparent`}
                                type={`button`} onClick={() => { setBecomeAMemberPopup(true); }}>
                            <p className={`center`} data-color={`blue`}>{`I don't have a request yet, show me Osmos`}</p>
                        </button> */}
                    </form>
                </div>  

                <FooterDesktop links={true} agreement={true} hideCommunity={true}/>         
            </div>}
        </div>

        {mailOkPopup &&
        <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                   title={<FormattedMessage id="reg_check_email"/>}
                   buttonTitle={mailbox ? <FormattedMessage id="reg_open_mailbox"/> : 
                        <FormattedMessage id="reg_ok_got_it"/>} onOk={() => {
                        if (mailbox) {
                            window.location.href = mailbox;
                        } else {
                            EmailAuthConfirm(
                                email, setUserId, 0, 
                                () => {
                                    setMailOkPopup(false);
                                    if (emailExist) {
                                        navigate('/profile');
                                    } else {
                                        setStep(1);
                                    }
                                },
                                () => {                                            
                                    setMailOkPopup(false);
                                }, () => {
                                    setMailResendPopup(true);
                                }
                            )
                        }
                   }} desktop={true}/>}
        
        {mailResendPopup &&
        <InfoPopup _id={`mail-ok-popup`} show={mailResendPopup} setShow={setMailResendPopup}
                   title={<FormattedMessage id="reg_auth_timeout"/>}
                   buttonTitle={<FormattedMessage id="reg_send_again"/>} onOk={() => {
                        EmailAuth(
                            email, setEmailExist, setMailbox, 
                            searchParams.get('code'), 
                            searchParams.get('community'), 
                            searchParams.get('redirect'), 
                            (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                text: searchParams.get('can_help'),
                                requestId: searchParams.get('request_id'),
                            } : null,
                            {
                                200: () => {                                     
                                    setMailOkPopup(true);
                                setMailResendPopup(false);
                                }, 
                                0: () => {
                                    setMailErrorPopup(true);
                                    setMailResendPopup(false);
                                },
                            },
                        )
                   }} desktop={true}/>}

        {mailErrorPopup &&
        <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                        title={<FormattedMessage id="reg_error"/>}
                        buttonTitle={<FormattedMessage id="reg_try_again"/>} onOk={() => {
                                setMailErrorPopup(false);
                                setStep(0);
                        }} desktop={true}/>}

        {confirmEmailPopup &&
        <PopupCenter show={confirmEmailPopup} setShow={setConfirmEmailPopup} desktop={true} mailbox={mailbox} onClose={() => { setStep(2) }}/>}      

        {becomeAMemberPopup &&
        <PopupCenterBecomeAMember show={becomeAMemberPopup} setShow={setBecomeAMemberPopup} redirect={redirect}/>}
    </> : <div className={`body top skeleton`} style={{maxWidth: 1920}}></div>)
}

export default RegistrationDesktop;