import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { 
    NewAvatar,
    Edit,
    Loading,
} from "../elements/Elements.jsx";
import { 
    AddRequestDesktop,
    AddRequestPopup,
    ConfirmPopup,
    PopupCanHelpMobile,
    PopupCenterCanHelp,
} from "../elements/Popups";

import { EllipsisGray, ShareBlack, TrashCoral } from "../elements/Imgs";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { ClipBoard, CreateHSLColor, FirstSelected, GoTo, RequestColors2, SetDict, Share } from "../elements/Data";
import { useCookies } from "react-cookie";
import { CardDelete, RequestRelevanceRequest } from "../Requests";
import { FormattedMessage } from "react-intl";

export const FeedRequestCardDesktop = (props: any) => { 
    const navigate = useNavigate();
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ request, setRequest ] = useState<any>();
    const [ profile, setProfile ] = useState<any>();
    const [ requestNumber, setRequestNumber ] = useState<number>(0);

    const [ wishToHelpPopup, setWishToHelpPopup ] = useState<boolean>(false);
    const [ isReadMore, setIsReadMore ] = useState<boolean>(false);
    // const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ seeFullRequest, setSeeFullRequest ] = useState<boolean>(false);
    const [ rateRequest, setRateRequest ] = useState<boolean>(false);
    const [ waitRateRequest, setWaitRateRequest ] = useState<boolean>(false);
    const [ confirmDeleteRequest, setConfirmDeleteRequest ] = useState<boolean>(false);

    const onScroll = (e: any) => {
        document.querySelectorAll(`[data-lines]`).forEach(elem => {
            elem.scrollTo({
                top: 0,
                behavior: 'instant',
            })
        })


    }

    const onCloseRate = (e: any) => {
        setRateRequest(false);
    }
    
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 18;
        } 
        if (text?.length > 75) {
            return 20;
        } 
        if (text?.length > 55) {
            return 22;
        } 
        return 24;
    }

    useEffect(() => {
        if (props.profile) {
            setProfile(props.profile);
            setRequest(props?.request ?? props.profile?.requests[requestNumber]);
            // setRndColor(ScreenColors[
            //     [8, 3, 0, 5, 1, 4, 2][Math.min(6, OnlyValid(props.profile?.requests)?.length)]
            // ]);
        } else {
            setProfile(props.request?.user)
        }
        if (props.profile?.requests?.length > 0) {
            setRequestNumber(Math.max(0, props.profile?.requests.findIndex((val: any) => val.id === props.request?.id)));
        }
    }, [props.profile, props.request])

    useEffect(() => {
        setRequest(props?.profile?.requests[requestNumber] ?? props?.request);
    }, [requestNumber, props.request])

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('click', onCloseRate, true);

        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('click', onCloseRate, true);
        }
    }, [])
    
    useEffect(() => {
        const aboutElement = document.querySelector(`#feed-card-about-${request?.id}`) as HTMLDivElement;
        setIsReadMore(aboutElement?.offsetHeight < aboutElement.scrollHeight);
    }, [request?.id])
    
    return (<>
        <div className={`${props.className} block ${props.onboardingV ? 'b-2' : `p-1 b-3`} btn ${cookie[`user_id_cookie`] !== request?.user?.id ? "hover-dark-white" : ""}`} 
             style={{...props.style}} hidden={(context.profile?.not_relevant_requests ?? []).includes(request?.id) && !props.showAnywhere}
             data-request-id={request?.id} data-color={rateRequest ? `dark-white` : `null`}>
            <div className={`block w-flex b-2 pt-2`} data-border={`light-gray-2`} 
                 data-color={typeof request?.style?.background === 'number' ? RequestColors2[request?.style?.background]?.color : `transparent`} 
                 style={{overflow: `hidden`}}>
                <div className={`w-flex row left gap-0 px-2`} hidden={!(request?.tags ?? profile?.industry)?.length} style={{overflow: `hidden`, maxHeight: 39}}>
                    {FirstSelected((request?.tags ?? profile?.industry)?? [], props.selectedTags ?? []).map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-24 desktop mb-2`} key={index} data-border={typeof request?.style?.background !== 'number' ? true : null}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (typeof props.setFilterByIndustry === `function`) {
                                        props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                    }
                                }} style={typeof request?.style?.background !== 'number' ? {border: `solid 1px ${CreateHSLColor(elem)}`} : {}}>
                            <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                        </button>
                    )})}
                </div>

                <div className={`w-flex px-2 column left bottom clickable`} data-lines={typeof request?.style?.background === 'number' ? null : 6} onClick={e => {
                        if (typeof props.onRequestClick === `function`) {
                            e.stopPropagation();
                            props.onRequestClick();
                        }
                    }}
                    id={`feed-card-about-${request?.id}`} data-scroll={seeFullRequest}
                    style={typeof request?.style?.background === 'number' ? {
                        paddingBottom: 14, fontSize: fontSize(request?.answers[0]),
                        minHeight: (!props.landingAndRequest && props.landingV) ? 117.6 : 96,
                    } : {fontSize: 14, minHeight: (!props.landingAndRequest && props.landingV) ? 117.6 : 40}}>
                    {request?.answers.reduce((init: string, elem: string) => {
                        return `${init}${init && '\n'}${elem.trim()}`;
                    }, '').trim().split(`\n`).map((elem: string, index: string) => {return ( elem !== '' ?
                    <p className={`article ${typeof request?.style?.background === 'number' ? `semibold seminarrow` : ``}`}
                       data-color={typeof request?.style?.background === 'number' ? RequestColors2[request?.style?.background]?.textColor : 'black'} key={index}>
                        {elem}
                    </p> : null)})}
                </div>

                {isReadMore && !props.landingV && !props.onboardingV &&
                <button className={`btn mobile px-2`} onClick={e => {
                            e.stopPropagation();
                            setSeeFullRequest(true);
                            setIsReadMore(false);
                        }}>
                    <p data-size={6} className={`semibold narrow hover mt`} data-color={'black'}>
                        <FormattedMessage id="read_more"/>
                    </p>
                </button>}
                
                <div className={`block row w-flex nowrap nogap p-2`}
                     data-color={typeof request?.style?.background === 'number' ? null : `transparent`}>
                    <div className={`row left nowrap clickable`} onClick={e => {
                        e.stopPropagation();
                        if (typeof props.onAvatarClick === `function`) {
                            props.onAvatarClick();
                        } else {
                            const newWindow: WindowProxy | null = window.open(`/profile/${profile?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }}>
                        <NewAvatar name={profile?.name} type={profile?.avatar?.style ?? 0} size={2}
                                avatar={profile?.avatar?.link}/>

                        <div className={`neg-my`}>
                            <p data-size={7} className={`semibold seminarrow`} data-color={'black'}>
                                {profile?.name ?? ``}
                            </p>
                            <p data-size={7} className={`seminarrow`} data-color={`black-40-opacity`} data-lines={1}>
                                {profile?.occupation ?? ``}
                            </p>
                        </div>
                    </div>

                    {props.landingV &&
                    <button className={`block btn-circled-32 desktop row`} 
                            data-color={`black`}
                            onClick={e => {
                                e.stopPropagation();
                                if (typeof props.onLandingCanHelp === `function`) {
                                    props.onLandingCanHelp();
                                }
                            }} type={`button`}>
                        <p><FormattedMessage id="i_can_help"/></p>
                    </button>}
                </div>
            </div>            
            <div className={`w-flex mt-1`} hidden={cookie[`user_id_cookie`] === request?.user?.id || props.landingV || props.onboardingV}>
                {props.firstLogin ? 
                <div className={`row w-flex right neg-mb-3`}>
                    <button className={`block btn-circled-40 desktop p-left row nowrap`} onClick={() => {
                        navigate(`/profile/requests`);
                    }} data-color={`transparent`}>
                        <Edit color={`black`} className={`w-3 h-3`}/>
                        <p data-color={`black`}><FormattedMessage id="edit"/></p>
                    </button>
                </div> :
                <div className={`row w-flex`}>
                    <div className={`row left nowrap gap-0`}>                    
                        {cookie[`user_id_cookie`] ?
                        <button className={`block btn-circled-32 desktop row`} 
                                data-color={`black`}
                                disabled={(context.profile?.is_requested ?? []).includes(request?.id)}
                                // hidden={!context?.isSubscriptionActive}
                                onClick={e => {
                                    e.stopPropagation();
                                    // setWishToHelpPopup(true);
                                    if (context?.isSubscriptionActive) {
                                        if (typeof context.setTab === `function`) {
                                            context.setNewChatOffer({
                                                created_at: Date.now() / 1000,    
                                                items: [],
                                                pair: request?.user,
                                                user: context.profile,
                                                is_new_requested: true, 
                                                is_can_help: true, 
                                                refer_to_request: request,
                                            })
                                            context.setPage(2, 0);
                                            context.setTab(0, 2);
                                        } else {
                                            sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                                created_at: Date.now() / 1000,    
                                                items: [],
                                                pair: request?.user,
                                                user: context.profile,
                                                is_new_requested: true,
                                                is_can_help: true, 
                                                refer_to_request: request,
                                            }));
                                            navigate(`/profile/chat?new_chat_offer=true`);
                                        } 
                                    } else {
                                        context.setActivateSubscriptionPopup(true);
                                    }
                                }} type={`button`} id={props.selectBtn ? `select-btn-hint` : `wish-to-match`} style={props.selectBtn ? {
                                    border: `solid 8px #FFFFFF`,
                                    borderRadius: 40,
                                    margin: -8,
                                } : {}}>
                            <p><FormattedMessage id="i_can_help"/></p>
                        </button> :
                        <button className={`block btn-circled-32 desktop row`} 
                                data-color={`black`}
                                onClick={e => {
                                    e.stopPropagation();
                                    GoTo(`/`, `_self`);
                                }} type={`button`}>
                            <p><FormattedMessage id="sign_in_to_help"/></p>
                        </button>}

                        {/* <button className={`block btn-circled-32 desktop`} data-color={'light-gray'}
                                onClick={e => {
                                    e.stopPropagation();
                                    context.setSuggestAFriendPopup({
                                        ...request,
                                        name: profile?.name,
                                        occupation: profile?.occupation,
                                        avatar: profile?.avatar,
                                    });
                                }} type={`button`} id={props.selectBtn ? `select-btn-hint-2` : `suggest-feed-btn`} 
                                style={props.selectBtn ? {
                                    border: `solid 8px #FFFFFF`,
                                    borderRadius: 40,
                                    margin: -8,
                                } : {}} hidden={!cookie[`user_id_cookie`] || !context?.isSubscriptionActive}>
                            <p><FormattedMessage id="suggest_friend"/></p>
                        </button> */}

                        {context.profile?.subscription?.is_subscription_active &&
                        <button className={`block btn-circled-32 desktop p-both row`} data-color={'light-gray'}
                                onClick={e => {
                                    e.stopPropagation(); 
                                    ClipBoard(`${window.location.origin}/share/${request?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                        globalContext.setClipboardOk(true);
                                    })  
                                    // if (window.isSecureContext) {
                                    //     navigator.clipboard.writeText(`${window.location.origin}/share/${request?.id}`)
                                    //     .then(() => {
                                    //         context.setClipboardOk(true);
                                    //     });
                                    // }
                                }} type={'button'} id={`share-feed-btn`} disabled={false}>
                            <img src={ShareBlack} alt={``}></img>
                        </button>}
                    </div>
                    {(
                        (context.profile?.relevant_requests ?? []).includes(request?.id) ||
                        !context?.isSubscriptionActive
                    ) ? null :
                    <div style={{position: `relative`}} onClick={e => { e.stopPropagation() }} 
                        hidden={!cookie[`user_id_cookie`]} className={`noshrink`}>
                        <button className={`btn desktop row`} onClick={e => { setRateRequest(true) }}
                                disabled={!context?.isSubscriptionActive}>
                            {waitRateRequest ?
                            <Loading color={`gray`} width={24} height={24}/> :
                            <img src={EllipsisGray} alt={``} className={`w-3`}></img>}
                        </button>
                        <div style={{position: `absolute`, bottom: 12, right: 12, width: 192}} className={`b-1 box-shadow block`} hidden={!rateRequest}>
                            <button className={`block w-flex btn desktop bt-1 row center py-1`} onClick={() => {
                                    setWaitRateRequest(true); 
                                    RequestRelevanceRequest(request?.id, true, () => { 
                                        setWaitRateRequest(false);
                                        context.setProfile((val: any) => SetDict(val, [`relevant_requests`], (val?.relevant_requests ?? []).concat([request?.id]))) 
                                    }, () => { setWaitRateRequest(false) });
                                }}>
                                <p data-size={6}><FormattedMessage id="feed_more_like_this"/></p>
                            </button>
                            <div className={`w-flex block`} data-color={`gray`} style={{height: 1}}></div>
                            <button className={`block w-flex btn desktop bb-1 row center py-1`} onClick={() => { 
                                    setConfirmDeleteRequest(true);
                                }}>
                                <p data-size={6} data-color={`coral`}><FormattedMessage id="feed_delete_from_feed"/></p>
                            </button>
                        </div>
                    </div>}
                </div>}
            </div>
        </div>

        {wishToHelpPopup &&
        <PopupCenterCanHelp show={wishToHelpPopup} setShow={setWishToHelpPopup} desktop={true} 
                            request={{...request, user_id: profile?.id}} onRequested={(id: string) => {
                                // props.setProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                                if (typeof props.onRequested === `function`) {
                                    props.onRequested(id);
                                }
                            }}/>}

        {confirmDeleteRequest &&
        <ConfirmPopup title={`Are you sure you want to delete this request from your feed?`} show={confirmDeleteRequest} 
                setShow={setConfirmDeleteRequest} _id={`yesno`}
                color_1={'black'} buttonTitle_1={<FormattedMessage id="no"/>} onClick_1={() => {}} black={true} desktop={true} white={true}
                color_2={'light-gray'} buttonTitle_2={<FormattedMessage id="yes"/>} onClick_2={() => {
                    setWaitRateRequest(true); 
                    RequestRelevanceRequest(request?.id, false, () => { 
                        setWaitRateRequest(false); 
                        context.setProfile((val: any) => SetDict(
                            val, [`not_relevant_requests`], (val?.not_relevant_requests ?? []).concat([request?.id]))); 
                    }, () => { setWaitRateRequest(false) });
                }} close={'both'}/>}
        </>) 
}

export const FeedRequestCardMobile = (props: any) => { 
    const navigate = useNavigate();  
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);  

    const [ request, setRequest ] = useState<any>();
    const [ profile, setProfile ] = useState<any>();
    const [ requestNumber, setRequestNumber ] = useState<number>(0);

    const [ isReadMore, setIsReadMore ] = useState<boolean>(false);
    // const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ wishToHelpPopup, setWishToHelpPopup ] = useState<boolean>(false);
    const [ seeFullRequest, setSeeFullRequest ] = useState<boolean>(false);
    const [ rateRequest, setRateRequest ] = useState<boolean>(false);
    const [ waitRateRequest, setWaitRateRequest ] = useState<boolean>(false);
    const [ confirmDeleteRequest, setConfirmDeleteRequest ] = useState<boolean>(false);

    const onScroll = () => {
        document.querySelectorAll(`[data-lines]`).forEach(elem => {
            elem.scrollTo({
                top: 0,
                behavior: 'instant',
            })
        });
        setRateRequest(false);
    }
    
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 14;
        } 
        if (text?.length > 75) {
            return 16;
        } 
        if (text?.length > 55) {
            return 18;
        } 
        return 20;
    }

    const onCloseRate = (e: any) => {
        setRateRequest(false);
    }

    useEffect(() => {
        if (props.profile) {
            setProfile(props.profile);
            setRequest(props?.request ?? props.profile?.requests[requestNumber]);
            // setRndColor(ScreenColors[
            //     [8, 3, 0, 5, 1, 4, 2][Math.min(6, OnlyValid(props.profile?.requests)?.length)]
            // ]);
        } else {
            setProfile(props.request?.user)
        }
        if (props.profile?.requests?.length > 0) {
            setRequestNumber(Math.max(0, props.profile?.requests.findIndex((val: any) => val.id === props.request?.id)));
        }
    }, [props.profile, props.request])

    useEffect(() => {
        setRequest(props?.profile?.requests[requestNumber] ?? props?.request);
    }, [requestNumber, props.request])

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('click', onCloseRate, true);

        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('click', onCloseRate, true);
        }
    }, [])
    
    useEffect(() => {
        const aboutElement = document.querySelector(`#feed-card-about-${request?.id}`) as HTMLDivElement;
        setIsReadMore(aboutElement?.offsetHeight < aboutElement?.scrollHeight);
    }, [request?.id])
    
    return (<>
        <div className={`block ${props.landingV ? 'b-2' : `b-3 p-2`} ${props.className}`} hidden={(context.profile?.not_relevant_requests ?? []).includes(request?.id) && !props.showAnywhere} 
            style={{
                ...props.style, padding: (props.onboardingV || props.landingV) ? 0 : 10,
            }} data-request-id={request?.id} data-color={rateRequest ? `dark-white` : null}>
            <div className={`block w-flex b-2 pt-2`} data-border={`gray-1`} 
                 data-color={typeof request?.style?.background === 'number' ? RequestColors2[request?.style?.background]?.color : `transparent`} style={{overflow: `hidden`}}>
                <div className={`w-flex row left gap-0 px-2`} hidden={!(request?.tags ?? profile?.industry)?.length} style={{overflow: `hidden`, maxHeight: 39}}>
                    {FirstSelected((request?.tags ?? profile?.industry) ?? [], props.selectedTags ?? []).map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-24 mobile mb-2`} key={index} data-border={typeof request?.style?.background !== 'number' ? true : null}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (typeof props.setFilterByIndustry === `function`) {
                                        props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                    }
                                }} style={typeof request?.style?.background !== 'number' ? {border: `solid 1px ${CreateHSLColor(elem)}`} : {}}>
                            <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                        </button>
                    )})}
                </div>

                <div className={`w-flex px-2 column left bottom`} data-lines={typeof request?.style?.background === 'number' ? null : 4} onClick={e => {
                        if (typeof props.onRequestClick === `function`) {
                            e.stopPropagation();
                            props.onRequestClick();
                        }
                    }}
                    id={`feed-card-about-${request?.id}`} data-scroll={seeFullRequest}
                    style={typeof request?.style?.background === 'number' ? {
                        paddingBottom: 14, fontSize: fontSize(request?.answers[0]),
                        minHeight: props.landingV ? 83 : (props.onboardingV ? 132 : 108),
                    } : {fontSize: 14, minHeight: props.landingV ? 83 : (props.onboardingV ? 132 : 40)}}>
                    {request?.answers.reduce((init: string, elem: string) => {
                        return `${init}${init && '\n'}${elem.trim()}`;
                    }, '').trim().split(`\n`).map((elem: string, index: string) => {return ( elem !== '' ?
                    <p className={`article ${typeof request?.style?.background === 'number' ? `semibold seminarrow` : ``}`} 
                       data-color={typeof request?.style?.background === 'number' ? RequestColors2[request?.style?.background]?.textColor : 'black'} key={index}>
                        {elem}
                    </p> : null)})}
                </div>

                {isReadMore && !props.landingV && !props.onboardingV &&
                <button className={`btn mobile px-2`} onClick={e => {
                            e.stopPropagation();
                            setSeeFullRequest(true);
                            setIsReadMore(false);
                        }}>
                    <p data-size={6} className={`semibold narrow hover mt`} data-color={'black'}>
                        <FormattedMessage id="read_more"/>
                    </p>
                </button>}

                <div className={`block row nowrap p-2`}>
                    <div className={`row left nowrap`} onClick={e => {
                        e.stopPropagation();
                        if (typeof props.onAvatarClick === `function`) {
                            props.onAvatarClick();
                        } else {
                            const newWindow: WindowProxy | null = window.open(`/profile/${profile?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }}>
                        <NewAvatar name={profile?.name} type={profile?.avatar?.style ?? 0} size={1}
                                avatar={profile?.avatar?.link}/>

                        <div className={`neg-my`}>
                            <p data-size={7} className={`semibold seminarrow`} data-color={'black'}>
                                {profile?.name ?? ``}
                            </p>
                            <p data-size={7} className={`seminarrow`} data-color={`black-40-opacity`} data-lines={1}>
                                {profile?.occupation ?? ``}
                            </p>
                        </div>
                    </div>

                    {props.landingV &&
                    <button className={`block btn-circled-32 desktop row`} 
                            data-color={`black`}
                            onClick={e => {
                                e.stopPropagation();
                                if (typeof props.onLandingCanHelp === `function`) {
                                    props.onLandingCanHelp();
                                }
                            }} type={`button`}>
                        <p><FormattedMessage id="i_can_help"/></p>
                    </button>}
                </div>
            </div>

            {props.firstLogin ? 
            <div className={`row w-flex right neg-mb-2 mt-1`}>
                <button className={`block btn-circled-32 mobile p-left row nowrap`} onClick={() => {
                    navigate(`/profile/requests`);
                }} data-color={`transparent`}>
                    <Edit color={`black`} className={`w-3 h-3`}/>
                    <p data-color={`black`}><FormattedMessage id="edit"/></p>
                </button>
            </div> :
            <div className={`row w-flex row pr mt-1`} hidden={cookie[`user_id_cookie`] === request?.user?.id || props.landingV || props.onboardingV}> 
                <div className={`row left nowrap gap-0`}>
                    {cookie[`user_id_cookie`] ?                  
                    <button className={`block btn-circled-32 mobile row`} 
                            data-color={`black`}
                            disabled={context.profile?.is_requested.includes(request?.id)}
                            // hidden={!context?.isSubscriptionActive}
                            onClick={e => {
                                e.stopPropagation();
                                // setWishToHelpPopup(true);
                                if (context?.isSubscriptionActive) {
                                    if (typeof context.setTab === `function`) {
                                        context.setNewChatOffer({
                                            created_at: Date.now() / 1000,    
                                            items: [],
                                            pair: request?.user,
                                            user: context.profile,
                                            is_new_requested: true, 
                                            is_can_help: true, 
                                            refer_to_request: request,
                                        })
                                        context.setPage(2, 0);
                                        context.setTab(0, 2);
                                    } else {
                                        sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                            created_at: Date.now() / 1000,    
                                            items: [],
                                            pair: request?.user,
                                            user: context.profile,
                                            is_new_requested: true, 
                                            is_can_help: true,
                                            refer_to_request: request,
                                        }));
                                        navigate(`/profile/chat?new_chat_offer=true`);
                                    }
                                } else {
                                    context.setActivateSubscriptionPopup(true);
                                } 
                            }} type={`button`} id={`wish-to-match`}>
                        <p><FormattedMessage id="i_can_help"/></p>
                    </button> :
                    <button className={`block btn-circled-32 mobile row`} 
                            data-color={`black`}
                            onClick={e => {
                                e.stopPropagation();
                                GoTo(`/`, `_self`);
                            }} type={`button`} disabled={!context?.isSubscriptionActive}>
                        <p><FormattedMessage id="sign_in_to_help"/></p>
                    </button>}

                    {/* <button className={`block btn-circled-32 mobile`} data-color={'light-gray'}
                            onClick={e => {
                                e.stopPropagation();
                                context.setProfilePopup(null);
                                context.setSuggestAFriendPopup({
                                    ...request,
                                    name: profile?.name,
                                    occupation: profile?.occupation,
                                    avatar: profile?.avatar,
                                });
                            }} type={`button`} id={`suggest-feed-btn`} 
                            hidden={true}>
                        <p><FormattedMessage id="suggest_friend"/></p>
                    </button> */}

                    {context.profile?.subscription?.is_subscription_active &&
                    <button className={`block btn-circled-32 mobile p-both row`} data-color={'light-gray'}
                            onClick={e => {
                                e.stopPropagation(); 
                                Share(`${window.location.origin}/share/${request?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                    globalContext.setClipboardOk(true);
                                }) 
                                // if (window.isSecureContext) {
                                //     navigator.share({
                                //         title: "Osmos",
                                //         url: `${window.location.origin}/share/${request?.id}`,
                                //     }).catch(error => {
                                //         navigator.clipboard.writeText(`${window.location.origin}/share/${request?.id}`)
                                //         .then(() => {
                                //             context.setClipboardOk(true);
                                //         });
                                //     })
                                // }
                            }} type={'button'} id={`share-feed-btn`} disabled={false}>
                        <img src={ShareBlack} className={`w-3`} alt={``}></img>
                    </button>}
                </div> 

                {(
                    (context.profile?.relevant_requests ?? []).includes(request?.id) ||
                    !context?.isSubscriptionActive
                ) ? null :
                <div style={{position: `relative`}} onClick={e => { e.stopPropagation() }} 
                    hidden={!cookie[`user_id_cookie`]} className={`noshrink`}>
                    <button className={`btn mobile row`} onClick={e => { setRateRequest(true) }}
                            disabled={!context?.isSubscriptionActive}>
                        {waitRateRequest ?
                        <Loading color={`gray`} width={24} height={24}/> :
                        <img src={EllipsisGray} alt={``} className={`w-3`}></img>}
                    </button>
                    <div style={{position: `absolute`, bottom: 12, right: 12, width: 192}} className={`b-1 box-shadow block`} hidden={!rateRequest}>
                        <button className={`block w-flex btn mobile bt-1 row center py-1`} onClick={() => {
                                setWaitRateRequest(true); 
                                RequestRelevanceRequest(request?.id, true, () => { 
                                    setWaitRateRequest(false);
                                    context.setProfile((val: any) => SetDict(val, [`relevant_requests`], (val?.relevant_requests ?? []).concat([request?.id])))  
                                }, () => { setWaitRateRequest(false) });
                            }}>
                            <p data-size={6}><FormattedMessage id="feed_more_like_this"/></p>
                        </button>
                        <div className={`w-flex block`} data-color={`gray`} style={{height: 1}}></div>
                        <button className={`block w-flex btn mobile bb-1 row center py-1`} onClick={() => { 
                                setConfirmDeleteRequest(true);
                            }}>
                            <p data-size={6} data-color={`coral`}><FormattedMessage id="feed_delete_from_feed"/></p>
                        </button>
                    </div>
                </div>}
            </div>}
        </div>

        {wishToHelpPopup &&
        <PopupCanHelpMobile show={wishToHelpPopup} setShow={setWishToHelpPopup} mobile={true} 
                            request={{...request, user_id: profile?.id}} onRequested={(id: string) => {
                                // props.setProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                                if (typeof props.onRequested === `function`) {
                                    props.onRequested(id);
                                }
                            }}/>}

        {confirmDeleteRequest &&
        <ConfirmPopup title={`Are you sure you want to delete this request from your feed?`} show={confirmDeleteRequest} 
                setShow={setConfirmDeleteRequest} _id={`yesno`}
                color_1={'black'} buttonTitle_1={<FormattedMessage id="no"/>} onClick_1={() => {}} mobile={true}
                color_2={'light-gray'} buttonTitle_2={<FormattedMessage id="yes"/>} onClick_2={() => {setWaitRateRequest(true); 
                    RequestRelevanceRequest(request?.id, false, () => { 
                        setWaitRateRequest(false); 
                        context.setProfile((val: any) => SetDict(
                            val, [`not_relevant_requests`], (val?.not_relevant_requests ?? []).concat([request?.id])));  
                    }, () => { setWaitRateRequest(false) });                    
                }} close={'both'}/>}
        </>) 
}

export const MessageCardDesktop = (props: any) => {
    const context = useContext(ProfileContext);

    const [ title, setTitle ] = useState<any>(``);
    const [ subtitle, setSubtitle ] = useState<any>(``);
    const [ buttonTitle, setButtonTitle ] = useState<string | React.JSX.Element>(``);
    const [ buttonAction, setButtonAction ] = useState<Function | null>(null);

    useEffect(() => {
        switch (props.type) {
            case `profile`:
                setTitle(null)
                setSubtitle(
                    props.profile?.rating?.overall === -1 ? <>
                        {`It looks like your requests, projects, or information may not be aligned with the interests of the people you are trying to connect with. Don't worry, we can fix that! Could you please modify your profile to better suit your audience's interests?`}
                    </> : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? <>
                            {`Tell us about your experience and projects, and fill out at least one request card. Let us know what kind of people you are looking to meet. Osmos AI will work its magic!`}
                        </> : (
                            (!props.profile?.about ? <> 
                                {`Please fill out your about to start receiving matches.`}
                            </> :
                            (!(props.profile?.projects ?? []).length ? <>
                                <FormattedMessage id="profile_add_project_description"/>
                            </> : <>	
                                {`To start getting matches, help out more with`}&nbsp;	
                                <span data-color={`green`}><FormattedMessage id="i_can_help"/></span>&nbsp;	
                                {`in your feed and respond to help offers in your chats`}
                            </>))
                        )
                    )
                )
                setButtonAction(
                    props.profile?.rating?.overall === -1 ? () => () => {
                        context.setPage(0, 3);
                        context.setTab(3, 0);
                    } : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? () => () => {
                            context.setPage(0, 0);
                            context.setTab(0, 0);
                        } : (
                            (!props.profile?.about ? () => () => {
                                context.setPage(0, 3);
                                context.setTab(3, 0);
                            } :
                            (!(props.profile?.projects ?? []).length ? () => () => {
                                context.setAddProjectPopup(true);
                            } : () => () => {
                                context.setPage(1, 0);
                                context.setTab(0, 1);
                            }))
                        )
                    )
                )
                setButtonTitle(
                    props.profile?.rating?.overall === -1 ? 
                        <FormattedMessage id="profile_edit"/> : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? 
                            `Edit about` : (
                            (!props.profile?.about ? 
                            `Edit about` :
                            (!(props.profile?.projects ?? []).length ?  
                            <FormattedMessage id="projects_add_btn"/> : `Open feed`))
                        )
                    )
                )
                break;
            case `feed`:
                setTitle(`Empty feed?`);
                setSubtitle(<>{`Chose at least one person you  wish to match with `}<br/>{`in your profile or suggested`}</>);
                // setButtonAction(() => () => { 
                //     context.setTab(1);
                // });
                // setButtonTitle(`Suggested`);
                break;
            case `projects`:
                setTitle(``);
                setSubtitle(<>
                    <FormattedMessage id="add_one_project"/><br/>{`to\xa0start getting matches`}
                </>);
                // setSubtitle(<FormattedMessage id="profile_add_project_description"/>);
                setButtonAction(() => () => { 
                    if (typeof props.projectsButtonAction === `function`) {
                        props.projectsButtonAction();
                    }
                });
                setButtonTitle(<FormattedMessage id="profile_add_a_project"/>);
                break;
            case `feedback`:
                setTitle(<>
                    {`Leave us your feedback about`}&nbsp;	
                    <a href={`/profile/${props.needFeedback}`}>	
                        <span data-color={`green`}>{props.needFeedbackName}</span>
                    </a>&nbsp;	
                    {`to unlock next one`}
                </>);
                break;
            case `chat`:
                setTitle(<FormattedMessage id="chat_empty"/>);
                setSubtitle(<>
                    <FormattedMessage id="chat_empty_description_1"/><br/>
                    <FormattedMessage id="chat_empty_description_2"/>
                </>);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(<FormattedMessage id="go_to_feed"/>);
                break;
            case `offer`:
                setTitle(``);
                setSubtitle(<>{`Responses to me will open after someone`}<br/>{`will offer help to you`}</>);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(`Open feed`);
                break;
            case `requested`:
                setTitle(``);
                setSubtitle(<>{`Here you will find your messages `}<br/>{`to others after you offer help`}</>);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(`Open feed`);
                break;
            case `notifications`:
                setTitle(null);
                setSubtitle(<>{`Here you will find the notifications `}<br/>{`about all events in your Osmos`}</>);
                setButtonAction(null);
                break;
            default:
                break;
        }
    }, [props.profile, props.type])

    return (
        <div className={`block b-3 w-flex ${props.className} p-4 centered`} style={{
            height: (props.type === 'chat' || props.type === 'offer' || props.type === 'requested') ? 643 : (props.type === `feed`) ? 392 : ``,
        }}>
            <div className={`column px-5`}>
                {/* <CardMatchesVideo className={`mb-1`}/> */}
                <p data-size={9} className={`semibold w-flex center mb-1`}>
                    {title}
                </p>
                <p data-size={8} className={`w-flex center`} data-color={`black-40-opacity`}>
                    {subtitle}
                </p>                        

                {props.type !== `feedback` ? (typeof buttonAction === `function` &&
                <button className={`block btn-flex desktop row center mt-3`} data-color={`black`} onClick={() => {
                    if (typeof buttonAction === `function`) {
                        buttonAction();
                    }
                }} style={{minWidth: 169}}>
                    <p>{buttonTitle}</p>  
                </button>) :
                <div className={`row w-flex center mt-2`}>
                    <button className={`block btn-circled-40 desktop min-w-7 row center`} data-color={`light-gray`} onClick={() => {
                        if (typeof props.feedbackButtonAction[0] === `function`) {
                            props.feedbackButtonAction[0]();
                        }
                    }}>
                        <p>{`We didn't meet`}</p>  
                    </button>
                    <button className={`block btn-circled-40 desktop min-w-7 row center`} data-color={`black`} onClick={() => {
                        if (typeof props.feedbackButtonAction[1] === `function`) {
                            props.feedbackButtonAction[1]();
                        }
                    }}>
                        <p><FormattedMessage id="ok"/></p>  
                    </button>
                </div>}
            </div> 
        </div>
    )
}

export const MessageCardMobile = (props: any) => {
    const context = useContext(ProfileContext);

    const [ title, setTitle ] = useState<any>(``);
    const [ subtitle, setSubtitle ] = useState<any>(``);
    const [ buttonTitle, setButtonTitle ] = useState<string | React.JSX.Element>(``);
    const [ buttonAction, setButtonAction ] = useState<Function | null>(null);

    useEffect(() => {
        switch (props.type) {
            case `profile`:
                setTitle(null)
                setSubtitle(
                    props.profile?.rating?.overall === -1 ? <>
                        {`It looks like your requests, projects, or information may not be aligned with the interests of the people you are trying to connect with. Don't worry, we can fix that! Could you please modify your profile to better suit your audience's interests?`}
                    </> : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? <>
                            {`Tell us about your experience and projects, and fill out at least one request card. Let us know what kind of people you are looking to meet. Osmos AI will work its magic!`}
                        </> : (
                            (!props.profile?.about ? <> 
                                {`Please fill out your about to start receiving matches.`}
                            </> :
                            (!(props.profile?.projects ?? []).length ? <>
                                {`Projects help other people understand who you are`}
                            </> : <>	
                                {`To start getting matches, help out more with`}&nbsp;	
                                <span data-color={`green`}><FormattedMessage id="i_can_help"/></span>&nbsp;	
                                {`in your feed and respond to help offers in your chats`}
                            </>))
                        )
                    )
                )
                setButtonAction(
                    props.profile?.rating?.overall === -1 ? () => () => {
                        context.setPage(0, 3);
                        context.setTab(3, 0);
                    } : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? () => () => {
                            context.setPage(0, 0);
                            context.setTab(0, 0);
                        } : (
                            (!props.profile?.about ? () => () => {
                                context.setPage(0, 3);
                                context.setTab(3, 0);
                            } :
                            (!(props.profile?.projects ?? []).length ? () => () => {
                                context.setAddProjectPopup(true);
                            } : () => () => {
                                context.setPage(1, 0);
                                context.setTab(0, 1);
                            }))
                        )
                    )
                )
                setButtonTitle(
                    props.profile?.rating?.overall === -1 ? 
                        <FormattedMessage id="profile_edit"/> : (
                        (props.profile?.first_login ?? [`profile`]).includes('profile') ? 
                            `Edit about` : (
                            (!props.profile?.about ? 
                            `Edit about` :
                            (!(props.profile?.projects ?? []).length ?  
                            <FormattedMessage id="projects_add_btn"/> : `Open feed`))
                        )
                    )
                )
                break;
            case `feed`:
                setTitle(`Empty feed?`);
                setSubtitle(`Chose at least one person you  wish to match with in your profile or suggested`);
                // setButtonAction(() => () => { 
                //     context.setTab(1);
                // });
                // setButtonTitle(`Suggested`);
                break;
            case `projects`:
                setTitle(``);
                setSubtitle(`Add at least one project that you have completed to start getting matches`);
                // setSubtitle(<FormattedMessage id="profile_add_project_description"/>);
                setButtonAction(() => () => { 
                    if (typeof props.projectsButtonAction === `function`) {
                        props.projectsButtonAction();
                    }
                });
                setButtonTitle(<FormattedMessage id="profile_add_a_project"/>);
                break;
            case `feedback`:
                setTitle(<>
                    {`Leave us your feedback about`}&nbsp;	
                    <a href={`/profile/${props.needFeedback}`}>	
                        <span data-color={`green`}>{props.needFeedbackName}</span>
                    </a>&nbsp;	
                    {`to unlock next one`}
                </>);
                break;
            case `chat`:
                setTitle(<FormattedMessage id="chat_empty"/>);
                setSubtitle(<>
                    <FormattedMessage id="chat_empty_description_1"/><br/>
                    <FormattedMessage id="chat_empty_description_2"/>
                </>);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(<FormattedMessage id="go_to_feed"/>);
                break;
            case `offer`:
                setTitle(``);
                setSubtitle(`Responses to me will open after someone will offer help to you`);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(`Open feed`);
                break;
            case `requested`:
                setTitle(``);
                setSubtitle(`Here you will find your messages to others after you offer help`);
                setButtonAction(() => () => { 
                    context.setPage(1);
                });
                setButtonTitle(`Open feed`);
                break;
            case `notifications`:
                setTitle(``);
                setSubtitle(<>{`Here you will find the notifications `}<br/>{`about all events in your Osmos`}</>);
                setButtonAction(null);
                break;
            default:
                break;
        }
    }, [props.profile, props.type])

    return (
        <div className={`block b-3 w-flex ${props.className} p-3 column top`} id={props.type === `projects` ? `profile-projects-block` : undefined}>
            {/* <CardMatchesVideoMobile className={`mb-1`}/> */}
            <p data-size={4} className={`semibold w-flex center`}>
                {title}
            </p>
            <p data-size={7} className={`w-flex center mt`} data-color={`black-40-opacity`}>
                {subtitle}
            </p>                        

            {props.type !== `feedback` ? (typeof buttonAction === `function` &&
            <button className={`block btn-flex mobile row center mt-2`} data-color={`black`} onClick={() => {
                if (typeof buttonAction === `function`) {
                    buttonAction();
                }
            }} style={{minWidth: 169}}>
                <p>{buttonTitle}</p>  
            </button>) :
            <div className={`row w-flex center mt-2`}>
                <button className={`block btn-circled-32 mobile min-w-7 row center`} data-color={`light-gray`} onClick={() => {
                    if (typeof props.feedbackButtonAction[0] === `function`) {
                        props.feedbackButtonAction[0]();
                    }
                }}>
                    <p>{`We didn't meet`}</p>  
                </button>
                <button className={`block btn-circled-32 mobile min-w-7 row center`} data-color={`black`} onClick={() => {
                    if (typeof props.feedbackButtonAction[1] === `function`) {
                        props.feedbackButtonAction[1]();
                    }
                }}>
                    <p><FormattedMessage id="ok"/></p>  
                </button>
            </div>} 
            </div>
    )
}

export const ProfileRequestCardDesktop = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    const [ cookie ] = useCookies(["user_id_cookie"]);
    const navigate = useNavigate();

    const TextBlock = useRef<HTMLDivElement | null>(null);
    const ProcessBtns = useRef<HTMLDivElement | null>(null);

    const [ isReadMore, setIsReadMore ] = useState<boolean>();
    
    const [ editPopup, setEditPopup ] = useState<boolean>(false);
    const [ deletePopup, setDeletePopup ] = useState<boolean>(false);

    useEffect(() => {
        setIsReadMore(undefined);
    }, [context.profile?.requests])

    useEffect(() => {
        if (TextBlock.current && isReadMore === undefined) {
            setIsReadMore(TextBlock.current?.offsetHeight < TextBlock.current?.scrollHeight)
        }
    }, [isReadMore])

    return (<>
        <div className={`block w-flex p-2 b-2 mb-1 clickable hover-dark-white`} onClick={() => { 
            if (window.getSelection()?.type !== "Range") {
                const newWindow: WindowProxy | null = window.open(`/request/${props.request?.id}`, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }
        }} data-border={`gray-1`} style={{position: `relative`}} onMouseEnter={() => {
            if (ProcessBtns.current && props.request?.user?.id === cookie[`user_id_cookie`]) {
                ProcessBtns.current.hidden = false;
            }
        }} onMouseLeave={() => {
            if (ProcessBtns.current) {
                ProcessBtns.current.hidden = true;
            }
        }}>
            <div className={`row left gap-0`} style={{position: `absolute`, top: 8, right: 8}}
                ref={ProcessBtns} hidden={true}>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setEditPopup(true);
                        }} type={`submit`} disabled={!context.isSubscriptionActive}>
                    <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                </button>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setDeletePopup(true);
                        }} type={`submit`} disabled={!context.isSubscriptionActive || (
                            context.profile?.requests?.length < 2 &&
                            !globalContext.community
                        )}>
                    <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                </button>
            </div>

            <div className={`w-flex row left gap-0`} hidden={!props.request?.tags?.length} style={{overflow: `hidden`, maxHeight: 32}}>
                {FirstSelected(props.request?.tags ?? [], props.selectedTags ?? []).map((elem: string, index: number) => {return (
                    <button className={`block btn-circled-24 desktop mb-2`} key={index} data-border={true}
                            onClick={e => {
                                e.stopPropagation();
                                if (typeof props.setFilterByIndustry === `function`) {
                                    props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                }
                            }} style={{border: `solid 1px ${CreateHSLColor(elem)}`}}>
                        <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                    </button>
                )})}
            </div>

            <div className={`neg-mx-1 px-1 w-flex-p-1`} data-lines={4} ref={TextBlock}
                 data-scroll={isReadMore === false}>
                {props.request?.answers.reduce((init: string, elem: string) => {
                    return `${init}${init && '\n'}${elem.trim()}`;
                }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                <p data-size={6} className={`article`} data-color={'black'} key={index}>
                    {elem}
                </p> : null)})}
            </div>

            {isReadMore &&
            <button className={`btn`} onClick={e => {
                        e.stopPropagation();
                        setIsReadMore(false);
                    }}>
                <p data-size={6} className={`semibold hover mt`} data-color={'black'}>
                    <FormattedMessage id="read_more"/>
                </p>
            </button>}

            <div className={`row mt-3 w-flex row`} hidden={props.request?.user?.id === cookie[`user_id_cookie`]}>                            
                <div className={`row left nowrap`} hidden={!cookie[`user_id_cookie`]}>
                    <button className={`block btn-circled-32 desktop row`} 
                            data-color={`black`}
                            disabled={context.ownerProfile?.is_requested.includes(props.request?.id) || !context.isSubscriptionActive}
                            hidden={!context?.isSubscriptionActive}
                            onClick={e => {
                                e.stopPropagation();
                                if (!cookie[`user_id_cookie`]) {
                                    if (typeof props.onCanHelp === `function`) {
                                        props.onCanHelp();
                                    }
                                    // setCanHelpRequest({
                                    //     ...props.request,                
                                    //     onSend: () => {  }
                                    // });
                                    // setCanHelpPopup(true);
                                } else {
                                    // context.setWishToHelpRequest1({...elem, user_id: context.profile?.id});
                                    // context.setWishToHelpPopup1(true);
                                    sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: props.request?.user,
                                        user: context.ownerProfile,
                                        is_new_requested: true, 
                                        is_can_help: true,
                                        refer_to_request: props.request,
                                    }));
                                    navigate(`/profile/chat?new_chat_offer=true`);
                                }
                            }} type={`button`} id={`wish-to-match`} >
                        <p><FormattedMessage id="i_can_help"/></p>
                    </button>

                    <button className={`block btn-circled-32 desktop row p-both`} data-color={'light-gray'}
                            onClick={e => {
                                e.stopPropagation();  
                                ClipBoard(`${window.location.origin}/share/${props.request?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                    globalContext.setClipboardOk(true);
                                })
                                // if (window.isSecureContext) {
                                //     navigator.clipboard.writeText(`${window.location.origin}/share/${elem?.id}`)
                                //     .then(() => {
                                //         context.setClipboardOk(true);
                                //     });
                                // }
                            }} type={'button'} id={`share-feed-btn`} disabled={false}>
                        <img src={ShareBlack} alt={``} className={`w-3`}></img>
                    </button>
                </div>
                <div></div>        
            </div>
        </div>

        {props.request?.user?.id === cookie[`user_id_cookie`] && deletePopup &&
        <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle={`Are your sure you want to delete your\u00A0request?`} 
                        show={deletePopup} setShow={setDeletePopup} _id={`yesno`}
                        color_2={`light-gray`} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} desktop={true}
                        color_1={`coral`} buttonTitle_1={<FormattedMessage id="delete"/>} onClick_1={() => {
                            CardDelete(props.request?.id, () => {
                                context.UpdateData();
                                setDeletePopup(false);
                            }, () => {
                                setDeletePopup(false);
                            });
                        }} close={'second'}/>}

        {props.request?.user?.id === cookie[`user_id_cookie`] && editPopup && 
        <AddRequestDesktop show={editPopup} setShow={setEditPopup} canClose={true}
                        onSubmit={() => {
                            context.UpdateData();
                        }} onDelete={() => {
                            context.UpdateData();
                        }} onClose={() => { 
                            setEditPopup(false);
                        }} canDelete={context.profile?.requests?.length > 1}
                        headerId={`add-request-popup-content`} request={props.request}/>}
    </>)
}

export const ProfileRequestCardMobile = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    const [ cookie ] = useCookies(["user_id_cookie"]);
    const navigate = useNavigate();

    const TextBlock = useRef<HTMLDivElement | null>(null);
    const [ clicked, setClicked ] = useState<boolean>(false);

    const [ isReadMore, setIsReadMore ] = useState<boolean>();
    
    const [ editPopup, setEditPopup ] = useState<boolean>(false);
    const [ deletePopup, setDeletePopup ] = useState<boolean>(false);

    useEffect(() => {
        setIsReadMore(undefined);
    }, [context.profile?.requests])

    useEffect(() => {
        if (TextBlock.current && isReadMore === undefined) {
            setIsReadMore(TextBlock.current?.offsetHeight < TextBlock.current?.scrollHeight)
        }
    }, [isReadMore])

    return (<>
        <div className={`block w-flex p-2 b-2 ${props.className}`} onClick={e => {
            if (context.profile?.id !== cookie[`user_id_cookie`]) {
                if (window.getSelection()?.type !== "Range") {
                    const newWindow: WindowProxy | null = window.open(`/request/${props.request?.id}`, '_blank');
                    if (newWindow) {
                        newWindow.focus();
                    }
                }
            } else {
                setClicked(val => !val)
            }
        }} data-border={`gray-1`} data-color={clicked ? `dark-white` : null} style={{position: `relative`}}>
            <div className={`row left gap-0`} style={{position: `absolute`, top: 8, right: 8}}
                 hidden={!clicked || props.request?.user?.id !== cookie[`user_id_cookie`]}>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setEditPopup(true);
                        }} type={`submit`} disabled={!context.isSubscriptionActive}>
                    <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                </button>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setDeletePopup(true);
                        }} type={`submit`} disabled={!context.isSubscriptionActive || (
                            context.profile?.requests?.length < 2 &&
                            !globalContext.community
                        )}>
                    <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                </button>
            </div>

            <div className={`w-flex row left gap-0`} hidden={!props.request?.tags?.length} style={{overflow: `hidden`, maxHeight: 32}}>
                {FirstSelected(props.request?.tags ?? [], props.selectedTags ?? []).map((elem: string, index: number) => {return (
                    <button className={`block btn-circled-24 mobile mb-2`} key={index} data-border={true}
                            onClick={e => {
                                e.stopPropagation();
                                if (typeof props.setFilterByIndustry === `function`) {
                                    props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                }
                            }} style={{border: `solid 1px ${CreateHSLColor(elem)}`}}>
                        <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                    </button>
                )})}
            </div>

            <div className={`neg-mx-1 px-1 w-flex-p-1`} data-lines={4} ref={TextBlock}
                 data-scroll={isReadMore === false}>
                {props.request?.answers.reduce((init: string, elem: string) => {
                    return `${init}${init && '\n'}${elem.trim()}`;
                }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                <p data-size={6} className={`article`} data-color={'black'} key={index}>
                    {elem}
                </p> : null)})}
            </div>

            {isReadMore &&
            <button className={`btn`} onClick={e => {
                        e.stopPropagation();
                        setIsReadMore(false);
                    }}>
                <p data-size={6} className={`semibold mt`} data-color={'black'}>
                    <FormattedMessage id="read_more"/>
                </p>
            </button>}

            <div className={`row mt-3 w-flex row`} hidden={props.request?.user?.id === cookie[`user_id_cookie`]}>                            
                <div className={`row left nowrap`} hidden={!cookie[`user_id_cookie`]}>
                    <button className={`block btn-circled-32 mobile row`} 
                            data-color={`black`}
                            disabled={context.ownerProfile?.is_requested.includes(props.request?.id) || !context.isSubscriptionActive}
                            onClick={e => {
                                e.stopPropagation();
                                if (!cookie[`user_id_cookie`]) {
                                    if (typeof props.onCanHelp === `function`) {
                                        props.onCanHelp();
                                    }
                                    // setCanHelpRequest({
                                    //     ...props.request,                
                                    //     onSend: () => {  }
                                    // });
                                    // setCanHelpPopup(true);
                                } else {
                                    // context.setWishToHelpRequest1({...elem, user_id: context.profile?.id});
                                    // context.setWishToHelpPopup1(true);
                                    sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: props.request?.user,
                                        user: context.ownerProfile,
                                        is_new_requested: true, 
                                        is_can_help: true,
                                        refer_to_request: props.request,
                                    }));
                                    navigate(`/profile/chat?new_chat_offer=true`);
                                }
                            }} type={`button`} id={`wish-to-match`} >
                        <p><FormattedMessage id="i_can_help"/></p>
                    </button>

                    <button className={`block btn-circled-32 mobile row p-both`} data-color={'light-gray'}
                            onClick={e => {
                                e.stopPropagation();  
                                ClipBoard(`${window.location.origin}/share/${props.request?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                    globalContext.setClipboardOk(true);
                                })
                                // if (window.isSecureContext) {
                                //     navigator.clipboard.writeText(`${window.location.origin}/share/${elem?.id}`)
                                //     .then(() => {
                                //         context.setClipboardOk(true);
                                //     });
                                // }
                            }} type={'button'} id={`share-feed-btn`} disabled={false}>
                        <img src={ShareBlack} alt={``} className={`w-3`}></img>
                    </button>
                </div>
                <div></div>        
            </div>
        </div>

        {props.request?.user?.id === cookie[`user_id_cookie`] && deletePopup &&
        <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle={`Are your sure you want to delete your\u00A0request?`} 
                        show={deletePopup} setShow={setDeletePopup} _id={`yesno`}
                        color_2={`light-gray`} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} mobile={true}
                        color_1={`coral`} buttonTitle_1={<FormattedMessage id="delete"/>} onClick_1={() => {
                            CardDelete(props.request?.id, () => {
                                context.UpdateData();
                                setDeletePopup(false);
                            }, () => {
                                setDeletePopup(false);
                            });
                        }} close={'second'}/>}

        {props.request?.user?.id === cookie[`user_id_cookie`] && editPopup && 
        <AddRequestPopup show={editPopup} setShow={setEditPopup} canClose={true}
                        onSubmit={() => {
                            context.UpdateData();
                        }} onDelete={() => {
                            context.UpdateData();
                        }} onClose={() => { 
                            setEditPopup(false);
                        }} canDelete={context.profile?.requests?.length > 1}
                        headerId={`add-request-popup-content`} request={props.request}/>}
    </>)
}
