import React, { useContext, useEffect, useState } from "react";
import { NewAvatar, AnimatedLogo } from "./elements/Elements.jsx";
import { useParams, useNavigate } from "react-router";
import RegistrationMobile from "./RegistrationMobile";
import RegistrationDesktop from "./RegistrationDesktop";
import { GlobalContext } from "./elements/Contexts";
import { CommunityLogo } from "./elements/Data";
import { FormattedMessage } from "react-intl";

const GenericNotFound = (props: any) => {
    const globalContext = useContext(GlobalContext);

    return (
        <div className={`block w-flex column ${props.isMobile ? 'pt-4' : 'h-screen'}`} data-color={`gray`} style={{overflow: 'hidden'}}>
            <div className={`row left nogap w-flex nowrap ${props.isMobile ? 'pl-3 neg-mb-5' : 'pl-4 h-7 neg-mb-7 min-w-9'}`}
                style={{opacity: globalContext.community === undefined ? 0 : 1}}>
                {!!globalContext.community ?
                <img src={CommunityLogo[globalContext.community]["black"]} alt=""
                    style={{height: 40}}></img> :
                <AnimatedLogo className={`neg-mr`}/>}
            </div>

            {props.isMobile ? <div className={`h-screen column w-flex`}>
                <div className={`w-flex h-9 neg-mb-9`}>
                    <div className={`w-flex row right pr-3 pt-4`}>
                        <NewAvatar type={['light-blue', 'coral']} name={`F V`} size={5}/></div>
                    <div className={`w-flex neg-ml-5`}>
                        <NewAvatar type={['pink', 'green']} name={`F G`} size={6} className={`neg-ml-3`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar type={['light-yellow', 'blue']} name={`I G`} size={6} className={`neg-mr-5`}/></div>
                </div>
                
                <div className={`w-flex px-3`} style={{zIndex: 1}}>
                    <h3 className={`center`}>{404}</h3>
                    <p data-size={4} className={`center mt-1 mb-3`}>
                        {`We can't find the page you're looking for. Let's find a better place for you to go`}
                    </p>
                    <a className={`block btn-circled-40 mobile row center w-flex mb-1`} data-color={`black`} type={`button`} href={`/profile`}>
                        <p><FormattedMessage id="my_requests"/></p>
                    </a>
                    <a className={`block btn-circled-40 mobile row center w-flex`} data-color={`black`} type={`button`} href={`/profile/feed`}>
                        <p><FormattedMessage id="feed"/></p>
                    </a>
                </div>

                <div className={`w-flex column bottom h-9 neg-mt-9`}>
                    <div className={`w-flex pl-6 pb-5`}>
                        <NewAvatar type={['white', 'blue']} name={`R K`} size={5} className={`mb-3`}/></div>
                    <div className={`w-flex row right pr-4`}>
                        <NewAvatar type={['light-pink', 'coral']} name={`W H`} size={5}/></div>
                    <div className={`w-flex neg-mb-5 pl-3`}>
                        <NewAvatar type={['white', 'pink']} name={`V N`} size={6}/></div>
                </div>
            </div> : <>
                <div className={`row h-10 neg-mb-10 w-flex nowrap nogap pl-6 pr-5 no-pointer`}>
                    <div className={`w-flex`}>
                        <NewAvatar name={`B D`} type={[`light-blue`, `coral`]} size={5} className={``}/></div>
                    <div className={`w-flex row right top pr-6 h-9`}>
                        <NewAvatar name={`F W`} type={[`dark-gray`, `blue`]} size={5} className={``}/></div>
                    <div className={`w-flex row right top h-10`}>
                        <NewAvatar name={`G M`} type={[`green`, `yellow`]} size={6} className={`neg-mr-7`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar name={`W H`} type={[`light-pink`, `coral`]} size={5} className={`mb-7`}/></div>
                </div>

                <div className={'column center h-flex'} style={{zIndex: 1}}>
                    <h2 className={`mt-3`}>{404}</h2>
                    <p data-size={3} className={`center mt-2`}>
                        {`We can't find the page you're looking for.`}<br/>
                        {`Let's find a better place for you to go`}
                    </p>
                    <div className={`row center mt-4`}>
                        <a className={`block btn-circled-40 desktop row center w-7`} data-color={`black`} type={`button`} href={`/profile`}>
                            <p><FormattedMessage id="my_requests"/></p>
                        </a>
                        <a className={`block btn-circled-40 desktop row center w-7`} data-color={`black`} type={`button`} href={`/profile/feed`}>
                            <p><FormattedMessage id="feed"/></p>
                        </a>
                    </div>
                </div>
                
                <div className={`row left h-10 neg-mt-10 w-flex nogap nowrap no-pointer`}>
                    <div className={`w-flex row h-9 bottom`}>
                        <NewAvatar name={`C T`} type={[`light-blue`, `blue`]} size={6} className={`neg-ml-3`}/></div>
                    <div className={`w-flex h-10 row bottom left`}>
                        <NewAvatar name={`V L`} type={[`white`, `pink`]} size={6} className={`neg-mb-5`}/></div>
                    <div className={`w-flex h-9 row bottom`}>
                        <NewAvatar name={`R K`} type={[`white`, `black`]} size={5} className={`ml-5`}/></div>
                    <div className={`w-flex h-9 row bottom left`}>
                        <NewAvatar name={`F G`} type={[`pink`, `green`]} size={6} className={`mb-2 ml-6`}/></div>
                </div>
            </>}
        </div>
    )
}

const InviteNotFound = (props: any) => {
    const globalContext = useContext(GlobalContext);

    return (
        <div className={`block w-flex column ${props.isMobile ? 'pt-4' : 'h-screen'}`} data-color={`blue`} style={{overflow: 'hidden'}}>
            <div className={`row left nogap w-flex nowrap ${props.isMobile ? 'pl-3 neg-mb-5' : 'pl-4 h-7 neg-mb-7 min-w-9'}`}
                style={{opacity: globalContext.community === undefined ? 0 : 1}}>
                {!!globalContext.community ?
                <img src={CommunityLogo[globalContext.community]["black"]} alt=""
                    style={{height: 40}}></img> :
                <AnimatedLogo color={`white`} className={`neg-mr`}/>}
            </div>

            {props.isMobile ? <div className={`w-flex h-screen column`}>
                <div className={`w-flex row right neg-mb-4 pt-5`}>
                    <NewAvatar type={['light-blue', 'yellow']} name={`I G`} size={6} className={`neg-mr-5`}/></div>
                <div className={`w-flex row right pr-7 pb-3 neg-mt-5`}>
                    <NewAvatar type={['light-yellow', 'coral']} name={`F V`} size={5}/></div>
                <div className={`w-flex neg-mb-6 neg-mt-7 neg-ml-7`}>
                    <NewAvatar type={['pink', 'green']} name={`F G`} size={6}/></div>
                
                <div className={`w-flex`} style={{zIndex: 1}}>
                    <h4 className={`mt-3 center`}>{`We couldn't find your email`}</h4>
                    <p data-size={6} className={`center mt px-3`}>
                        {`Osmos is only available by invite.`}<br/>
                        {`Get an invite from a friend or join the waitlist.`}
                    </p>
                    <div className={`row center mt-3 px-4`}>
                        <a className={`block btn-flex mobile row center w-flex`} type={`button`} href={`https://osmos.social?nologin=true?nologin=true`}>
                            <p data-color={`black`}><FormattedMessage id="waitlist_join"/></p>
                        </a>
                    </div>
                </div>

                <div className={`w-flex pl-6 neg-mb-5 neg-mt-5`}>
                    <NewAvatar type={['light-pink', 'coral']} name={`W H`} size={5}/></div>
                <div className={`w-flex row right pr-4 neg-mb-5`}>
                    <NewAvatar type={['white', 'blue']} name={`R K`} size={5}/></div>
                <div className={`w-flex neg-mb-5 pl-3`}>
                    <NewAvatar type={['white', 'pink']} name={`V N`} size={6}/></div>
            </div> : <>
                <div className={`row h-10 neg-mb-10 w-flex nowrap nogap`}>
                    <div className={`w-flex`}>
                        <NewAvatar name={`B N`} type={[`white`, `pink`]} size={6} className={`neg-ml-4 mb-8`}/></div>
                    <div className={`w-flex row right top h-9`}>
                        <NewAvatar name={`F W`} type={[`light-yellow`, `coral`]} size={5} className={``}/></div>
                    <div className={`w-flex row right top h-10`}>
                        <NewAvatar name={`M G`} type={[`pink`, `coral`]} size={6} className={`ml-8 neg-mr-7 neg-mt-3`}/></div>
                    <div className={`w-flex row top h-10 right`}>
                        <NewAvatar name={`R K`} type={[`white`, `blue`]} size={5} className={`mt-6`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar name={`T H`} type={[`white`, `black`]} size={5} className={`neg-mb-7 mr-4`}/></div>
                </div>

                <div className={'column center h-flex'} style={{zIndex: 1}}>
                    <h2 className={`mt-3`}>{`We couldn't find your email`}</h2>
                    <p data-size={3} className={`center mt-2`}>
                        {`Osmos is only available by invite.`}<br/>
                        {`Get an invite from a friend or join the waitlist.`}
                    </p>
                    <div className={`row center mt-4`}>
                        <a className={`block btn-flex desktop row center w-8`} type={`button`} href={`https://osmos.social?nologin=true`}>
                            <p data-color={`black`}><FormattedMessage id="waitlist_join"/></p>
                        </a>
                    </div>
                </div>
                
                <div className={`row left h-10 neg-mt-10 w-flex nogap nowrap`}>
                    <div className={`w-flex row h-8 bottom`}>
                        <NewAvatar name={`W H`} type={[`light-pink`, `coral`]} size={5} className={`ml-6`}/></div>
                    <div className={`w-flex h-10 row bottom left`}>
                        <NewAvatar name={`S L`} type={[`coral`, `blue`]} size={6} className={`neg-mb-4`}/></div>
                    <div className={`w-flex h-9 row bottom`}>
                        <NewAvatar name={`B D`} type={[`light-blue`, `coral`]} size={5} className={`ml-5`}/></div>
                    <div className={`w-flex h-10 row bottom left`}>
                        <NewAvatar name={`C M`} type={[`yellow`, `light-blue`]} size={6} className={`mb-4 ml-6`}/></div>
                </div>
            </>}
        </div>
    )
}

const WaitPage = (props: any) => {
    const navigate = useNavigate();

    const { path } = useParams();

    const [ onLoaded, setOnLoaded ] = useState<boolean>(false);

    const [ availablePaths, setAvailablePaths ] = useState<string[]>([  
        'osmos',
        'starta',
        'prinston',
        'scoalition',
        'ff',
        'yes',
        'bea',
        'babc',
        'harvardgsasbc',
        'harvardeta',
        'hvcpe',
        'core',
        'cwbs',
        'cgbc',
        'mitsloanb',
        'akpsidbs',
        'rss',
        'startupcoalition',
        'foundersforum',
        'trampery',
        'bayesmarketing',
        'silicondrinkaboutsevilla',
        'princetonEA',
        'fn',
        'runway',
    ])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}communities`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setAvailablePaths(val => val.concat(data));
                    if (availablePaths.concat(data).includes(path ?? '')) {
                        setOnLoaded(true);
                    } else {
                        navigate('/404');
                    }
                })
                .catch(() => {
                    if (availablePaths.includes(path ?? '')) {
                        setOnLoaded(true);
                    } else {
                        navigate('/404');
                    }
                })
            } else {
                if (availablePaths.includes(path ?? '')) {
                    setOnLoaded(true);
                } else {
                    navigate('/404');
                }
            }
        })
        .catch(() => {
            if (availablePaths.includes(path ?? '')) {
                setOnLoaded(true);
            } else {
                window.location.assign(`https://osmos.social?nologin=true`);
            }
        })
    }, [path])

    return (
    onLoaded ? (
        props.isMobile ? 
        <RegistrationMobile community={path}/> :
        <RegistrationDesktop community={path}/>
    ) : <></>)
}

const GenericOnlyDesktop = (props: any) => {
    return (
        <div className={`block w-flex column pt-4`} data-color={`gray`} style={{overflow: 'hidden'}}>
            <div className={`row left nogap w-flex nowrap pl-3 neg-mb-5`}>
                <AnimatedLogo className={`neg-mr`}/>
            </div>

            <div className={`h-screen column w-flex`}>
                <div className={`w-flex h-9 neg-mb-9`}>
                    <div className={`w-flex row right pr-3 pt-4`}>
                        <NewAvatar type={['light-blue', 'coral']} name={`F V`} size={5}/></div>
                    <div className={`w-flex neg-ml-5`}>
                        <NewAvatar type={['pink', 'green']} name={`F G`} size={6} className={`neg-ml-3`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar type={['light-yellow', 'blue']} name={`I G`} size={6} className={`neg-mr-5`}/></div>
                </div>
                
                <div className={`w-flex px-3 column`} style={{zIndex: 1}}>
                    <p data-size={4} className={`center mt-1 mb-3`}>
                        {`Please, use desktop version`}
                    </p>
                    <a className={`block btn-circled-40 mobile row center w-7`} data-color={`black`} type={`button`} href={`/admin`}>
                        <p><FormattedMessage id="admin_page"/></p>
                    </a>
                </div>

                <div className={`w-flex column bottom h-9 neg-mt-9`}>
                    <div className={`w-flex pl-6 pb-5`}>
                        <NewAvatar type={['white', 'blue']} name={`R K`} size={5} className={`mb-3`}/></div>
                    <div className={`w-flex row right pr-4`}>
                        <NewAvatar type={['light-pink', 'coral']} name={`W H`} size={5}/></div>
                    <div className={`w-flex neg-mb-5 pl-3`}>
                        <NewAvatar type={['white', 'pink']} name={`V N`} size={6}/></div>
                </div>
            </div>
        </div>
    )
}

export {
     GenericNotFound,
     InviteNotFound,
     WaitPage,
     GenericOnlyDesktop,
};