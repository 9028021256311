import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { 
    CardRecommendationRequests,
    CardRequest, 
    EmailAuth, 
    EmailAuthConfirm, 
    GoogleAuth, 
    LinkedInAuth,
    MinProfileRequest, 
} from "../Requests";
import { 
    FooterDesktop,  
    Loading, 
    NewAvatar, 
    TopBar, 
    AnimatedLogo,
} from "./Elements.jsx";
import {
    PopUp, 
    InfoErrorPopup, 
    InfoPopup,
    PopupCenterSignIn,
} from "./Popups";
import { ScreenColors, IsRightScroll, IsLeftScroll } from "./Data";
import { 
    AngleRightBlack,  
    GoogleLogo, 
    LinkedInLogo, 
    PageListBlack, 
    PlusWhite 
} from "./Imgs";
import { FeedRequestCardDesktop, FeedRequestCardMobile } from "../Profile/RequestCards";
import { SiteCard } from "./Elems";
import { FormattedMessage } from "react-intl";

const InvitePageDesktop = (props: any) => { 
    const navigate = useNavigate();
    // const [ cookie ] = useCookies([`user_id_cookie`, `no_sign_in_cookie`]) 
    const { code } = useParams();  
    const [ searchParams ] = useSearchParams();

    const [ loaded, setLoaded ] = useState<boolean>(false);

    const [ email, setEmail ] = useState<string>("");    
    const [ profile, setProfile ] = useState<any>();  
    const [ fromProfile, setFromProfile ] = useState<any>();
    const [ card, setCard ] = useState<any>();
    const [ similarRequests, setSimilarRequests ] = useState<any[]>();

    const [ popUp, setPopUp ] = useState<boolean>(false);
    const [ closePopUp, setClosePopup ] = useState<boolean>(false);
    const [ emailExist, setEmailExist ] = useState<boolean>(false);
    const [ mailbox, setMailbox ] = useState();
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false); 
    // const [ signInPopup, setSignInPopup ] = useState<boolean>(props?.share && !cookie[`user_id_cookie`] && !cookie[`no_sign_in_cookie`]);
    const [ signInPopup, setSignInPopup ] = useState<boolean>(false);
    const [ rndColor, setRndColor ] = useState<string[]>([]);   

    const projectScrollBlock = useRef<HTMLDivElement | null>(null);
    const projectScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftProjectBtn, setShowLeftProjectBtn ] = useState<boolean>(false);
    const [ showRightProjectBtn, setShowRightProjectBtn ] = useState<boolean>(false);

    useEffect(() => {
        if (profile) {
            setCard(profile?.requests.find(
                (val: any) => (val.id === code || val.id === profile?.requests?.invite_card)) ?? profile?.requests[0]
            );
            setLoaded(true);
        }
    }, [profile])

    useEffect(() => {
        setSimilarRequests([]);
        if (card) {
            CardRecommendationRequests(
                profile?.id,
                card.answers[0],
                setSimilarRequests,
                () => {}, () => {}, 3, false,
            )
        }
    }, [card])

    useEffect(() => {
        CardRequest(code, setProfile, () => {}, () => {});
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);

        const fromUserId = searchParams.get(`from_user`);

        if (fromUserId) {
            MinProfileRequest(fromUserId, setFromProfile);
        }
    }, []) 

    useEffect(() => {
        if (fromProfile?.avatar) {
            setPopUp(true);
        }
    }, [fromProfile])

    useEffect(() => {
        if (rndColor) {
            const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
            // console.warn(`feed changed`, rndColor[0])
            if (btn) {
                btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
                btn.click();
            }
        }
    }, [rndColor])

    return (<div className={`body top desktop`}>
        <div className={`row w-flex left top nogap nowrap pt-4`}>
            <div className={`row left top nogap w-flex pr-5 md-pr-3 pb-6`}>
                <div style={{height: 40}} className={`w-flex mb-3 row nowrap pl-5 md-pl-3`} hidden={props.isNarrow}>  
                    <AnimatedLogo/>

                    <div className={`row nowrap left`}>
                        <a className={`block btn-circled-40 desktop p-left p-right row`}
                        href={`/blog`}>
                            <img src={PageListBlack} alt={``} className={`w-3`}></img>
                        </a> 
                        <button className={`block btn-circled-40 desktop`} data-color={`black`} 
                                onClick={() => { setSignInPopup(true) }}>
                            <p><FormattedMessage id="sign_in"/></p>
                        </button>
                    </div>
                </div>

                <div className={`pl-5 md-pl-3 md-col-12 col-4`} style={{top: 38}} data-md-sticky={false}>
                {loaded ? <>
                    <div className={`block p-3 b-3 w-flex mb-3`} data-md-hidden={true} 
                        onClick={() => {}}>
                        <NewAvatar size={0} name={profile?.name} avatar={profile?.avatar?.link}
                                    type={profile?.avatar?.style} className={`circled mb-2`}/>
                        <p data-size={4} className={`semibold`}>{profile?.name}</p>
                        <p data-size={5} data-color={`black-40-opacity`}>
                            {profile?.occupation}
                        </p>
                    </div>
                    <div className={`block w-flex p-3 b-3 mb-3`} data-md-hidden={true}>
                        <p data-size={4} className={`semibold mb-3`}>
                            <FormattedMessage id="sign_in"/>
                        </p>
                        {[
                            {title: `Google`, logo: GoogleLogo, onClick: () => {
                                GoogleAuth(
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: false},
                            {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                LinkedInAuth(
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: true},
                        ].map((elem, index) => {return (
                            <button className={`btn-google desktop row nowrap center mb-2 w-flex`} key={index}
                                    onClick={() => {
                                        elem.onClick();
                                    }} hidden={elem.hidden}>
                                <img className={`h-3`} src={elem.logo} alt={`li`}></img>
                                <p>{`Continue with ${elem.title}`}</p>
                            </button>
                        )})}
                        <div className={`w-flex row pb-2 nowrap`}>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                            <p data-size={5} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                                <FormattedMessage id="or"/>
                            </p>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                        </div>
                        <form className={`w-flex`} onSubmit={e => {
                            e.preventDefault();
                            if (email) {
                                setWaitEmail(true); 
                                setTimeout(() => { setWaitEmail(false) }, 5000);
                                EmailAuth(
                                    email, setEmailExist, setMailbox, 
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        200: () => {                                     
                                            setWaitEmail(false);
                                            setMailOkPopup(true);
                                        },
                                        0: () => {
                                            setMailErrorPopup(true);
                                        },
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            } 
                        }}>
                            <div className={`row desktop input-block mb-2`}>
                                <input className={`desktop`} placeholder={`Email address`} type={`email`}
                                        value={email} onChange={e => {
                                            setEmail(e.target.value);
                                        }} required={true}></input> 
                                <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                            </div>
                                
                            <button className={`block btn-flex desktop w-flex row center`}
                                    data-color={`black`} type={`submit`}>
                                {waitEmail ? 
                                <Loading color={`white`} width={24} height={24}/> : 
                                <p><FormattedMessage id="continue"/></p>}
                            </button>
                        </form>
                        <div className={`mt-3 row left nogap nowrap`}>
                            <p data-size={5} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                                <FormattedMessage id="reg_new_in"/>&nbsp;	
                                <a href={`/`}>
                                    <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                                </a>
                            </p>
                        </div>
                    </div>
                </> : <>
                    <div className={`skeleton b-3 w-flex h-9 mb-3`}></div>
                    <div className={`skeleton b-3 w-flex h-9 mb-3`}></div>
                </>}
                </div>

                <div className={`pl-4 md-pl-3 md-col-12 col-8`}>
                {loaded ? <>
                    <div className={`block b-3 p-3 column left top w-flex mb-3`} data-color={rndColor[0]}>
                        <div className={`w-flex row nowrap mb-3`}>
                            <a href={`/profile/${profile?.unique_id ?? profile?.id}`} className={`row top left nowrap`}
                                onClick={e => { e.stopPropagation() }}>
                                <NewAvatar name={profile?.name} type={profile?.avatar?.style ?? 0} size={3}
                                        avatar={profile?.avatar?.link}/>

                                <div>
                                    <p data-size={6} className={`semibold seminarrow`} data-color={'black'}>
                                        {profile?.name ?? ``}
                                    </p>
                                    <p data-size={7} className={`seminarrow`} data-color={`black-40-opacity`} data-lines={1}>
                                        {profile?.occupation ?? ``}
                                    </p>
                                </div>
                            </a>

                            {/* {!!OnlyValid(profile?.requests ?? [])?.length &&
                            <a className={`block h-3 min-w-3 centered b-2`} data-color={ScreenColors[
                                    [8, 3, 0, 5, 1, 4, 2][Math.min(6, OnlyValid(profile?.requests)?.length)]
                                ][0]} 
                                href={`/profile/${profile?.unique_id ?? profile?.id}?from=feed&target=requests`}
                            onClick={e => { e.stopPropagation() }}>
                                <p className={`mx-1 narrow`}>{OnlyValid(profile?.requests ?? [])?.length}</p>
                            </a>} */}
                        </div>
                        <div id={`request-text-field`} style={{wordBreak: `break-word`}}>
                            {(card?.answers ?? []).filter((val: string) => !!val).reduce((init: string, elem: string) => {
                                return `${init}${init && '\n'}${elem}`;
                            }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem ?
                                <p data-size={6} data-color={'black'} className={`article`} key={index}>{elem.trim()}</p>
                            : null)})}
                        </div>
                    </div>

                    {!!profile?.projects?.length &&
                    <div className={`block b-3 p-3 w-flex mb-3`} data-color={`gray`} ref={projectScrollBlock}
                            onMouseEnter={() => {
                            if (projectScrollElem.current && 
                                projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                if (IsLeftScroll(projectScrollElem.current)) {
                                    setShowLeftProjectBtn(true);
                                }
                                if (IsRightScroll(projectScrollElem.current)) {
                                    setShowRightProjectBtn(true);
                                }
                            }
                            }} onMouseLeave={() => { 
                            setShowLeftProjectBtn(false); 
                            setShowRightProjectBtn(false);
                        }}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                <p data-size={6} className={`px-1`}>{profile?.projects?.length}</p>
                            </div>
                            <p data-size={5} className={`semibold seminarrow`} data-color={rndColor[2]}>{`${profile?.fname}'s projects`}</p>
                        </div>
                        <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                            <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftProjectBtn} style={{zIndex: 1}}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(-29px, 0) rotate(180deg)'
                                }} data-color={'white'} onClick={() => {
                                    if (projectScrollElem.current) {
                                        projectScrollElem.current.scrollTo({
                                            left: projectScrollElem.current.scrollLeft - 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>

                            <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`} ref={projectScrollElem}
                                onScroll={() => {
                                    if (projectScrollElem.current && 
                                        projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                        if (IsLeftScroll(projectScrollElem.current)) {
                                            setShowLeftProjectBtn(true);
                                        } else {
                                            setShowLeftProjectBtn(false);
                                        }
                                        if (IsRightScroll(projectScrollElem.current)) {
                                            setShowRightProjectBtn(true);
                                        } else {
                                            setShowRightProjectBtn(false);
                                        }
                                    }
                                }} >
                                {profile?.projects.map((elem: any, index: number) => {return (
                                    <SiteCard project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                                desktop={true} orderNumber={index}/>
                                )})}
                            </div> 

                            <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightProjectBtn}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(29px, 0)'
                                }} data-color={'white'} onClick={() => {
                                    if (projectScrollElem.current) {
                                        projectScrollElem.current.scrollTo({
                                            left: projectScrollElem.current.scrollLeft + 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>
                        </div> 
                    </div>}
                    
                    {!!similarRequests?.length && <>
                    <div className={`row left nowrap px-1 mb-2`}>
                        <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                            <p data-size={6} className={`px-1`}>{similarRequests?.length}</p>
                        </div>
                        <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}><FormattedMessage id="requests_similar"/></p>
                    </div>
                    {similarRequests.map((elem: any, index: number) => {return (<Fragment key={index}>
                    <FeedRequestCardDesktop className={`w-flex mb-2`} profile={elem.user} request={elem.card}
                                            desktop={true} onlyValid={true} index={index} onAvatarClick={() => {
                                                if (window.getSelection()?.type !== "Range") {
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }
                                            }} onRequestClick={() => {
                                                navigate(`/request/${elem.card?.id}`);
                                            }}/>
                    </Fragment>)})}
                    </>}
                    
                    <div className={`block w-flex p-3 b-3 mb-3`} data-hidden={true} data-md-shown={true}>
                        <p data-size={4} className={`semibold mb-3`}>
                            <FormattedMessage id="sign_in"/>
                        </p>
                        {[
                            {title: `Google`, logo: GoogleLogo, onClick: () => {
                                GoogleAuth(
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: false},
                            {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                LinkedInAuth(
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            }, hidden: true},
                        ].map((elem, index) => {return (
                            <button className={`btn-google desktop row nowrap center mb-2 w-flex`} key={index}
                                    onClick={() => {
                                        elem.onClick();
                                    }} hidden={elem.hidden}>
                                <img className={`h-3`} src={elem.logo} alt={`li`}></img>
                                <p>{`Continue with ${elem.title}`}</p>
                            </button>
                        )})}
                        <div className={`w-flex row pb-2 nowrap`}>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                            <p data-size={5} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                                <FormattedMessage id="or"/>
                            </p>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                        </div>
                        <form className={`w-flex`} onSubmit={e => {
                            e.preventDefault();
                            if (email) {
                                setWaitEmail(true); 
                                setTimeout(() => { setWaitEmail(false) }, 5000);
                                EmailAuth(
                                    email, setEmailExist, setMailbox, 
                                    props?.share ? null : code, 
                                    null, 
                                    encodeURIComponent(`/request/${card.id}`), 
                                    null,
                                    {
                                        200: () => {                                     
                                            setWaitEmail(false);
                                            setMailOkPopup(true);
                                        },
                                        0: () => {
                                            setMailErrorPopup(true);
                                        },
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            } 
                        }}>
                            <div className={`row desktop input-block mb-2`}>
                                <input className={`desktop`} placeholder={`Email address`} type={`email`}
                                        value={email} onChange={e => {
                                            setEmail(e.target.value);
                                        }} required={true}></input> 
                                <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                            </div>
                                
                            <button className={`block btn-flex desktop w-flex row center`}
                                    data-color={`black`} type={`submit`}>
                                {waitEmail ? 
                                <Loading color={`white`} width={24} height={24}/> : 
                                <p><FormattedMessage id="continue"/></p>}
                            </button>
                        </form>
                        <div className={`mt-3 row left nogap nowrap`}>
                            <p data-size={5} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                                <FormattedMessage id="reg_new_in"/>&nbsp;	
                                <a href={`/`}>
                                    <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                                </a>
                            </p>
                        </div>
                    </div>
                </> : <>
                    <div className={`skeleton b-3 w-flex h-10 mb-5`}></div>
                    <div className={`skeleton b-3 w-flex h-10 mb-5`}></div>
                </>}                    
                </div>
            </div>                
        </div>

        <FooterDesktop links={true}/>
        
        {signInPopup &&
        <PopupCenterSignIn show={signInPopup} setShow={setSignInPopup}/>}

        {mailOkPopup &&
        <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                   title={<FormattedMessage id="reg_check_email"/>}
                   buttonTitle={mailbox ? <FormattedMessage id="reg_open_mailbox"/> : <FormattedMessage id="reg_ok_got_it"/>} onOk={() => {
                        if (mailbox) {
                            window.location.href = mailbox;
                        } else {
                            EmailAuthConfirm(
                                email, () => {}, 0, 
                                () => {
                                    setMailOkPopup(false);
                                    if (emailExist) {
                                        navigate('/profile');
                                    } else {
                                        navigate(`/?new_user=true&email=${email}`)
                                    }
                                },
                                () => {                                            
                                    setMailOkPopup(false);
                                }
                            )
                        }
                   }} desktop={true}/>}

        {mailErrorPopup &&
        <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                        title={<FormattedMessage id="reg_error"/>}
                        buttonTitle={<FormattedMessage id="reg_reload_page"/>} onOk={() => {
                                setMailErrorPopup(false);
                                window.location.reload();
                        }} desktop={true}/>}

        {popUp &&
        <PopUp onlyBg={true} content={<div className={`block column w-10 b-3 center-popup`} data-color={`blue`}> 
                <div style={{zIndex: 1}}>
                    <div className={`row top nowrap w-flex h-9 neg-mb-9 bt-3`} style={{overflow: `hidden`}}>
                        <div></div>
                        <NewAvatar size={6} type={['green', 'yellow']} name={`O T`} className={`circled neg-mt-5`}/>
                        <div className={`mt-7`}>
                        <NewAvatar size={5} type={['yellow', 'bright-blue']} name={`O V`} className={`neg-mt-4 neg-mr-3`}/></div>
                    </div> 

                    <div className={`p-4`}>  
                        <div className={`row top w-flex mb-3 w-flex`}>
                            <NewAvatar size={7} name={fromProfile?.name} type={fromProfile?.avatar?.style ?? 0} 
                                       avatar={fromProfile?.avatar?.link}/>
                            <button className={`btn desktop centered`} 
                                    onClick={() => { setClosePopup(true) }} id={`close-popup-btn`}
                                    data-color={`white-10-opacity`}>
                                <div className={`btn-symbol close filled centered w-3 h-3`}>
                                    <img src={PlusWhite} alt={`+`} className={`hover`}></img>
                                </div>
                            </button>
                        </div>
                        <p data-size={4} className={`mb-2 w-flex`} data-color={`white`}>
                            {`Hi there! I'm using Osmos to connect with people aligned with my business goals.`}
                        </p>
                        <p data-size={4} className={`mb-3 w-flex`} data-color={`white`}>
                            {`I've noticed a demand for professionals like you within my network. Please take a moment to explore the request, as it could offer fresh opportunities and perspectives.`}
                        </p>
                        <button className={`block btn-circled-40 desktop`}
                                data-color={`black`} type={`button`} onClick={() => {
                                    setClosePopup(true);
                                }}>
                            <p>{`See who's interested in me`}</p>
                        </button>
                    </div>
                </div>
                 
                <div className={`row bottom center w-flex h-9 neg-mt-9 bb-3`} style={{overflow: 'hidden'}}>
                    <NewAvatar size={5} type={['bright-pink', 'blue']} name={`L Q`} className={`mr-8 neg-mb-4`}/>
                    <NewAvatar size={6} type={['light-pink', 'coral']} name={`P D`} className={`circled neg-mb-4`}/>
                </div>
            </div>} show={popUp} setShow={setPopUp} close={closePopUp}/>}
    </div>
    )
}

const InvitePageMobile = (props: any) => {
    const navigate = useNavigate();
    const { code } = useParams();
    const [ searchParams ] = useSearchParams();

    const [ loaded, setLoaded ] = useState<boolean>(false); 
    const [ popUp, setPopUp ] = useState<boolean>(false);
    const [ closePopUp, setClosePopup ] = useState<boolean>(false);
    const [ emailExist, setEmailExist ] = useState<boolean>(false);
    const [ mailbox, setMailbox ] = useState<any>();
    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false);
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    
    const [ fullText, setFullText ] = useState<boolean>(false);
    const [ rndColor, setRndColor ] = useState<string[]>([]); 

    const [ email, setEmail ] = useState<string>("");   
    const [ profile, setProfile ] = useState<any>();
    const [ fromProfile, setFromProfile ] = useState<any>();
    const [ card, setCard ] = useState<any>();
    const [ similarRequests, setSimilarRequests ] = useState<any[]>();

    useEffect(() => {
        if (profile) {
            setCard(profile?.requests.find(
                (val: any) => (val.id === code || val.id === profile?.requests?.invite_card)) ?? profile?.requests[0]
            );
            setLoaded(true);
        }
    }, [profile])

    useEffect(() => {
        setSimilarRequests([]);
        if (card) {
            CardRecommendationRequests(
                profile?.id,
                card.answers[0],
                setSimilarRequests,
                () => {}, () => {}, 3, false
            )
        }
    }, [card])

    useEffect(() => {
        if (document.querySelector(`#request-text-field`)) {
            setFullText(
                (document.querySelector(`#request-text-field`) as HTMLDivElement).offsetHeight >= (document.querySelector(`#request-text-field`) as HTMLDivElement).scrollHeight
            );
        }
    }, [card])

    useEffect(() => {
        CardRequest(code, setProfile, () => {}, () => {});
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);

        const fromUserId = searchParams.get(`from_user`);

        if (fromUserId) {
            MinProfileRequest(fromUserId, setFromProfile);
        }
    }, [])

    useEffect(() => {
        if (fromProfile?.avatar) {
            setPopUp(true);
        }
    }, [fromProfile])

    return (<div className={`body top`}>
        <TopBar notification={3} className={`mb-3 w-flex`} share={props.share}
                func={() => {
                    navigate('/profile');
                }} toTop={false} profile={profile} setProfile={setProfile} color={`light-gray`}/>

        <div className={`w-flex px-2`}>
        {loaded ? <>
            <div className={`block b-3 p-3 column left top w-flex mb-3`} data-color={rndColor[0]}>
                <div className={`w-flex row nowrap mb-3`}>
                    <a href={`/profile/${profile?.unique_id ?? profile?.id}`} className={`row top left nowrap`}
                       onClick={e => { e.stopPropagation() }}>
                        <NewAvatar name={profile?.name} type={profile?.avatar?.style ?? 0} size={3}
                                avatar={profile?.avatar?.link}/>

                        <div>
                            <p data-size={6} className={`semibold seminarrow`} data-color={'black'}>
                                {profile?.name ?? ``}
                            </p>
                            <p data-size={7} className={`seminarrow`} data-color={`black`} data-lines={1}>
                                {profile?.occupation ?? ``}
                            </p>
                        </div>
                    </a>

                    {/* {!!OnlyValid(profile?.requests ?? [])?.length &&
                    <a className={`block h-3 min-w-3 centered b-2`} data-color={ScreenColors[
                            [8, 3, 0, 5, 1, 4, 2][Math.min(6, OnlyValid(profile?.requests)?.length)]
                        ][0]} 
                       href={`/profile/${profile?.unique_id ?? profile?.id}?from=feed&target=requests`}
                    onClick={e => { e.stopPropagation() }}>
                        <p className={`mx-1 narrow`}>{OnlyValid(profile?.requests ?? [])?.length}</p>
                    </a>} */}
                </div>

                <div data-lines={7} data-scroll={fullText} id={`request-text-field`} style={{wordBreak: `break-word`}}>
                    {(card?.answers ?? []).filter((val: string) => !!val).reduce((init: string, elem: string) => {
                        return `${init}${init && '\n'}${elem}`;
                    }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem ?
                        <p data-size={6} data-color={'black'} className={`article`} key={index}>{elem.trim()}</p>
                    : null)})}
                </div>
                {!fullText &&
                <button className={`btn mobile mt`} onClick={() => { setFullText(true) }}>
                    <p data-size={6} className={`semibold`} data-color={`black`}><FormattedMessage id="read_more"/></p>
                </button>}
            </div>

            {!!similarRequests?.length && <>
                <div className={`row left nowrap px-1 mt-3 mb-2`}>
                    <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                        <p data-size={6} className={`px-1`}>{similarRequests?.length}</p>
                    </div>
                    <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}><FormattedMessage id="requests_similar"/></p>
                </div>
                {similarRequests.map((elem, index) => { return (
                <Fragment key={index}>
                    <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem.user} request={elem.card}
                                            mobile={true} onlyValid={true} index={index} onAvatarClick={() => {
                                                if (window.getSelection()?.type !== "Range") {
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }
                                            }} onRequestClick={() => {
                                                navigate(`/request/${elem?.card?.id}`)
                                            }}/>
                </Fragment>)})}
            </>}

            {!!profile?.projects?.length && <>
            <div className={`block p-3 b-3 w-flex mb-3`} data-color={`gray`} id={`profile-projects-block`}>
                <div className={`row left nowrap`}>
                    <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                        <p data-size={6} className={`px-1`}>{profile?.projects?.length}</p>
                    </div>
                    <p data-size={5} className={`semibold seminarrow`} data-color={rndColor[2]}>{`${profile?.fname}'s projects`}</p>
                </div>
                <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                    <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`}>
                        {profile?.projects.map((elem: any, index: number) => {return (
                            <SiteCard project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                      mobile={true} orderNumber={index}/>
                        )})}
                    </div> 
                </div> 
            </div>

            <div className={`block w-flex p-3 b-3 mb-3`}>
                <p data-size={4} className={`center semibold seminarrow mb-3`}>
                    <FormattedMessage id="sign_in"/>
                </p>
                {
                    [
                        {title: `Google`, logo: GoogleLogo, onClick: () => {
                            GoogleAuth(
                                props?.share ? null : code, 
                                null, 
                                encodeURIComponent(`/request/${card.id}`), 
                                null,
                                {
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                },
                            )
                        }, hidden: false},
                        {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                            LinkedInAuth(
                                props?.share ? null : code, 
                                null, 
                                encodeURIComponent(`/request/${card.id}`), 
                                null,
                                {
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                },
                            )
                        }, hidden: true},
                    ].map((elem, index) => {return (
                        <button className={`btn-google mobile w-flex row center mb-1`}
                                data-color={`black`} onClick={() => {
                                    elem.onClick();
                                }} key={index} hidden={elem.hidden}>
                            <img src={elem.logo} alt={`li`}></img>
                            <p>{`Continue with ${elem.title}`}</p>
                        </button>
                    )})
                }                
                <div className={`w-flex row my-2 nowrap`}>
                    <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                    <p data-size={8} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                        <FormattedMessage id="or"/>
                    </p>
                    <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                </div>
                <form className={`w-flex`} onSubmit={e => {
                    e.preventDefault();
                    if (email) {
                        setWaitEmail(true); 
                        setTimeout(() => { setWaitEmail(false) }, 5000);
                        EmailAuth(
                            email, setEmailExist, setMailbox, 
                            props?.share ? null : code, 
                            null, 
                            encodeURIComponent(`/request/${card.id}`), 
                            null,
                            {
                                200: () => {                                     
                                    setWaitEmail(false);
                                    setMailOkPopup(true);
                                },
                                0: () => {
                                    setMailErrorPopup(true);
                                },
                                203: () => {
                                    navigate('/notfound');
                                }
                            },
                        )
                    } 
                }}>
                    <div className={`row mobile input-block mb-1`}>
                        <input className={`mobile`} placeholder={`Email address`} type={`email`}
                                value={email} onChange={e => {
                                    setEmail(e.target.value);
                                }} required={true} data-size={6}></input> 
                            <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                    </div>
                        
                    <button className={`block btn-flex mobile w-flex row center`}
                            data-color={`black`} type={`submit`}>
                        {waitEmail ? 
                        <Loading color={`white`} width={24} height={24}/> : 
                        <p><FormattedMessage id="continue"/></p>}
                    </button>
                </form>
                <div className={`mt-3 row center nogap nowrap`}>
                    <p data-size={8} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                        <FormattedMessage id="reg_new_in"/>&nbsp;	
                        <a href={`/`}>
                            <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                        </a>
                    </p>
                </div>
            </div>
            </>}
        </> : <>
            <div className={`skeleton b-3 w-flex h-9 mb-2`}></div>
            <div className={`skeleton b-3 w-flex h-8 mb-2`}></div>
            <div className={`skeleton b-3 w-flex h-9 mb-2`}></div>
        </>}
        </div>

        {mailOkPopup &&
        <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                   title={<FormattedMessage id="reg_check_email"/>}
                   buttonTitle={mailbox ? <FormattedMessage id="reg_open_mailbox"/> : <FormattedMessage id="reg_ok_got_it"/>} onOk={() => {
                        if (mailbox) {
                            window.location.href = mailbox;
                        } else {
                            EmailAuthConfirm(
                                email, () => {}, 0, 
                                () => {
                                    setMailOkPopup(false);
                                    if (emailExist) {
                                        navigate('/profile');
                                    } else {
                                        navigate(`/?new_user=true&email=${email}`)
                                    }
                                },
                                () => {                                            
                                    setMailOkPopup(false);
                                }
                            )
                        }
                   }} mobile={true}/>}

        {mailErrorPopup &&
        <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                        title={<FormattedMessage id="reg_error"/>}
                        buttonTitle={<FormattedMessage id="reg_reload_page"/>} onOk={() => {
                                setMailErrorPopup(false);
                                window.location.reload();
                        }} mobile={true}/>}
                        
        {popUp &&
        <PopUp content={<div className={`column h-flex`}> 
                <div style={{zIndex: 1}}>
                    <div className={`row top w-flex-p-1 neg-mx-1 h-7 neg-mb-7 neg-mt-3`}>
                        <NewAvatar size={5} type={['yellow', 'blue']} name={`A I`} className={`neg-ml-4 mt-6`}/>
                        <NewAvatar size={6} type={['light-yellow', 'yellow']} name={`A M`} className={`neg-mt-6 mr-6`}/>
                        <NewAvatar size={5} type={['bright-pink', 'blue']} name={`L K`} className={`neg-mr-4 mt-5`}/>
                    </div> 

                    <div className={`p column pb-4`}>  
                        <NewAvatar size={7} name={fromProfile?.name} type={fromProfile?.avatar?.style ?? 0} 
                                   avatar={fromProfile?.avatar?.link} className={'mt-4 mb-3'}/>
                        <p data-size={8} className={`center`} data-color={`white`}>
                            {`Hi there! I'm using Osmos to connect with people aligned with my business goals.`}
                        </p>
                        <p data-size={8} className={`center mb-3`} data-color={`white`}>
                            {`I've noticed a demand for professionals like you within my network. Please take a moment to explore the request, as it could offer fresh opportunities and perspectives.`}
                        </p>
                        <button className={`block btn-circled-40 mobile row`}
                           data-color={`black`} type={`button`} onClick={() => {
                                setClosePopup(true);
                           }}>
                            <p>{`See who's interested in me`}</p>
                        </button>
                    </div>
                </div>
                 
                <div className={`row bottom w-flex-p-3 neg-mx-3 h-9 neg-mt-9 neg-mb-4`} style={{overflow: 'hidden'}}>
                    <div className={`neg-mb-3`}>
                    <NewAvatar size={6} type={['light-pink', 'coral']} name={`P D`} className={`neg-mb-5 neg-ml-3`}/></div>
                    <NewAvatar size={5} type={['green', 'bright-blue']} name={`O D`} className={`neg-mr-2 neg-mb-3`}/>
                </div>
            </div>} show={popUp} setShow={setPopUp} close={closePopUp} color={'#1033AF'}/>}
    </div>)
}

export {
    InvitePageDesktop,
    InvitePageMobile,
}