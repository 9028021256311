import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

posthog.init(
  `phc_xKplHwMrq8nQCtzEnuL01lLSuGFrUW32Gs6DIvUC1cC`,
  {
    api_host: `https://eu.posthog.com`,
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PostHogProvider client={posthog}>
            <App />
        </PostHogProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
