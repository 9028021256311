import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import {   
    TopBar,
    FooterDesktop,
} from "./elements/Elements.jsx";
import { AngleRightBlack } from "./elements/Imgs";
import { ArticleRequest, BlogRequest, MinProfileRequest, NotificationsRequest, ProfileRequest } from "./Requests";
import { BlogLayout, DateFromString, UUIDRegExp } from "./elements/Data";
import { ProfileContext } from "./elements/Contexts";
import { BottomNavBar, TopNavBar } from "./elements/Elems";
import { FormattedMessage } from "react-intl";

export const BlogDesktop = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const { id } = useParams()
    const navigate = useNavigate();

    const [ profile, setProfile ] = useState<any>();
    const [ notifications, setNotifications ] = useState<any[]>([]);
    const [ article, setArticle ] = useState<any>();
    const [ articles, setArticles ] = useState<any[]>();

    useEffect(() => {
        BlogRequest(setArticles);
        ProfileRequest(cookie[`user_id_cookie`], setProfile);
        NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
    }, [])

    useEffect(() => {
        if (id) {
            if (UUIDRegExp.test(id)) {
                ArticleRequest(id, setArticle, () => {}, () => {}, () => {
                    navigate(`/404`);
                });
            } else {
                navigate(`/404`);
            }
        }
    }, [id])

    return (<ProfileContext.Provider value={{
            notifications: notifications, setNotifications: setNotifications,
            isSubscriptionActive: profile?.subscription?.is_subscription_active,
        }}>
        <div className={`body top desktop`}>                
            <TopNavBar other={true} noSignIn={true}/> 

            <div className={`w-flex column px-5 md-px-3`}>
                <div className={`w-10 sm-w-flex`}>
                    <div className={`row top nowrap mb-3`}>
                        {!id ?
                        <a className={`btn-circled-24 desktop p-left row left my-1`} href={`/profile`}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4} data-color={`black`}><FormattedMessage id="go_back"/></p>
                        </a> :
                        <a className={`btn-circled-24 desktop p-left row left my-1`} href={`/blog`}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4} data-color={`black`}><FormattedMessage id="blog"/></p>
                        </a>}
                    </div>

                    {!id ? (!!articles ?
                    <div className={`block b-3 px-4 pt-4 column mb-6`}>
                        {articles.map((elem: any, index: number) => {return (<Fragment key={index}>
                            <p data-size={8} data-color={`black-40-opacity`} className={`mb-1`}>
                                {elem?.type === 0 ? `New feature` :
                                elem?.type === 1 ? `Quick tips` : `Article`}
                            </p>
                            <p data-size={4} className={`semibold seminarrow mb-1`}>
                                {elem?.title ?? `New article`}
                            </p>
                            <a href={`/blog/${elem?.id}`} className={`desktop btn mb-2`}>
                                <p data-size={8} data-color={`black`}>
                                    {`Read more →`}
                                </p>
                            </a>
                            {!!elem?.preview ?
                            <img src={elem?.preview} alt={`l`} className={`w-flex mb-4`}></img> :
                            <div className={`w-flex h-8 block b-3 skeleton mb-4`}></div>}
                        </Fragment>)})}                            
                    </div> :                            
                    <div className={`w-10 h-10 skeleton b-3`}></div>) : 
                    (!!article ? <>
                    <div className={`block bt-3 p-4`} data-color={`blue`}>
                        <p data-size={6} data-color={`gray`} className={`mb-3`}>
                            {DateFromString(article?.created_at ?? 0)}
                        </p>
                        <p data-size={3} data-color={`white-40-opacity`} className={`semibold seminarrow`} 
                            style={{maxWidth: 522}}>
                            {article?.content?.title}
                            {article?.content?.enter ? <br/> : <>&nbsp;</>}
                            <span data-color={`white`}>
                                {article?.content?.subtitle}
                            </span>
                        </p>
                    </div>
                    <div className={`block bb-3 p-4 mb-6`}>
                        {(article?.content?.content ?? []).map((elem: {type: string, content: string, width: string}, index: number) => {
                            switch (elem?.type) {
                                case "title":
                                    return (
                                        <p data-size={4} data-color={`blue`} key={index}
                                        className={`semibold seminarrow ${BlogLayout?.desktop[article?.type ?? 0][elem?.type ?? `text`]}`}>
                                            {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                                <Fragment key={index_}>
                                                    {elem_}<br/>
                                                </Fragment>
                                            )})}
                                        </p>
                                    )
                                case "subtitle":
                                    return (
                                        <p data-size={8} key={index}
                                        className={`semibold seminarrow ${BlogLayout?.desktop[article?.type ?? 0][elem?.type ?? `text`]}`}>
                                            {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                                <Fragment key={index_}>
                                                    {elem_}<br/>
                                                </Fragment>
                                            )})}
                                        </p>
                                    )
                                case "text":
                                    return (
                                        <p data-size={8} key={index} data-color={`black-40-opacity`} 
                                        className={BlogLayout?.desktop[article?.type ?? 0][elem?.type ?? `text`]}>
                                            {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                                <Fragment key={index_}>
                                                    {elem_}<br/>
                                                </Fragment>
                                            )})}
                                        </p>
                                    )
                                case "image":
                                    return (
                                        <img src={elem?.content} alt={``} key={index}
                                            className={`w-${elem?.width} ${BlogLayout?.desktop[article?.type ?? 0][elem?.type ?? `text`]}`}></img>
                                    )
                                case "closing":
                                    return (
                                        <p data-size={8} key={index}
                                        className={`${BlogLayout?.desktop[article?.type ?? 0][elem?.type ?? `text`]}`}>
                                            {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                                <Fragment key={index_}>
                                                    {elem_}<br/>
                                                </Fragment>
                                            )})}
                                        </p>
                                    )
                                default:
                                    return null;
                            }
                        })}
                        <p data-size={8} data-color={`black-40-opacity`} className={`px-2 mb`}>
                            {`The Osmos team`}
                        </p>
                    </div>
                    </> :                            
                    <div className={`w-10 h-10 skeleton b-3`}></div>)}
                </div>
            </div>
            <FooterDesktop links={true}/>
        </div>
    </ProfileContext.Provider>)
}

export const BlogMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const navigate = useNavigate();
    const { id } = useParams()

    const [ profile, setProfile ] = useState<any>();
    const [ article, setArticle ] = useState<any>();
    const [ notifications, setNotifications ] = useState<any[]>([]);
    const [ articles, setArticles ] = useState<any[]>();

    useEffect(() => {
        BlogRequest(setArticles);
        MinProfileRequest(cookie[`user_id_cookie`], setProfile);
        NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
    }, [])

    useEffect(() => {
        if (id) {
            if (UUIDRegExp.test(id)) {
                ArticleRequest(id, setArticle, () => {}, () => {}, () => {
                    navigate(`/404`);
                });
            } else {
                navigate(`/404`);
            }
        }
    }, [id])

    return (
    <div className={`body top desktop`}>
        <ProfileContext.Provider value={{notifications: notifications, setNotifications: setNotifications}}>
            <TopBar profile={profile} setProfile={setProfile} hideSettings={!cookie['user_id_cookie']}
                    hideNotifications={!cookie['user_id_cookie']} hideLogIn={true}
                    func={() => {
                        navigate('/profile');
                    }} toTop={false} className={`w-flex`} color={`light-gray`}/>
        </ProfileContext.Provider>

        <div className={`w-flex px-3`}>
            {!id ? <>
            <a className={`btn-circled-24 mobile p-left row left mb-3`} href={`/profile`}>
                <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                <p data-size={5} data-color={`black`}><FormattedMessage id="go_back"/></p>
            </a>
            {!!articles ?
            <div className={`block b-3 p-3 column mb-4`}>
                {articles.map((elem: any, index: number) => {return (<Fragment key={index}>
                    <p data-size={6} data-color={`black-40-opacity`} className={`mb-1`}>
                        {elem?.type === 0 ? `New feature` :
                            elem?.type === 1 ? `Quick tips` : `Article`}
                    </p>
                    <p data-size={5} className={`semibold center seminarrow mb-1`}>
                        {elem?.title ?? `New article`}
                    </p>
                    <a href={`/blog/${elem?.id}`} className={`desktop btn mb-2`}>
                        <p data-size={6} data-color={`black`}>
                            {`Read more →`}
                        </p>
                    </a>
                    {!!elem?.preview ?
                    <img src={elem?.preview} alt={`l`} className={`w-flex ${index + 1 < articles?.length ? `mb-4` : ``}`}></img> :
                    <div className={`w-flex h-8 block b-3 skeleton mb-4`}></div>}
                </Fragment>)})}                            
            </div> :                            
            <div className={`w-flex h-9 skeleton b-3 mb-4`}></div>}
            </> : <>
            <a className={`btn-circled-24 mobile p-left row left mb-3`} href={`/blog`}>
                <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                <p data-size={5} data-color={`black`}><FormattedMessage id="blog"/></p>
            </a>
            {!!article ? <>
            <div className={`block bt-3 p-3`} data-color={`blue`}>
                <p data-size={6} data-color={`light-gray`} className={`mb-2`}>
                    {DateFromString(article?.created_at ?? 0)}
                </p>
                <p data-size={4} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                    {article?.content?.title}
                    {article?.content?.enter ? <br/> : <>&nbsp;</>}
                    <span data-color={`white`}>
                        {article?.content?.subtitle}
                    </span>
                </p>
            </div>
            <div className={`block bb-3 p-3 pt-3 mb-4`}>
                {(article?.content?.content ?? []).map((elem: {type: string, content: string, width: string}, index: number) => {
                    switch (elem?.type) {
                        case "title":
                            return (
                                <p data-size={5} data-color={`blue`} key={index}
                                   className={`semibold seminarrow ${BlogLayout?.mobile[article?.type ?? 0][elem?.type ?? `text`]}`}>
                                    {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                        <Fragment key={index_}>
                                            {elem_}<br/>
                                        </Fragment>
                                    )})}
                                </p>
                            )
                        case "subtitle":
                            return (
                                <p data-size={6} key={index}
                                   className={`semibold seminarrow ${BlogLayout?.mobile[article?.type ?? 0][elem?.type ?? `text`]}`}>
                                    {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                        <Fragment key={index_}>
                                            {elem_}<br/>
                                        </Fragment>
                                    )})}
                                </p>
                            )
                        case "text":
                            return (
                                <p data-size={6} data-color={`black-40-opacity`} key={index}
                                   className={BlogLayout?.mobile[article?.type ?? 0][elem?.type ?? `text`]}>
                                    {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                        <Fragment key={index_}>
                                            {elem_}<br/>
                                        </Fragment>
                                    )})}
                                </p>
                            )
                        case "image":
                            return (
                                <img src={elem?.content} alt={``} key={index}
                                     className={`w-${elem?.width} ${BlogLayout?.mobile[article?.type ?? 0][elem?.type ?? `text`]}`}></img>
                            )
                        case "closing":
                            return (
                                <p data-size={6} key={index}
                                   className={BlogLayout?.mobile[article?.type ?? 0][elem?.type ?? `text`]}>
                                    {(elem?.content ?? "").split(`\n`).map((elem_: string, index_: number) => {return (
                                        <Fragment key={index_}>
                                            {elem_}<br/>
                                        </Fragment>
                                    )})}
                                </p>
                            )
                        default:
                            return null;
                    }
                })}
                <p data-size={6} data-color={`black-40-opacity`} className={`px`}>
                    {`The Osmos team`}
                </p>
            </div>
            </> :                            
            <div className={`w-flex h-9 skeleton b-3 mb-4`}></div>}
            </>}
        </div>
        {cookie['user_id_cookie'] ?
        <BottomNavBar other={true} profile={profile}/> :
        <FooterDesktop isMobile={true} links={true}/>} 
    </div>
    )
}