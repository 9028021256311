import { MakeValidURL, OnlyValid, OnlyValidUsers, SetDict } from "./elements/Data";

export const StripeID = process.env.REACT_APP_STRIPE_KEY ?? "";

const SaveCall = (func: any) => {
    if (typeof func === `function`) {
        func();
    }
}

const ConstructURL = (origin: string, query: {[key: string]: string | undefined}) => {
    const queryParts = Object.keys(query).map((key: string) => {
        if (query[key] !== undefined) {
            return `${key}=${query[key]}`;
        }
        return null
    }).filter(val => val !== null)

    if (queryParts.length > 0) {
        return `${origin}?${queryParts.join('&')}`;
    } else {
        return origin
    }
}


async function ServerError(
    statusCode: number,
    detail: string,
) {
    const header = {'Content-Type': 'application/json'};
    const request = {
        method: "POST",
        headers: header,
        credentials: 'include' as RequestCredentials as RequestCredentials,
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}server_error/${statusCode}?detail=${encodeURIComponent(detail)}`, request)
}

async function Request(
    url: string, 
    body: string = "", 
    method: string = 'POST'
) {
    const header = {'Content-Type': 'application/json'};
    const request = {
        method: method,
        headers: header,
        credentials: 'include' as RequestCredentials as RequestCredentials,
        body: body,
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, request)
    .then(async data => data)
    .catch(error => {
        return {ok: false, status: -1, statusText: error};
    })
}


export function IsCommunity(
    setInfo: Function,
) {
    const header = {'Content-Type': 'application/json'};
    const request = {
        method: "GET",
        headers: header,
        credentials: 'include' as RequestCredentials as RequestCredentials,
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}is_community`, request).then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data?.is_community)
            })
        }
    })
}

export function EmailAuth(
    login: string, 
    setExist: Function, 
    setMailbox: Function, 
    code: string | null | undefined, 
    community: string | null | undefined, 
    redirect: string | null, 
    canHelp: {
        text: string | null,
        requestId: string | null,
    } | null = null,
    onStatus: {[key: number]: Function} = {}, 
) { 
    return fetch(`${process.env.REACT_APP_BACKEND_URL}email?redirect=${redirect ?? encodeURIComponent(`/profile`)}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: login.toLowerCase(), 
            code: code, 
            community: community,
            can_help_text: canHelp?.text,
            can_help_request_id: canHelp?.requestId,
        }),
    })
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {                 
                console.log('Auth', res);
                setExist(data.exist);
                setMailbox(MakeValidURL(data.mailbox_link));
            })
        }
        else {
            console.warn('Auth', res);
            ServerError(res.status, res.statusText);
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        SaveCall(onStatus[0]);
        console.error({ok: false, status: -1, statusText: error});
    })
}

export function LinkedInAuth(
    code: string | null | undefined, 
    community: string | null | undefined, 
    redirect: string | null, 
    canHelp: {
        text: string | null,
        requestId: string | null,
    } | null = null,
    onStatus: {[key: number]: Function} = {}, 
) {
    return fetch(
        ConstructURL(
            `${process.env.REACT_APP_BACKEND_URL}linkedin/login`,
            {
                code: code ?? 'None',
                community: community ?? 'None',
                redirect: redirect ?? encodeURIComponent(`/profile`),
                can_help_text: canHelp?.text ?? undefined,
                can_help_request_id: canHelp?.requestId ?? undefined,
            }
        ),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }
    )
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                if (data['authorization_url']) {                    
                    console.log('AuthLinkedIn', res);
                    window.location.href = data['authorization_url'];
                } else {
                    console.warn('AuthLinkedIn', res);
                    SaveCall(onStatus[0]);
                }
            }) 
        } else {
            ServerError(res.status, res.statusText);
            SaveCall(onStatus[0]);
            ServerError(res.status, res.statusText);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        SaveCall(onStatus[0]);
        console.error('AuthLinkedIn', {ok: false, status: -1, statusText: error});
    })
}

export function GoogleAuth(
    code: string | null | undefined, 
    community: string | null | undefined, 
    redirect: string | null,  
    canHelp: {
        text: string | null,
        requestId: string | null,
    } | null = null,
    onStatus: {[key: number]: Function} = {}, 
) {
    return fetch(
        ConstructURL(
            `${process.env.REACT_APP_BACKEND_URL}google/login`,
            {
                code: code ?? 'None',
                community: community ?? 'None',
                redirect: redirect ?? encodeURIComponent(`/profile`),
                can_help_text: canHelp?.text ?? undefined,
                can_help_request_id: canHelp?.requestId ?? undefined,
            }
        ),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }
    )
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                if (data['authorization_url']) {                    
                    console.log('AuthGoogle', res);
                    window.location.href = data['authorization_url'];
                } else {
                    console.warn('AuthGoogle', res);
                    SaveCall(onStatus[0]);
                }
            }) 
        } else {
            console.warn('AuthGoogle', res);
            SaveCall(onStatus[0]);
            ServerError(res.status, res.statusText);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        SaveCall(onStatus[0]);
        console.error('AuthGoogle', {ok: false, status: -1, statusText: error});
    })
}

export function EmailAuthConfirm(
    login: string, 
    setId: Function, 
    iterator: number = 0, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onResend: Function = () => {}
) {
    if (iterator < 1) {    
        const controller = new AbortController()
        const timeoutId = setTimeout(() => {
            controller.abort()
        }, 3000)

        return fetch(`${process.env.REACT_APP_BACKEND_URL}email_authorization_get_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: login.toLowerCase()}),
            signal: controller.signal,
        })
        .then(res => {
            if (res.status === 200) {
                clearTimeout(timeoutId);
                res.json().then(data => {
                    if (data.status) {
                        console.log('AuthConfirm', res);
                        setId(data.id);
                        onSuccess();
                    } else {
                        console.warn('AuthConfirm', res);
                        onFailure();
                    }
                }).catch(e => {
                    console.error({ok: false, status: -1, statusText: e});
                    onFailure();
                })
            } else {
                clearTimeout(timeoutId);
                console.warn(`${iterator}: ${res}`);
                onFailure();
                setTimeout(() => {
                    EmailAuthConfirm(login, setId, iterator + 1, onSuccess, onFailure, onResend);
                }, 27000)                
            }
        })
        .catch(error => {
            clearTimeout(timeoutId);
            console.warn(`${iterator}: ${error}`);
            onFailure();
            setTimeout(() => {
                EmailAuthConfirm(login, setId, iterator + 1, onSuccess, onFailure, onResend);
            }, 27000) 
        })
    } else { 
        console.error({ok: false, status: -1, statusText: 'Not confirmed'});
        onResend();
    }
}

export function MinProfileRequest(
    id: string, 
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/min`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('MinProfileGet', res);
                    if (data.strings) {
                        sessionStorage.setItem('strings', JSON.stringify(data.strings));
                    }
                });
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } 
                console.warn('MinProfileGet', res);
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('MinProfileGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ProfileRequest(
    id: string, 
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(SetDict(
                        data,
                        // SetDict(data: string, ['avatar', 'link'], data?.avatar?.link ? `${data?.avatar?.link}?${String(Date.now())}` : null), 
                        ['status'], 
                        !OnlyValid(data.requests).length ? 0 : (!data.projects.length ? 1 : (!data.about ? 2 : null)))); 
                    console.log('ProfileGet', res);
                    if (data.strings) {
                        sessionStorage.setItem('strings', JSON.stringify(data.strings));
                    }
                });
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } 
                console.warn('ProfileGet', res);
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('ProfileGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function SubscriptionsRequest(
    id: string, 
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/followers`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('SubscriptionsGet', res);
                });
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } 
                console.warn('SubscriptionsGet', res);
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('SubscriptionsGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ProfileUpdate(
    id: string, 
    data: any, 
    avatar: File | null, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {},
    onNoId: Function = () => {},
) {
    const request = {
        mode: 'cors' as RequestMode as RequestMode,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include' as RequestCredentials,
        method: 'PATCH',
        body: JSON.stringify(data),
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('UpdateProfile', res);

                if (avatar) {
                    const formData = new FormData();
                    formData.append(`avatar`, avatar) 
        
                    fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/avatar/upload`, {
                        mode: 'cors' as RequestMode,
                        credentials: 'include' as RequestCredentials,
                        method: 'POST',
                        body: formData,
                    })
                    .then(res => {
                        if (res.status === 200) {
                            console.log('UpdateProfileAvatar', res);
                            onSuccess();
                        } else {
                            console.warn('UpdateProfileAvatar', res);
                        }
                    })
                    .catch(error => {
                        console.error('UpdateProfileAvatar', {ok: false, status: -1, statusText: error});
                    })
                } else {                
                    onSuccess();
                }
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('UpdateProfile', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('UpdateProfile', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('UpdateProfile', {ok: false, status: -1, statusText: `Invalid ID: ${id}`});
            onNoId();
            resolve(null);
        })
    }
}

export function EmailPrefsUpdate(
    id : string, 
    data: any, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {},
    onConfirmed: Function = () => {}, 
    onUnconfirmed: Function = () => {},
) {
    const request = {
        mode: 'cors' as RequestMode,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include' as RequestCredentials,
        method: 'PATCH',
        body: JSON.stringify(data),
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/preferences`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('UpdatePrefs', res);
                onSuccess();
                res.json().then(data => {
                    if (data.status === `confirmed`) {
                        onConfirmed();
                    } else 
                    if (data.status === `unconfirmed`) {
                        onUnconfirmed();
                    }
                })
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('UpdatePrefs', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('UpdatePrefs', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('UpdatePrefs', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ProfileDelete(
    email: string, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        mode: 'cors' as RequestMode,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include' as RequestCredentials,
        method: 'DELETE',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${email}`, request)
    .then(res => {
        if (res.status === 200) {
            console.log('DeleteProfile', res);
            onSuccess();
        } else {
            if (res.status === 404) {
                onNotFound();
            } else if (res.status === 403) {
                onForbidden();
            }
            console.warn('DeleteProfile', res);
            onFailure();
        }
    })
    .catch(error => {
        console.error('DeleteProfile', {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function CardRequest(
    id: string | undefined, 
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}card/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('CardGet', res);
                });
            } else {
                if (res.status === 404 || res.status === 422) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } 
                console.warn('CardGet', res);
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('CardGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function CardRecommendation(
    id: string, 
    cardRequest: string,
    setInfo: Function, 
    onStatus: {[key: number]: Function} = {},
    signal: AbortSignal | null = null,
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
        signal: signal,
    }
    if (id && cardRequest && process.env.REACT_APP_BACKEND_STATUS !== "devi") {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/recommendations?request=${encodeURIComponent(cardRequest)}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('RecommendGet', res);
                });
            } else {
                console.warn('RecommendGet', res);
            }
            SaveCall(onStatus[res.status]);
        })
        .catch(error => {
            console.error('RecommendGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn(
                'RecommendGet', 
                {ok: false, status: -1, statusText: !id ? `ID is ${id}` : `Request is ${cardRequest}`}
            );
            resolve(null);
        })
    }
}

export function MinRecommendation(
    id: string, 
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/recommendations/min`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('MinRecommendGet', res);
                });
            } else {
                if (res.status === 404 || res.status === 422) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } else if (res.status === 401) {
                    // window.location.href = `${window.location.origin}/401`;
                }
                console.warn('MinRecommendGet', res);
            }
        })
        .catch(error => {
            console.error('MinRecommendGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn(
                'MinRecommendGet', 
                {ok: false, status: -1, statusText: `ID is ${id}`}
            );
            resolve(null);
        })
    }
}

export function CardRecommendationRequests(
    id: string, 
    cardRequest: string,
    setInfo: Function, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {},
    limit: number | null = null,
    shuffle: boolean = true,
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id && process.env.REACT_APP_BACKEND_STATUS !== "devi") {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/recommendations/cards?request=${encodeURIComponent(cardRequest)}${limit ? `&limit=${limit}` : ``}&if_shuffle=${shuffle}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('RecommendGet', res);
                });
            } else {
                if (res.status === 404 || res.status === 422) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } else if (res.status === 401) {
                    // window.location.href = `${window.location.origin}/401`;
                }
                console.warn('RecommendGet', res);
            }
        })
        .catch(error => {
            console.error('RecommendGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('RecommendGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function CardUpdate(
    id : string, 
    data: {
        type: number,
        answers: string[],
        background_type?: number,
        tags?: string[] | null, 
    },
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const formData = new FormData();
    formData.append(`type`, String(data.type));
    formData.append("answers", JSON.stringify(data.answers));
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: id === 'new' ? 'PUT' : 'PATCH',
        body: JSON.stringify(data),
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}card/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('UpdateCard', res);
                onSuccess();
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('UpdateCard', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('UpdateCard', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function CardDelete(
    id: string, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'DELETE',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}card/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('DeleteCard', res);
                onSuccess();
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('DeleteCard', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('DeleteCard', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ProjectAvatarRequest(
    url: string, 
    setImg: Function,
) {    
    const request = {
        mode: 'cors' as RequestMode,
        method: 'GET',
    }
    if (url) {
    return fetch(url, request)
        .then(res => {
            if (res.status === 200) {
                console.log('ProjectAvatar:', url, res);
                res.blob().then(img => {
                    setImg(URL.createObjectURL(img));
                })
            } else {
                console.warn('ProjectAvatar', res);
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
        })
    }
}

export function ProjectUpdate(
    id: string, 
    data: string, 
    file: File | null, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {        
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: id === 'new' ? 'PUT' : 'PATCH',
        body: JSON.stringify(data),
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}project/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('UpdateProject', res);

                if (file) {
                    res.json().then(data => {
                        const formData = new FormData();
                        formData.append(`preview`, file) 

                        console.warn('Data:', data)
            
                        fetch(`${process.env.REACT_APP_BACKEND_URL}project/${data.id}/preview/upload`, {
                            mode: 'cors' as RequestMode,
                            credentials: 'include' as RequestCredentials,
                            method: 'POST',
                            body: formData,
                        })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('UpdateProjectPreview', res);
                                onSuccess();
                            } else {
                                console.warn('UpdateProjectPreview', res);
                            }
                        })
                        .catch(error => {
                            console.error('UpdateProjectPreview', {ok: false, status: -1, statusText: error});
                        })                    
                    })
                } else {                
                    onSuccess();
                }
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                } 
                console.warn('UpdateProject', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('UpdateProject', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('UpdateProject', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ProjectDelete(
    id: string, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'DELETE',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}project/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('DeleteProject', res);
                onSuccess();
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('DeleteProject', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error({ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('DeleteProject', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function MatchRequest(
    setInfo: Function,
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}user_match`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log('MatchesGet', res);
                setInfo(SetDict(data, ["seen_as_match"], data?.id === localStorage.getItem("seen_as_match")));
            });
        }  else {
            console.warn('MatchesGet', res);
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        console.error('MatchesGet', {ok: false, status: -1, statusText: error});
        SaveCall(onStatus[0]);
    })
}

export function ToRateSkip(
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}user_skip_rate`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log('SkipRate', res);
            });
        }  else {
            console.warn('SkipRate', res);
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        console.error('SkipRate', {ok: false, status: -1, statusText: error});
        SaveCall(onStatus[0]);
    })
}

export function ToRateRequest(
    setInfo: Function,
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}user_to_rate`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log('ToRateGet', res);
                setInfo(data);
            });
        }  else {
            console.warn('ToRateGet', res);
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        console.error('ToRateGet', {ok: false, status: -1, statusText: error});
        SaveCall(onStatus[0]);
    })
}


export function SetReadyStatus(
    ready: boolean,
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'PATCH',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}user_ready?is_ready=${ready}`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log('SetReady', res);
            });
        }  else {
            console.warn('SetReady', res);
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        console.error('SetReady', {ok: false, status: -1, statusText: error});
        SaveCall(onStatus[0]);
    })
}

export function MatchGrade(
    id: string, 
    data: string, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'PATCH',
        body: JSON.stringify(data),
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}matches/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('MatchesGrade', res);
                onSuccess();
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn('MatchesGrade', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('MatchesGrade', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('MatchesGrade', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function FeedRequest(
    id: string, 
    setInfo: Function, 
    setBackInfo: Function, 
    page: number = 0, 
    onSuccess: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}feed/${id}?page=${page}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo((info: any) => (info && !!page) ? info.concat(OnlyValidUsers(data?.feed) ?? []) : OnlyValidUsers(data?.feed) ?? []);  
                    // setBackInfo(info => (info && !!page) ? info.concat(data?.feed_old ?? (data?.feed ?? []).toReversed()) : (data?.feed_old ?? (data?.feed ?? []).toReversed()));
                    setBackInfo((info: any) => (info && !!page) ? info.concat(OnlyValidUsers(data?.old_feed) ?? (OnlyValidUsers(data?.feed) ?? [])) : (OnlyValidUsers(data?.old_feed) ?? (OnlyValidUsers(data?.feed) ?? [])));
                    console.log('FeedGet', res);
                    if (data.feed.length) {
                        onSuccess();
                    } else {
                        const finisher = document.querySelector(`#feed-end-element`);
                        if (finisher) {
                            (finisher as HTMLDivElement).style.opacity = `1`;
                        }
                    }
                });
            } else if (res.status === 204) {
                const finisher = document.querySelector(`#feed-end-element`);
                if (finisher) {
                    (finisher as HTMLDivElement).style.opacity = `1`;
                }
            } else {
                console.warn('FeedGet', res);
            }
        })
        .catch(error => {
            console.error('FeedGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('FeedGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function PeopleRequest(
    id: string, 
    setInfo: Function, 
    page: number = 0, 
    onSuccess: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}people/${id}?page=${page}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => { 
                    setInfo((info: any) => (info && !!page) ? {
                        new: info.new.concat(data.new), 
                        best: info.best.concat(data.best),
                    } : data);
                    console.log('PeopleGet', res);
                    if (!!data.best.length && !!data.new.length) {
                        onSuccess();
                    } else {
                        const finisher = document.querySelector(`#people-end-element`);
                        if (finisher) {
                            (finisher as HTMLDivElement).style.opacity = `1`;
                        }
                    }
                }).catch(() => {
                })
            } else if (res.status === 204) {
                const finisher = document.querySelector(`#people-end-element`);
                if (finisher) {
                    (finisher as HTMLDivElement).style.opacity = `1`;
                }
            } else if (res.status === 401) {
                // window.location.href = `${window.location.origin}/401`;
            } else {
                console.warn('PeopleGet', res);
            }
        })
        .catch(error => {
            console.error('PeopleGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('PeopleGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ChatsRequest(
    id: string, 
    setInfo: Function,
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}chats/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setInfo(data); 
                    console.log('ChatsGet', res);
                });
            } else {
                console.warn('ChatsGet', res);
            }
        })
        .catch(error => {
            console.error('ChatsGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('ChatsGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ContactsRequest(
    id: string, 
    setInfo: Function,
    redirect: boolean = true,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/contacts`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    if (data.authorization_url) {
                        if (redirect) {
                            window.location.href = data.authorization_url;
                        }
                    } else if (data.contacts?.error?.code === 401) {
                        console.warn('ContactsGet', data.contacts?.error?.message ?? res);
                    } else {
                        setInfo((data?.contacts ?? []).concat(data?.otherContacts ?? [])); 
                        console.log('ContactsGet', res);
                    }
                });
                onSuccess();
            } else {
                console.warn('ContactsGet', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('ContactsGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('ContactsGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function OffersRequest(
    id: string, 
    setFor: Function,
    setFrom: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/can_help`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setFor(data?.for ?? data); 
                    setFrom(data?.from ?? data); 
                    console.log('OffersGet', res);
                });
            } else {
                console.warn('OffersGet', res);
            }
        })
        .catch(error => {
            console.error('OffersGet', {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('OffersGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function NewOffer(
    offer: {
        user_id: string,
        pair_id: string,
        card_id: string | null,
        text: string,
    },
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) : Promise<Response> {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
        body: JSON.stringify(offer),
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}can_help`, request)
    .then(res => {
        if (res.status === 200) {
            console.log('OfferNew', res);
            onSuccess();
        } else {
            console.warn('OfferNew', res);
            onFailure();
        }
        return res
    })
    .catch(error => {
        console.error('OfferNew', {ok: false, status: -1, statusText: error});
        onFailure();
        throw error
    })
}

export function ReactOffer(
    offer: {
        user_id: string,
        pair_id: string,
        card_id: string | null,
        action: `accept` | `reject`,
    },
    setChatId: Function = () => {},
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
        body: JSON.stringify(offer),
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}can_help/accept_or_reject`, request)
    .then(res => {
        if (res.status === 200) {
            console.log('OfferReact', res);
            res.json().then(data => {
                console.log('OfferReact new chat ID:', data?.chat_id);
                setChatId(data?.chat_id);
            })
            onSuccess();
        } else {
            console.warn('OfferReact', res);
            onFailure();
        }
    })
    .catch(error => {
        console.error('OfferReact', {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function NotificationsRequest(
    id: string, 
    setInfo: Function, 
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}user/${id}/notifications`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => { 
                    setInfo(data);
                    console.log('NotificationsGet', res);
                    onSuccess();
                }).catch(error => {
                    console.error('NotificationsGet', error);
                })
            } else { 
                if (res.status === 401) {
                    // window.location.href = `${window.location.origin}/401`;
                } 
                console.warn('NotificationsGet', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('NotificationsGet', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('NotificationsGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function BlogRequest(
    setInfo: Function, 
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}articles`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => { 
                setInfo(data);
                console.log('BlogGet', res);
                onSuccess();
            }).catch(error => {
                console.error('BlogGet', error);
            })
        } else { 
            if (res.status === 401) {
                // window.location.href = `${window.location.origin}/401`;
            } 
            console.warn('BlogGet', res);
            onFailure();
        }
    })
    .catch(error => {
        console.error('BlogGet', {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function ArticleRequest(
    id: string | undefined,
    setInfo: Function, 
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
    onNotFound: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}article/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => { 
                    setInfo(data);
                    console.log('ArticleGet', res);
                    onSuccess();
                }).catch(error => {
                    console.error('ArticleGet', error);
                })
            } else { 
                if (res.status === 401) {
                    // window.location.href = `${window.location.origin}/401`;
                } else if (res.status === 404) {
                    onNotFound()
                }
                console.warn('ArticleGet', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('ArticleGet', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('ArticleGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function NotificationsRead(
    id: string = 'all', 
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}notifications/read/${id}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log('NotificationsRead', res);
                onSuccess();
            } else { 
                if (res.status === 401) {
                    // window.location.href = `${window.location.origin}/401`;
                } 
                console.warn('NotificationsRead', res);
                onFailure();
            }
        })
        .catch(error => {
            console.error('NotificationsRead', {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('NotificationsRead', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function MessagesRequest(
    id: string | undefined, 
    setInfo: Function, 
    setUser: Function,
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}chat/${id}/messages`, request)
        .then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    if (data?.messages && data?.with_user) {
                        setInfo(data.messages);
                        setUser(data.with_user);
                    } else {
                        setInfo(data); 
                    }
                    console.log('MessagesGet', res);
                });
            } else {
                console.warn('MessagesGet', res);
                SaveCall(onStatus[0]);
            }
            SaveCall(onStatus[res.status]);
        })
        .catch(error => {
            console.error('MessagesGet', {ok: false, status: -1, statusText: error});
            SaveCall(onStatus[0]);
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn('MessagesGet', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function Subscribe(
    id: string, 
    toId : string, 
    subscribe: boolean = true, 
    onSuccess: Function = () => {},
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
    }
    if (id && toId) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}${!subscribe ? 'un' : ''}subscribe/${id}/${subscribe ? 'to' : 'from'}/${toId}`, request)
        .then(res => {
            if (res.status === 200) {
                console.log(`${subscribe ? 'S' : 'Uns'}ubscribe`, res);
                onSuccess();
            } else {
                if (res.status === 404) {
                    onNotFound();
                } else if (res.status === 403) {
                    onForbidden();
                }
                console.warn(`${subscribe ? 'S' : 'Uns'}ubscribe`, res);
            }
        })
        .catch(error => {
            console.error(`${subscribe ? 'S' : 'Uns'}ubscribe`, {ok: false, status: -1, statusText: error});
        })
    } else {
        return new Promise((resolve, reject) => {
            console.warn(`${subscribe ? 'S' : 'Uns'}ubscribe`, {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function InviteTokenBurn(
    token: string, 
    onSuccess: Function = () => {}, 
    onNotFound: Function = () => {}, 
    onForbidden: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}burn_invite_token/${token}`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`BurnInviteToken`, res);
            onSuccess();
        } else {
            if (res.status === 404) {
                onNotFound();
            } else if (res.status === 403) {
                onForbidden();
            }
            console.warn(`BurnInviteToken`, res);
        }
    })
    .catch(error => {
        console.error(`BurnInviteToken`, {ok: false, status: -1, statusText: error});
    })
}

export function InviteTokenUse(
    setToken: Function, 
    requestId: string | null = '',
    number: number = 1, 
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({card_id: requestId}),
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}generate_invite_token?number=${number}${requestId ? `&card_id=${requestId}` : ''}`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`UseInviteToken`, res);
            res.json().then(data => {
                setToken(data);
            })
        } else {
            SaveCall(onStatus[0]);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        console.error(`UseInviteToken`, {ok: false, status: -1, statusText: error});
    })
}

export function LeaveFeedback(
    pairId: string, 
    body: any, 
    onStatus: {[key: number]: Function} = {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify(body),
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}feedback/${pairId}`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`LeaveFeedback`, res);
        } else {
            SaveCall(onStatus[0]);
            console.warn(`LeaveFeedback`, res);
        }
        SaveCall(onStatus[res.status]);
    })
    .catch(error => {
        SaveCall(onStatus[0]);
        console.error(`LeaveFeedback`, {ok: false, status: -1, statusText: error});
    })
}

export function SendAnalytics(
    id: string | null,
    device: string | null,
    events: Array<{
        timestamp: number,
        event: {
            type: string,
            target?: string | null,
            url?: string,
        },
    }>,
    onSuccess: Function = () => {},  
    onFailure: Function = () => {},   
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
            user_id: id,
            device: device,
            events: events,
        }),
        method: 'POST',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}metrics`, request)
        .then(res => {
            if (res.status === 204) {
                console.log(`SendAnalytics`, res);
                onSuccess();
            } else {
                console.warn(`SendAnalytics`, res);
                onFailure();
            }
        })
        .catch(error => {
            console.error(`SendAnalytics`, {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        onFailure();
        return new Promise((resolve, reject) => {
            console.warn('SendAnalytics', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function ViewRequests(
    id: string | null,
    requests: Array<string>,
    onSuccess: Function = () => {},  
    onFailure: Function = () => {},   
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify(requests),
        method: 'POST',
    }
    if (id) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}card/view`, request)
        .then(res => {
            if (res.status === 200) {
                console.log(`ViewCard`, res);
                onSuccess();
            } else {
                console.warn(`ViewCard`, res);
                onFailure();
            }
        })
        .catch(error => {
            console.error(`ViewCard`, {ok: false, status: -1, statusText: error});
            onFailure();
        })
    } else {
        onFailure();
        return new Promise((resolve, reject) => {
            console.warn('ViewCard', {ok: false, status: -1, statusText: `ID is ${id}`});
            resolve(null);
        })
    }
}

export function RefreshToken(
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}refresh_token`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`RefreshToken`, res);
            onSuccess();
        } else {
            console.warn(`RefreshToken`, res);
            onFailure();
        }
    })
    .catch(error => {
        onFailure();
        console.error(`RefreshToken`, {ok: false, status: -1, statusText: error});
    })
}

export function RefreshId(
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}refresh_id`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`RefreshId`, res);
            onSuccess();
        } else {
            console.warn(`RefreshId`, res);
            onFailure();
        }
    })
    .catch(error => {
        onFailure();
        console.error(`RefreshId`, {ok: false, status: -1, statusText: error});
    })
}

export function FirstLoginAdd(
    id: string, 
    name: string, 
    onSuccess: Function = () => {}, 
    onFailure: Function = () => {}
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({name: name}),
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}first_login/${id}/add`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`AddFirstLogin`, res);
            onSuccess();
        } else {
            console.warn(`AddFirstLogin`, res);
            onFailure();
        }
    })
    .catch(error => {
        console.error(`AddFirstLogin`, {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function UploadFileRequest(file: File) {
    const formData = new FormData();
    formData.append(`file`, file ?? 'None');
    formData.append("data", JSON.stringify({data: 'data'}));
    const request = {
        method: 'POST',
        body: formData,
    }
    return fetch(`http://localhost:8000/api${process.env.REACT_APP_BACKEND_URL}uploadfile`, request)
    .then(res => {
        if (res.status === 200) {
            console.log('UploadFile', res);
        } else {
            console.warn('UploadFile', res);
        }
    })
    .catch(error => {
        console.error({ok: false, status: -1, statusText: error});
    })
}

export function SendInvite(
    email: string,
    message: string,
    link: string,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
            email: email,
            invitee_name: null,
            invite_message: message,
            link: link,
        }),
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}send_invite`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`SendInvite`, res);
            onSuccess();
        } else {
            console.warn(`SendInvite`, res);
            onFailure();
        }
    })
    .catch(error => {
        console.error(`SendInvite`, {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function SuggestAFriend(
    user_id: string,
    request_id: string,
    text: string | null = null,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
            user_id: user_id,
            request_id: request_id,
            text: text,
        }),
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}suggest_a_friend`, request)
    .then(res => {
        if (res.status === 200) {
            console.log(`SuggestAFriend`, res);
            onSuccess();
        } else {
            console.warn(`SuggestAFriend`, res);
            onFailure();
        }
    })
    .catch(error => {
        console.error(`SuggestAFriend`, {ok: false, status: -1, statusText: error});
        onFailure();
    })
}

export function RelevanceRequest(
    rated_id: string,
    description: string[] = [],
    relevant: boolean = false,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
        body: JSON.stringify({description: description}),
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}relevance/${rated_id}/${relevant ? `relevant` : `not-relevant`}`, request)
    .then(res => {
        if (res.status === 200) {
            onSuccess();
            console.info(`Relevance`, res)
        } else {
            onFailure();
            console.warn(`Relevance`, res);
        }
    })
    .catch(error => {
        console.error(`Relevance`, {ok: false, status: -1, statusText: error});
    })
}

export function RequestRelevanceRequest(
    rated_id: string,
    relevant: boolean = false,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'POST',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}request_relevance/${rated_id}/${relevant ? `relevant` : `not-relevant`}`, request)
    .then(res => {
        if (res.status === 200) {
            onSuccess();
            console.info(`Relevance`, res)
        } else {
            onFailure();
            console.warn(`Relevance`, res);
        }
    })
    .catch(error => {
        console.error(`Relevance`, {ok: false, status: -1, statusText: error});
    })
}

export function SearchRequest(
    query: string,
    limit: number | null = null,
    setInfo: Function = () => {},
    industries: string[] | null = null, 
    requestTypes: number[] | null = null, 
    signal: AbortSignal | null = null,
) {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
        signal: signal,
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}search?query=${query ?? ""}${
        limit ? `&limit=${limit}` : ``
    }${
        industries ? `&industries=${industries.join(",")}` : ``
    }${
        requestTypes ? `&request_types=${requestTypes.join(",")}` : ``
    }`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log(`Search`, res);
                setInfo(data);
                const hist = JSON.parse(localStorage.getItem(`search_history`) ?? '[]');
                if (query?.length > 0 && hist[0] !== query) {
                    localStorage.setItem(`search_history`, JSON.stringify([query].concat(hist).slice(0, 15)));
                }
            })
            .catch(error => {
                console.error(`Search`, {ok: false, status: 200, statusText: error});
            })
        } else {
            console.warn(`Search`, res);
        }
    })
    .catch(error => {
        console.error(`Search`, {ok: false, status: -1, statusText: error});
    })
}

export function OpenStripePortal(
    isStripeCustomer: boolean,
    onResponse: Function = () => {},
    redirect?: string,
    version?: number | string,
) {
    if (!isStripeCustomer) {
        fetch(`${process.env.REACT_APP_BACKEND_URL}create-checkout-session?v=${version ?? ""}${redirect ? `&redirect=${encodeURIComponent(redirect.replace(/^\//, ""))}` : ""}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors' as RequestMode,
            credentials: 'include' as RequestCredentials,
            method: 'POST',
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    if (data?.checkout_url) {
                        window.location.href=data?.checkout_url;
                    }
                })
            }
            onResponse();
        })
    } else {
        fetch(`${process.env.REACT_APP_BACKEND_URL}create-portal-session${redirect ? `?redirect=${redirect.replace(/^\//, "")}` : ""}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors' as RequestMode,
            credentials: 'include' as RequestCredentials,
            method: 'POST',
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    if (data?.portal_url) {
                        window.location.href=data?.portal_url;
                    }
                })
            }
            onResponse();
        })
    }
}

export default Request;