import React, { useState, useEffect, useContext } from "react";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import Confetti from "react-confetti";

import {  
    SetDict,
    OnlyValid,
    PushHistory,
    LogOut,
    UUIDRegExp,
    SetMetaData,
    UniqueIdRegExp,
    IsThisProfile,
    IsClosedBlock,
} from "../elements/Data";

import { 
    CardRecommendation, 
    ChatsRequest, 
    NewOffer, 
    NotificationsRequest, 
    OffersRequest, 
    ProfileRequest, 
    RefreshId, 
    SubscriptionsRequest
} from "../Requests";
import {  
    FooterDesktop, 
    LargeAvatar, 
} from "../elements/Elements.jsx";
import { 
    ConfirmPopup,   
    SettingsPopupRight,
    AddRequestDesktop,
    NewEventsPopup,
    PopupCenterCanHelp2,
    AddProjectDesktop,
    PopupCenterSuggestAFriend,
    PopupCenterUpdatePrivacy,
    PopupCenterCanHelp,
    FirstOfferPopup,
    PopupCenterSubscription,
    PopupCenterWelcome,
    PopupCenterBecomeAMember2,
    PopupCenterAddAboutAvatar,
} from "../elements/Popups";
import { EditProfileDesktop } from "./EditProfileDesktop";
import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { 
    ProfileChatsDesktop, 
    ProfileFeedDesktop, 
    ProfileInvitesDesktop, 
    ProfileMatchDesktop, 
    ProfileNotificationsDesktop, 
    ProfilePageDesktop, 
    ProfileRequestsDesktop, 
    ProfileSettingsDesktop 
} from "./ProfilePagesDesktop";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { TopNavBar } from "../elements/Elems";
import { FormattedMessage } from "react-intl";

export const ProfileDesktop = (props: any) => {
    const navigate = useNavigate();   
    const [ cookie ] = useCookies(['user_id_cookie']);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const { id } = useParams();
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ target, setTarget ] = useState<string | null>(null);
    const [ canAddRequest, setCanAddRequest ] = useState<boolean>(true);
    const [ addRequestPopup, setAddRequestPopup ] = useState<boolean>(false);
    const [ addProjectPopup, setAddProjectPopup ] = useState<boolean | number>(false);
    const [ editRequestPopup, setEditRequestPopup ] = useState<any | null>(null);
    const [ largeAvatarPopup, setLargeAvatarPopup ] = useState<any>(null);
    const [ showSettingsPopup, setShowSettingsPopup ] = useState<boolean>(false);
    const [ updatePrivacyPopup, setUpdatePrivacyPopup ] = useState<boolean>(false);   
    const [ suggestAFriendPopup, setSuggestAFriendPopup ] = useState<any>(null);
    const [ newChatOffer, setNewChatOffer ] = useState<any>();
    const [ requestedCreatedPopup, setRequestedCreatedPopup ] = useState<boolean>(false);
    const [ becomeAMemberPopup, setBecomeAMemberPopup ] = useState<boolean>(false);
    const [ improveProfilePopup, setImproveProfilePopup ] = useState<boolean>(false);

    const [ hintBackgroundSize, setHintBackgroundSize ] = useState<{
        height: Number,
        width: Number,
    }>()
    const [ wishToHelpRequest1, setWishToHelpRequest1 ] = useState<any>(null);
    const [ wishToHelpPopup1, setWishToHelpPopup1 ] = useState<boolean>(false);
    const [ wishToHelpRequest2, setWishToHelpRequest2 ] = useState<any>(null);
    const [ wishToHelpPopup2, setWishToHelpPopup2 ] = useState<boolean>(false);

    const [ showConfirmLeavePopup, setShowConfirmLeavePopup ] = useState<boolean>(false); 
    const [ isEdit, setIsEdit ] = useState<boolean>(false);  
    const [ leaveFunc, setLeaveFunc ] = useState<{page?: number, tab?: number, back?: boolean}>({}); 
    const [ activateSubscriptionPopup, setActivateSubscriptionPopup ] = useState<boolean>(false);
    const [ isSubscriptionActive, setIsSubscriptionActive ] = useState<boolean>(true);
    const [ welcomePopup, setWelcomePopup ] = useState<boolean>(false);

    const [ tab, setTabRaw ] = useState<number>(0); 
    const setTab = (tab: number, withPage?: number) => {
        if (IsClosedBlock(withPage ?? page, tab) && !isSubscriptionActive) {
            setActivateSubscriptionPopup(true);
        } else {
            if (isEdit) {
                setLeaveFunc(val => SetDict(
                    SetDict(val, [`tab`], tab),
                    [`back`], 
                    false
                ));
                setShowConfirmLeavePopup(true);
            } else {
                setTabRaw(tab);
            }
        }
    } 
    const [ page, setPageRaw ] = useState<number>(Infinity); 
    const setPage = (page: number, withTab?: number) => {
        if (IsClosedBlock(page, withTab ?? tab) && !isSubscriptionActive) {
            setActivateSubscriptionPopup(true);
        } else {
            if (isEdit) {
                setLeaveFunc(val => SetDict(
                    SetDict(val, [`page`], page),
                    [`back`], 
                    false
                ));
                setShowConfirmLeavePopup(true);
            } else {
                setPageRaw(page);
            }
        }
    }

    const [ profile, setProfile ] = useState<any>();
    const [ notifications, setNotifications ] = useState<any[]>();
    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>()
    const [ newEvents, setNewEvents ] = useState<any[] | null>();
    const [ recommendationsCommon, setRecommendationsCommon ] = useState<any[]>([]);
    const [ otherRecommendationsCommon, setOtherRecommendationsCommon ] = useState<any[]>([]);
    const [ requestNumber, setRequestNumber ] = useState<number>(0);
    const [ enableCongrats, setEnableCongrats ] = useState<boolean>(false);
    const [ enableCongratsPopup, setEnableCongratsPopup ] = useState<boolean>(false);
    const [ search, setSearch ] = useState<string>("");

    const [ chats, setChats ] = useState<any[] | null>(null);
    const [ offers, setOffers ] = useState<any[] | null>(null);
    const [ requested, setRequested ] = useState<any[] | null>(null);

    const [ otherProfile, setOtherProfile ] = useState<any>();

    const getId = (myId: string = cookie['user_id_cookie']) => {
        if (UUIDRegExp.test(id ?? '') || UniqueIdRegExp.test(id ?? '')) {
            return (id ? id : myId)
        } else {
            return myId
        }
    }
    
    const getPath = (
        page: number = 0,
        tab: number = 0
    ): string | null => {
        switch (page) {
            case 0: 
                switch (tab) {
                    case 0: // /profile/uuid
                        return profile?.unique_id ?? getId(cookie[`user_id_cookie`]);
                    case 2: // /profile/requests
                        return profile?.unique_id ?? getId(cookie[`user_id_cookie`]);
                    case 3: // /profile/edit
                        return `edit`;
                    case 4: // /profile/settings
                        return `settings`;
                    case 5: // /profile/notifications
                        return `notifications`;
                    default:
                        return null
                }
            case 1:
                switch (tab) {
                    case 0: // /profile/feed
                        return `feed`; 
                    case 1: // /profile/explore
                        return `explore`;
                    default:
                        return `feed`;
                }
            case 2: 
                switch (tab) {
                    case 0: // /profile/chat
                        return `chat`;
                    case 1: // /profile/offer
                        return `offer`;
                    case 2: // /profile/requested
                        return `requested`;
                    default:
                        return `chat`; 
                }
            case 3: // /profile/invites
                return `invites`; 
            case 4: // /profile/requests
                return `requests`; 
            case 5: // /profile/match
                return `match`; 
            default:
                return null;
        }
    }

    const getLoc = (
        path: string | undefined
    ): number[] => {
        switch(path) {
            case undefined:
                return [5, 0]
            case 'home':
                return [5, 0] 
            case 'requests':
                return [0, 0]
            case 'edit':
                return [0, 3]
            case 'settings':
                return [0, 4]
            case 'notifications':
                return [0, 5]
            case 'feed':
                return [1, 1]
            case 'explore':
                return [1, 1]
            case 'chat':
                return [2, 0]
            case 'offer':
                return [2, 1];
            case 'requested':
                return [2, 0];
            case 'invites':
                return [3, 0]
            case 'me':
                return [0, 0]                
            case 'match':
                return [5, 0]
            default:
                if (!UUIDRegExp.test(path ?? '') && !UniqueIdRegExp.test(path ?? ``)) {
                    navigate(`/404`);
                }
                return [0, 0]
        }
    }

    const UpdateData = (cookie: {user_id_cookie?: any}): void => {
        if (cookie['user_id_cookie']) {
            ProfileRequest(cookie['user_id_cookie'], setProfile, () => {
                LogOut(encodeURIComponent(`/profile/${id ?? 'home'}`));
            }, () => {
                navigate(`/403`);
            });                     
        } 

        if (getId(cookie['user_id_cookie']) !== cookie['user_id_cookie']) {     
            ProfileRequest(getId(cookie['user_id_cookie']), setOtherProfile, () => {
                navigate(`/404`);
            }, () => {
                navigate(`/403`);
            });
        }
    }

    useEffect(() => {
        setHintBackgroundSize({
            height: document.documentElement.offsetHeight,
            width: document.documentElement.offsetWidth,
        })
    }, [document.documentElement.offsetHeight])

    useEffect(() => {
        const pairs = (notifications ?? []).filter(val => (
            val?.type === 1 ||
            val?.type === 6 ||
            val?.type === 7
        ) && !val?.is_read && !val.isRead);
        const this_pair = (notifications ?? []).find(val => (
            val?.type === 1 && 
            !val?.is_read && 
            val?.from_user === otherProfile?.id
        ));
        if (
            pairs?.length && 
            (IsThisProfile(getId(cookie[`user_id_cookie`]), profile) || this_pair) && 
            id !== `chat`
        ) {
            setNewEvents((val: any) => val === undefined ? pairs : val);
        } else {
            setNewEvents(undefined);
        }
    }, [notifications, profile, otherProfile?.id])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        });

        PushHistory(getPath(page, tab));
    }, [page, tab])

    useEffect(() => {
        if (page === 2) {
            if (searchParams.get('new_chat_offer') === `true` && sessionStorage.getItem("new_chat_offer")) {
                setNewChatOffer(JSON.parse(sessionStorage.getItem("new_chat_offer") ?? '{}'));
                sessionStorage.removeItem("new_chat_offer");
            }
        }
    }, [page, loaded, (profile?.requests ?? [])[requestNumber], profile?.requests])

    useEffect(() => {
        if (profile) {
            sessionStorage.setItem('profile', JSON.stringify(profile));
            
            if (IsThisProfile(getId(cookie[`user_id_cookie`]), profile)) {
                SetMetaData(profile);
            } 

            if (cookie[`user_id_cookie`]) {
                setUpdatePrivacyPopup(!(profile?.first_login ?? [`update_privacy`]).includes('update_privacy'));
            }  
            
            setImproveProfilePopup(
                cookie["user_id_cookie"] === profile?.id &&
                (
                    !profile?.about?.length ||
                    !profile?.avatar?.link
                ) &&
                profile?.subscription?.is_subscription_active &&
                !(page === 0 && tab === 3) 
            )
        }
    }, [profile]);

    useEffect(() => {
        if (profile?.subscription?.is_subscription_active === false) {
            if (profile?.subscription?.first_flow) {
                navigate("/profile/match");
            } else {
                setActivateSubscriptionPopup(page !== 5);
            }
            setIsSubscriptionActive(false);
        } else if (profile?.subscription?.is_stripe_customer === false) {
            setBecomeAMemberPopup(true);
            setIsSubscriptionActive(false);
            setPage(0, 0);
            setTab(0, 0)
        }
    }, [profile?.subscription])

    useEffect(() => {
        if (otherProfile) {            
            if (IsThisProfile(getId(cookie[`user_id_cookie`]), otherProfile)) {
                SetMetaData(otherProfile);
            }
        }
    }, [otherProfile])

    useEffect(() => {
        if (otherProfile?.about) {
            setOtherRecommendationsCommon([]);
            CardRecommendation(
                otherProfile?.id, 
                otherProfile?.about,
                setOtherRecommendationsCommon,
            )
        } else if (otherProfile?.industry) {
            setOtherRecommendationsCommon([]);
            CardRecommendation(
                otherProfile?.id, 
                otherProfile?.industry,
                setOtherRecommendationsCommon,
            )
        }
    }, [otherProfile?.about])

    useEffect(() => {
        if (profile?.about) {
            setRecommendationsCommon([]);
            CardRecommendation(
                cookie[`user_id_cookie`], 
                profile?.about,
                setRecommendationsCommon,
            )
        } else if (profile?.industry) {
            setRecommendationsCommon([]);
            CardRecommendation(
                cookie[`user_id_cookie`], 
                profile?.industry,
                setRecommendationsCommon,
            )
        }
    }, [profile?.about])

    useEffect(() => {
        if (profile?.requests) {
            sessionStorage.setItem('requests', JSON.stringify(profile?.requests));
            setRequestNumber(Math.max(profile?.requests.findIndex((val: any) => val.id === searchParams.get(`request_id`)), 0));
        }
        setCanAddRequest(profile?.requests?.length === OnlyValid(profile?.requests).length);
    }, [profile?.requests, otherProfile?.requests, target, loaded]);

    useEffect(() => {   
        if (profile?.projects) {
            sessionStorage.setItem('projects', JSON.stringify(profile?.projects));
        }
    }, [profile?.projects, otherProfile?.projects, target, loaded]);

    useEffect(() => {
        if (
            otherProfile || (profile && IsThisProfile(getId(cookie[`user_id_cookie`]), profile))
        ) {
            setLoaded(true);
        }
    }, [profile, otherProfile])  

    useEffect(()=> {
        console.warn("ON PROFILE", cookie['user_id_cookie'])
        if (!getId(cookie[`user_id_cookie`])) {
            navigate(`/?redirect=${encodeURIComponent(`/profile${id ? `/${id}` : ``}`)}`);
        } 

        if (searchParams.get('can_help_text') && searchParams.get('can_help_request_id')) {
            NewOffer({
                user_id: cookie[`user_id_cookie`],
                pair_id: searchParams.get('can_help_pair_id') ?? '',
                card_id: searchParams.get('can_help_request_id') ?? '',
                text: searchParams.get('can_help_text') ?? "",
            })
        }       

        setTarget(searchParams.get('target'));

        setWelcomePopup(searchParams.get('welcome') === `true`);

        setLeaveFunc(val => SetDict(val, [`back`], true));

        RefreshId(() => {}, () => {
            LogOut(
                encodeURIComponent(window.location.pathname ?? `/profile/home`), 
                UUIDRegExp.test(id ?? '') || UniqueIdRegExp.test(id ?? ``),
            )
        })        

        UpdateData(cookie);
        ChatsRequest(cookie['user_id_cookie'], setChats);
        OffersRequest(cookie[`user_id_cookie`], setOffers, setRequested);
        NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
        SubscriptionsRequest(cookie[`user_id_cookie`], setSubscriptions);
        const notificationTimer = setInterval(() => {
            NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
        }, 180000);

        const loc = getLoc(id);
        setPageRaw(loc[0]);
        setTabRaw(loc[1]);

        if (searchParams.get(`first_login`) === `true`) {
            sessionStorage.setItem(`first_login`, `true`);
            sessionStorage.setItem(`first_login_requests`, `true`);
        }

        return () => {
            clearInterval(notificationTimer);
        }
    }, [id, cookie]) 

    useEffect(() => {
        window.onpopstate = null; 
        window.onpopstate = e => {
            if (UUIDRegExp.test(e.state.pageName) || UniqueIdRegExp.test(e.state.pageName)) {
                if (e.state.pageName === cookie[`user_id_cookie`] || IsThisProfile(e.state.pageName, profile)) {
                    setPageRaw(0);
                    setTabRaw(0);
                } else {
                    navigate(`/profile/${e.state.pageName}`)
                }
            } else if (e.state.pageName) {
                const loc = getLoc(e.state.pageName);
                setPageRaw(loc[0]);
                setTabRaw(loc[1]);
            } else {
                navigate(-1);
            }
        }

        return () => {
            window.onpopstate = null;
        }
    }, [cookie])

    useEffect(() => {
        if (profile?.links?.email) {
            Smartlook.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })

            posthog.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })
        }
    }, [profile?.links?.email ?? null])
    
    return(<ProfileContext.Provider value={{
        setAddProjectPopup: setAddProjectPopup,
        setAddRequestPopup: setAddRequestPopup,
        setTab: setTab, setPage: setPage, setTarget: setTarget,
        setTabRaw: setTabRaw, setPageRaw: setPageRaw,
        tab: tab, page: page, target: target,
        profile: profile, setProfile: setProfile, UpdateData: () => { UpdateData(cookie) },
        ownerProfile: profile, setOwnerProfile: setProfile,
        notifications: notifications, setNotifications: setNotifications,
        setIsEdit: setIsEdit, 
        recommendationsCommon: recommendationsCommon,
        setRecommendationsCommon: setRecommendationsCommon,
        setProfilePopup: () => {},
        setSuggestAFriendPopup: setSuggestAFriendPopup,
        subscriptions: subscriptions, setSubscriptions: setSubscriptions,
        setWishToHelpPopup1: setWishToHelpPopup1,
        setWishToHelpRequest1: setWishToHelpRequest1,
        setWishToHelpPopup2: setWishToHelpPopup2,
        setWishToHelpRequest2: setWishToHelpRequest2,
        setEnableCongrats: setEnableCongrats,
        setEnableCongratsPopup: setEnableCongratsPopup,
        globalSearch: search, setGlobalSearch: setSearch,
        newChatOffer: newChatOffer, setNewChatOffer: setNewChatOffer,
        setActivateSubscriptionPopup: setActivateSubscriptionPopup,
        isSubscriptionActive: isSubscriptionActive,
        setRequestedCreatedPopup: setRequestedCreatedPopup,
    }}>
        <div className={`body top desktop`}>
            <TopNavBar other={!(IsThisProfile(getId(cookie[`user_id_cookie`]), profile) || (cookie[`user_id_cookie`] && !profile?.id))}
                onLogoClick={() => {
                    navigate(cookie["user_id_cookie"] ? `/profile/match` : `/?nologin=true`)
                }} community={globalContext?.community} 
                hideNav={profile?.subscription?.first_flow && page === 5}/>
            {(IsThisProfile(getId(cookie[`user_id_cookie`]), profile) || (cookie[`user_id_cookie`] && !profile?.id)) ?      
            <div className={`row left top nogap nowrap w-flex px-5 md-px-3 pt-2`}>
                {/* <LeftNavBar profile={profile} withLogo={true} hint={navbarHint} setHint={setNavbarHint}
                            unread={(chats ?? []).reduce((init: number, chat: any) => (init + (chat?.unread ?? 0)), 0)}/> */}
                <div className={`w-flex`} data-style={{width: `calc(100% - 102px)`}}>
                    {page === 0 && <> 
                        {tab === 0 && 
                        <ProfilePageDesktop loaded={loaded} 
                                            setShowSettingsPopup={setShowSettingsPopup}/>}

                        {!(tab - 3) && <>
                        <EditProfileDesktop setShowConfirmBack={setShowConfirmLeavePopup} 
                                            profile={profile}/>      
                        
                        {showConfirmLeavePopup &&
                        <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle=<FormattedMessage id="confirm_exit_text"/> 
                                        show={showConfirmLeavePopup} setShow={setShowConfirmLeavePopup} _id={`yesno`}
                                        color_2={'light-gray'} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} black={true} desktop={true} white={true}
                                        color_1={'black'} buttonTitle_1={<FormattedMessage id="exit"/>} onClick_1={() => {
                                            setShowConfirmLeavePopup(false); 
                                            if (leaveFunc.back) {
                                                navigate(-1);
                                            } else {
                                                setTabRaw(leaveFunc.tab ?? 0);
                                                setPageRaw(leaveFunc.page ?? 0);
                                            }
                                        }} close={'first'}/>}</>}                                           

                        {!(tab - 4) &&
                        <ProfileSettingsDesktop profile={profile} setProfile={setProfile}/>}

                        {!(tab - 5) &&
                        <ProfileNotificationsDesktop profile={profile} setProfile={setProfile}/>}
                    </>}

                    {!(page - 1) &&
                    <ProfileFeedDesktop profile={profile} setProfile={setProfile} canAddRequest={canAddRequest} 
                                        recommendationsCommon={recommendationsCommon}
                                        setRecommendationsCommon={setRecommendationsCommon}/>} 

                    {!(page - 2) && 
                    <ProfileChatsDesktop setPage={setPageRaw} profile={profile} requested={requested} setRequested={setRequested}  
                                        chats={chats} setChats={setChats} offers={offers} setOffers={setOffers}
                                        setTarget={setTarget} setLargeAvatar={setLargeAvatarPopup}/>}

                    {!(page - 3) && 
                    <ProfileInvitesDesktop profile={profile} setProfile={setProfile}/>}                     

                    {page === 4 && 
                    <ProfileRequestsDesktop loaded={loaded}/>}

                    {page === 5 && 
                    <ProfileMatchDesktop />}
                </div>
                
                {addRequestPopup && isSubscriptionActive &&  
                <AddRequestDesktop show={addRequestPopup} setShow={setAddRequestPopup} canClose={true}
                                    onSubmit={() => {
                                        UpdateData(cookie);
                                        setRequestedCreatedPopup(tab !== 0 || page !== 0);
                                    }} onDelete={() => {
                                        UpdateData(cookie);
                                    }} onClose={() => { 
                                        setAddRequestPopup(false); 
                                        setRequestNumber(0);
                                    }}
                                    headerId={`add-request-popup-content`}/>}

                {editRequestPopup &&  
                <AddRequestDesktop show={editRequestPopup} setShow={setEditRequestPopup} canClose={true}
                                onSubmit={() => {
                                    UpdateData(cookie);
                                }} onDelete={() => {
                                    UpdateData(cookie);
                                }} onClose={() => { 
                                    setEditRequestPopup(false);
                                }} canDelete={profile?.requests?.length > 1}
                                headerId={`add-request-popup-content`} request={editRequestPopup}/>}                           
                    
                {addProjectPopup && isSubscriptionActive &&
                <AddProjectDesktop show={addProjectPopup} setShow={setAddProjectPopup} canClose={true}
                                onSubmit={() => {
                                    UpdateData(cookie);
                                }} onDelete={() => {
                                    UpdateData(cookie);
                                }} headerId={`add-project-popup-content`}/>} 

                {improveProfilePopup &&
                <PopupCenterAddAboutAvatar show={improveProfilePopup} setShow={setImproveProfilePopup}/>}

                {updatePrivacyPopup &&
                <PopupCenterUpdatePrivacy show={updatePrivacyPopup} setShow={setUpdatePrivacyPopup}/>}

                {becomeAMemberPopup &&
                <PopupCenterBecomeAMember2 show={becomeAMemberPopup} setShow={setBecomeAMemberPopup} redirect={`/profile/${id}`}/>}
            </div> : 
            <div className={`row left top nogap nowrap w-flex px-5 md-px-3 pt-2`}>
                <div className={`w-flex`} data-style={{width: `calc(100% - ${cookie[`user_id_cookie`] ? 102 : (globalContext.isTablet ? -24 : -38)}px)`}}> 
                    <ProfileContext.Provider value={{
                        profile: otherProfile, setProfile: setOtherProfile,                         
                        recommendationsCommon: otherRecommendationsCommon,
                        setRecommendationsCommon: setOtherRecommendationsCommon,
                        setSuggestAFriendPopup: setSuggestAFriendPopup,
                        notifications: notifications, setNotifications: setNotifications,
                        setWishToHelpPopup1: setWishToHelpPopup1,
                        setWishToHelpRequest1: setWishToHelpRequest1,
                        setWishToHelpPopup2: setWishToHelpPopup2,
                        setWishToHelpRequest2: setWishToHelpRequest2,
                        setEnableCongrats: setEnableCongrats,
                        setEnableCongratsPopup: setEnableCongratsPopup,
                        globalSearch: search, setGlobalSearch: setSearch,
                        setTarget: setTarget,
                        newChatOffer: newChatOffer, setNewChatOffer: setNewChatOffer,
                        setActivateSubscriptionPopup: setActivateSubscriptionPopup,
                        isSubscriptionActive: isSubscriptionActive,
                        ownerProfile: profile, setOwnerProfile: setProfile,
                    }}> 
                        <ProfilePageDesktop loaded={loaded} other={true}/>
                    </ProfileContext.Provider>  
                </div>  
            </div>}
            <FooterDesktop toSupport={cookie[`user_id_cookie`] ? () => {
                setPage(0, 4);
                setTab(4, 0);
                setSearchParams({target: `support`});
            } : null} links={!cookie[`user_id_cookie`]}/>
        </div>      

        <LargeAvatar avatar={largeAvatarPopup} setAvatar={setLargeAvatarPopup}/>    
        
        {!!newEvents?.length &&
        <NewEventsPopup show={!!newEvents?.length} setShow={(show: boolean) => { setNewEvents((val: any) => show ? val : null) }} 
                        events={newEvents}/>}    

        <button style={{width: 0, height: 0, opacity: 0}} onClick={() => {
                    setShowSettingsPopup(val => !val);
                }} id={`open-settings-button`}></button>

        {showSettingsPopup && cookie[`user_id_cookie`] &&
        <SettingsPopupRight show={showSettingsPopup} setShow={setShowSettingsPopup} onInvitesUpdate={(invites: any) => {
            setProfile((val: any) => (val?.invites && invites) ? SetDict(val, ['invites'], invites) : val);
        }} invites={profile?.invites ?? null} profile={profile} isSettings={true}
        onEmailPrefsUpdate={(calendar: boolean, news: boolean, updates: boolean) => {
            setProfile((val: any) => SetDict(val, [`emails_matches_calendar_reminders`], calendar));
            setProfile((val: any) => SetDict(val, [`emails_platform_updates`], updates));
            setProfile((val: any) => SetDict(val, [`emails_project_news`], news));
        }}/>}         

        {wishToHelpPopup2 &&
        <PopupCenterCanHelp2 show={wishToHelpPopup2} setShow={setWishToHelpPopup2} desktop={true} 
                            request={wishToHelpRequest2} onRequested={(id: string) => {
                                setProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                            }}/>}  

        {wishToHelpPopup1 &&
        <PopupCenterCanHelp show={wishToHelpPopup1} setShow={setWishToHelpPopup1} desktop={true} 
                            request={wishToHelpRequest1} onRequested={(id: string) => {
                                setProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                            }}/>}

        {!!suggestAFriendPopup &&
        <PopupCenterSuggestAFriend show={!!suggestAFriendPopup} request={suggestAFriendPopup}
                                   setShow={(show: boolean) => { setSuggestAFriendPopup((val: any) => show ? val : null) }}/>}

        {requestedCreatedPopup &&
        <ConfirmPopup title=<FormattedMessage id="request_published"/> subtitle={<>You can find it in your profile<br/>or continue exploring the feed.</>}
                        show={requestedCreatedPopup} setShow={setRequestedCreatedPopup}
                        color_1={'black'} buttonTitle_1=<FormattedMessage id="stay_feed"/> onClick_1={() => {}} black={true} desktop={true}
                        color_2={'light-gray'} buttonTitle_2=<FormattedMessage id="go_to_profile"/> onClick_2={() => {
                            setPage(0, 0);
                            setTab(0, 0);
                        }} close={'first'} width={456}/>}

        {activateSubscriptionPopup && 
        <PopupCenterSubscription show={activateSubscriptionPopup} setShow={setActivateSubscriptionPopup}/>}

        {welcomePopup && !activateSubscriptionPopup &&
        <PopupCenterWelcome show={welcomePopup} setShow={setWelcomePopup}/>}

        {enableCongratsPopup &&
        <FirstOfferPopup show={enableCongratsPopup} setShow={setEnableCongratsPopup}/>}
                      
        <div style={{
            position: `absolute`, left: -1000, top: 0, zIndex: 1,
            width: `calc(100vw + 1000px)`,  
            minWidth: `calc(100% + 1000px)`,
            backgroundColor: `#00000099`,
            transition: `opacity 0.3s ease-in-out`,
            ...(searchParams.get(`first_login`) === `true` ? {
                height: `${hintBackgroundSize?.height ?? window.innerHeight}px`,
            } : {
                opacity: 0,
                pointerEvents: `none`,
            }), 
        }} id={`hint-dark-background`} hidden={true}></div>  

        <div style={{position: `fixed`, top: 0, left: 0}}>
            <Confetti 
                height={window.innerHeight} 
                drawShape={ctx => {
                    // ctx.fillRect(7, 7, 10, 10);
                    ctx.arc(0, 0, 4, 0, 360);
                    ctx.fill();
                }} recycle={enableCongrats}
                numberOfPieces={enableCongrats ? 200 : 0}
            /> 
        </div>     
    </ProfileContext.Provider>)
}