import { createContext } from "react";

export const ProfileContext = createContext<any>({
    setAddProjectPopup: () => {},
    setAddRequestPopup: () => {},
    setTab: () => {}, setTabRaw: () => {},
    setPage: () => {}, setPageRaw: () => {},
    setTarget: () => {},
    setProfile: () => {},
    UpdateData: () => {},
    setNotifications: () => {},
    setNotificationPopup: () => {},
    setIsEdit: () => {},
    setRecommendationsCommon: () => {},
    setProfilePopup: () => {},
    setSuggestAFriendPopup: () => {},
    setSubscriptions: () => {},
    setWishToHelpPopup1: () => {},
    setWishToHelpRequest1: () => {},
    setWishToHelpPopup2: () => {},
    setWishToHelpRequest2: () => {},
    setClipboardOk: () => {},
    setClose: () => {},
    setEnableCongrats: () => {},
    setEnableCongratsPopup: () => {},
});

export const GlobalContext = createContext<any>({
    setViews: () => {},
    setClipboardOk: () => {},
})